<template>
  <div class="p-4">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 mb-4">
      <!-- Order Information -->
      <div class="p-4 rounded-lg shadow-md">
        <h3 class="text-lg font-semibold mb-2">Захиалгын мэдээдлэл</h3>
        <div class="flex items-center">
          <p class="text-sm text-gray-600 flex-[1]">Нэр:</p>
          <p class="text-sm text-gray-600 flex-[5] capitalize">{{ orderDetail?.userInfo?.name }}</p>
        </div>
        <div class="flex items-center">
          <p class="text-sm text-gray-600 flex-[1]">Утас:</p>
          <p class="text-sm text-gray-600 flex-[5]">{{ orderDetail?.userInfo?.phone }}</p>
        </div>
        <div class="flex items-center">
          <p class="text-sm text-gray-600 flex-[1]">Огноо:</p>
          <p class="text-sm text-gray-600 flex-[5]">{{ orderDetail?.orderInfo?.orderDate }}</p>
        </div>
      </div>

      <!-- Shipping Information -->
      <div class="p-4 rounded-lg shadow-md">
        <h3 class="text-lg font-semibold mb-2">Захиалгын хаяг</h3>
        <p class="text-sm text-gray-600">{{ orderDetail?.orderInfo?.deliveryAddress }}</p>
        <!-- Add more shipping-related information as needed -->
      </div>

      <!-- Order Items -->
    </div>
    <p class="text-lg font-semibold mb-4 px-4">Захиалсан бараа</p>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2">
      <div v-for="item in orderDetail?.productInfo" :key="item.productId" class="rounded-lg shadow-md">
        <a :href="`/product/pid=${item?.productId}`" class="flex gap-2 p-2 items-center">
          <div class="rounded-lg">
            <img :src="item?.img" alt="" class="w-32 h-32 object-contain rounded-lg" />
          </div>
          <div class="w-full">
            <div class="flex justify-between mb-1">
              <p class="text-sm capitalize font-semibold">{{ item?.name }}</p>
              <p class="text-sm font-semibold">{{ item?.price.toLocaleString() }} вон</p>
            </div>
            <p class="text-sm text-gray-600 text-end">Ширхэг x{{ item?.quantity }}</p>
            <p class="text-sm text-gray-600 text-end">Нийт {{ item?.total.toLocaleString() }} вон</p>
            <p class="text-sm text-gray-600" v-if="item?.variants1">Төрөл{{ (item?.variants1, item?.variants2, item?.variants3) }}</p>
          </div>
        </a>
      </div>
    </div>

    <div class="border-t-1 border-grey-500 mt-4">
      <div>
        <p class="text-lg font-semibold mb-1">Захиалгын дүн</p>
        <div class="flex justify-between mb-1">
          <p class="text-md text-gray-600">Хүргэлт</p>
          <p class="text-md font-semibold">{{ orderDetail?.orderInfo?.deliveryFee.toLocaleString() }} вон</p>
        </div>
        <div class="flex justify-between text-red-500">
          <p class="text-md">Нийт</p>
          <p class="text-md font-semibold">{{ orderDetail?.orderInfo?.total.toLocaleString() }} вон</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderDetail: {
      type: Object,
      default: null,
    },
  },

  setup() {},
};
</script>
