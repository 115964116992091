export default {
  namespaced: true,
  state() {
    return {
      orderDetailNonUser: {
        orderId: "",
        customer: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          registerNum: "",
        },
        products: [],
        productsTotal: 0,
        shippingAddress: "",
        paymentMethod: "",
        deliveryCountry: "",
        deliveryMethod: "",
        deliveryWay: "",
        deliveryFee: 0,
        vat: 0,
        total: 0,
      },
    };
  },
  getters: {
    getOrderDetailNonUser(state) {
      return state.orderDetailNonUser;
    },
  },
  mutations: {
    setOrderIdNonUser(state, payload) {
      state.orderDetailNonUser.orderId = payload;
    },
  },
};
