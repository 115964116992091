import axios from "axios";
import instance from "@/lib/common/axios";
export default {
  namespaced: true,
  state() {
    return {
      accounts1: [],
      orders: [],
      routeChange: null,
    };
  },
  getters: {
    getRouter(state) {
      return state.routeChange;
    },
  },
  mutations: {
    nextRoute(state, payload) {
      state.routeChange = payload;
    },
    setCompleteOrders(state, payload) {
      state.orders = payload;
    },
  },
  actions: {
    async fetchAddress(context, payload) {
      try {
        const url = "https://dapi.kakao.com/v2/local/search/keyword.json?query=";
        return await axios.get(url + encodeURIComponent(payload), {
          headers: {
            Authorization: process.env.VUE_APP_KAKAO_MAP_KEY,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async postRegister(context, payload) {
      try {
        const res = await instance.post("/account/registerAccount", { data: payload });
        if (res.status === 200) {
          context.commit("nextRoute", "success");
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    },

    // async fetchCompleteOrders(context, payload) {
    //   console.log(payload);

    //   try {
    //     const { data } = await instance.get(`/user/completeorder`, {
    //       params: {
    //         id: payload,
    //       },
    //     });
    //     // context.state.orders = res.data;
    //     console.log(data);

    //     return data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
