export const addToWishList = (product) => {
  if (!product) return;
  const wishList = localStorage.getItem("wishList");
  if (!wishList) {
    localStorage.setItem("wishList", JSON.stringify([{ products: product }]));
  } else {
    const wishList = JSON.parse(localStorage.getItem("wishList"));
    const productIndex = wishList.findIndex((p) => p.products.id === product.id);
    if (productIndex === -1) {
      wishList.push({ products: product });
      localStorage.setItem("wishList", JSON.stringify(wishList));
    }
  }
};

export const addToBacketList = (product) => {
  if (!product) return;
  const backetList = localStorage.getItem("backetList");
  if (!backetList) {
    localStorage.setItem("backetList", JSON.stringify([{ userId: product.userId, products: product.products }]));
  } else {
    const backetList = JSON.parse(localStorage.getItem("backetList"));
    const checkByProductId = backetList.filter((item) => {
      return !product.products.product_id || item.products.product_id === product.products.product_id;
    });
    if (checkByProductId.length > 0) {
      const filteredData = checkByProductId.filter((item) => {
        if (!item.products.variants1 && !item.products.variants2 && !item.products.variants3)
          return item.products.product_id === product.products.product_id;
        else
          return (
            (!product.products.variants1 || item.products.variants1 === product.products.variants1) &&
            (!product.products.variants2 || item.products.variants2 === product.products.variants2) &&
            (!product.products.variants3 || item.products.variants3 === product.products.variants3)
          );
      });
      if (filteredData.length > 0) {
        //update quantity logic
        if (filteredData[0].products.quantity !== product.products.quantity) {
          filteredData[0].products.quantity = product.products.quantity;
          localStorage.setItem("backetList", JSON.stringify(backetList));
        } else {
          console.log("duplicated!");
          return;
        }
      } else {
        backetList.push(product);
        localStorage.setItem("backetList", JSON.stringify(backetList));
      }
    } else {
      backetList.push(product);
      localStorage.setItem("backetList", JSON.stringify(backetList));
    }
  }
};
