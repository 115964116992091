<template>
  <!-- <div class="text-green-500">success</div> -->
  <div class="flex justify-center h-[70vh] bg-gray-100 text-gray-700 text-2xl font-bold">
    <!-- <div class="flex flex-col items-center">
      <div class="flex justify-center items-center border-8 border-[#50C878] rounded-full mt-16">
        <img class="h-36 w-36" src="../../assets/common/success.svg" alt="" />
      </div>
      <div class="text-center mt-5">
        <h1 class="text-2xl font-bold">Төлбөр амжилттай хийгдлээ.</h1>
        <p class="text-sm mt-2">Таны захиалга амжилттай бүртгэгдлээ.</p>
      </div>
      <div class="flex justify-center items-center mt-5">
        <button class="bg-black text-white text-center text-xs font-bold uppercase rounded h-10 w-48 hover:shadow-lg" @click="orderAccount">
          Захиалгын мэдээлэл
        </button>
      </div>
    </div> -->
  </div>
</template>
<script>
import store from "@/store";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import instance from "@/lib/common/axios";
// import { useStore } from "vuex";
// import instance from "@/lib/common/axios";
export default {
  setup() {
    const route = useRoute();

    onMounted(async () => {
      if (!localStorage.getItem("orderDetail")) {
        // window.location.href = "/payment/fail";

        return;
      }

      if (!route.query.paymentKey || !route.query.orderId || !route.query.amount) {
        // window.location.href = "/payment/fail";

        return;
      }

      if (!JSON.parse(localStorage.getItem("nonUser"))) {
        try {
          const res = await store.dispatch("order/insertCompletedOrder", {
            orderDetail: JSON.parse(localStorage.getItem("orderDetail")),
            paymentKey: route.query.paymentKey,
          });
          if (res.status === 200) {
            setTimeout(() => {
              window.location.href = `/order/complete/orderid=${JSON.parse(localStorage.getItem("orderDetail")).orderId}`;
            }, 1000);
          }
        } catch (e) {
          localStorage.removeItem("orderDetail");
          localStorage.removeItem("orderAmounts");
          localStorage.removeItem("nonUser");
          localStorage.removeItem("backetList");
          console.log(e);

          // window.location.href = "/payment/fail";
        }
      } else {
        try {
          const res = await instance.post("/order/inserCompletedOrderForNonUser", {
            orderDetail: JSON.parse(localStorage.getItem("orderDetail")),
            orderAmounts: JSON.parse(localStorage.getItem("orderAmounts")),
            paymentKey: route.query.paymentKey,
          });
          if (res.status === 200) {
            setTimeout(() => {
              window.location.href = `/order/complete/orderid=${JSON.parse(localStorage.getItem("orderDetail")).orderId}`;
            }, 1000);
            // localStorage.removeItem("nonUser");
            // localStorage.removeItem("orderDetail");
            // localStorage.removeItem("orderAmounts");
          }
        } catch (e) {
          localStorage.removeItem("orderDetail");
          localStorage.removeItem("orderAmounts");
          localStorage.removeItem("nonUser");
          localStorage.removeItem("backetList");
          console.log(e);

          // window.location.href = "/payment/fail";
        }
      }

      // setTimeout(() => {
      //   router.push("/");
      // }, 3000);

      // if (!route.query.paymentKey || !route.query.orderId || !route.query.amount) {
      //   return;
      // }
      // try {
      //   const { data } = await instance.post("/order/authorize/payment", {
      //     paymentKey: route.query.paymentKey,
      //     orderId: route.query.orderId,
      //     amount: route.query.amount,
      //   });
      //   console.log(data);
      // } catch (e) {
      //   console.log(e);
      // }
    });
  },
};
</script>
