<template>
  <div class="bg-slate-50 flex-grow p-10">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5 rounded-lg border border-slate-200 bg-white mb-4">
        <div class="flex justify-between items-center border-b border-slate-200 pb-2 px-2">
          <h3 class="font-medium text-base text-gray-600">Нэвтрэх ID болон нууц үг бүртгэх</h3>
        </div>
        <form class="py-2 px-2">
          <div>
            <div class="grid md:grid-cols-3 gap-5">
              <div>
                <label for="lastname" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> ID
                </label>
                <input
                  name="lastname"
                  type="text"
                  placeholder="ID оруулна уу ж/нь test45 гм."
                  v-model="registerInfo.userID"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>
              <div class="md:py-2 mb-5">
                <div
                  @click="checkUserID"
                  class="w-40 cursor-pointer text-center border rounded md:mt-3 p-2 text-sm outline-primary bg-sky-500 hover:bg-gray-500 text-white"
                >
                  ID Шалгах
                </div>
              </div>
            </div>

            <div class="mb-4">
              <span class="text-xs text-red-500 ml-1"> Таны нууц үг хамгийн багадаа 8 оронтой байх ёстой. </span>
            </div>

            <div class="grid md:grid-cols-3 gap-5">
              <div>
                <label for="lastname" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> New password</label
                >
                <div class="flex relative">
                  <input
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    placeholder="Нууц үгээ оруулна уу"
                    :type="passwordVisible ? 'text' : 'password'"
                    v-model="registerInfo.pass"
                    name="password"
                  />
                  <div @click="togglePasswordVisibility" class="absolute top-3 right-3 cursor-pointer">
                    <img src="../../assets/common/eye-off.svg" alt="" v-if="!passwordVisible" />
                    <img src="../../assets/common/eye-on.svg" alt="" v-else />
                  </div>
                </div>
              </div>

              <div>
                <label for="lastname" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> Verification password</label
                >
                <div class="flex relative">
                  <input
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    :type="confirmPassVisible ? 'text' : 'password'"
                    placeholder="Нууц үгээ давтан оруулна уу"
                    v-model="confirmPass"
                  />
                  <div @click="toggleConfirmPassVisiblity" class="absolute top-3 right-3 cursor-pointer">
                    <img src="../../assets/common/eye-off.svg" alt="" v-if="!confirmPassVisible" />
                    <img src="../../assets/common/eye-on.svg" alt="" v-else />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="p-5 rounded-lg border border-slate-200 bg-white mb-4">
        <div class="flex justify-between flex-col border-b border-slate-200 pb-2 px-2">
          <h3 class="font-medium text-base text-gray-600">Хувийн мэдээлэл бүртгэх</h3>
        </div>
        <div class="mt-4">
          <button class="flex items-center gap-4 px-4 py-2 rounded text-[#333] text-sm border-2" @click="testing">
            <img class="w-16" src="../../assets/common/dan.webp" alt="" />
            <span> СИСТЕМЭЭР НЭВТРЭХ </span>
          </button>
        </div>

        <!-- Register ********************************* -->
        <form class="py-2 px-2" v-if="userInfo">
          <!-- <span class="text-red-500 text-xs">Та хувийн мэдээлэл хэсгийг бөглөхдөө кирилл үсгээр бөглөхийг анхаарна уу!</span> -->
          <div class="mt-4">
            <div class="grid md:grid-cols-3 gap-5">
              <div>
                <label for="lastname" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> Овог</label
                >
                <input
                  name="lastname"
                  type="text"
                  disabled
                  v-model="registerInfo.lastName"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>
              <div>
                <label for="name" class="block text-xs font-medium mb-1 ml-1 text-gray-500"><span class="text-red-500 font-bold">*</span> Нэр</label>
                <input
                  v-model="registerInfo.name"
                  disabled
                  name="name"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>
              <div>
                <label for="regNum" class="block text-xs font-medium mb-1 ml-1 text-gray-500"
                  ><span class="text-red-500 font-bold">*</span> Төрсөн он сар өдөр</label
                >
                <input
                  v-model="registerInfo.birthday"
                  name="regNum"
                  type="date"
                  disabled
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>
              <div>
                <label for="gender" class="block text-xs font-medium mb-1 ml-1 text-gray-500"
                  ><span class="text-red-500 font-bold">*</span> Хүйс</label
                >
                <input
                  v-model="registerInfo.gender"
                  name="gender"
                  type="text"
                  disabled
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>
              <!-- <div>
                <label for="gender" class="block text-xs font-medium mb-1 ml-1">Хүйс / Gender</label>
                <select
                  name="gender"
                  class="appearance-none w-full text-sm border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight outline-primary focus:bg-white focus:border-gray-500"
                  :value="registerInfo.gender"
                  @change="handleGender($event)"
                >
                  <option></option>
                  <option value="эрэгтэй">эрэгтэй / Male</option>
                  <option value="эмэгтэй">эмэгтэй / Female</option>
                </select>
              </div> -->

              <!-- <div>
                <label for="email" class="block text-xs font-medium mb-1 ml-1"> <span class="text-red-500 font-bold">*</span> и-мэйл / Email</label>
                <input
                  v-model="registerInfo.email"
                  name="email"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div> -->
              <!-- <div>
                <label for="telephone" class="block text-xs font-medium mb-1 ml-1">
                  <span class="text-red-500 font-bold">*</span> Утасны дугаар / Mobile number</label
                >
                <input
                  v-model="registerInfo.phoneNum"
                  name="telephone"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div> -->
              <div>
                <label for="regNum" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> Регистрийн дугаар</label
                >
                <input
                  name="regNum"
                  disabled
                  v-model="registerInfo.registerNum"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>

              <div>
                <label for="regNum" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> Монгол хаяг</label
                >
                <div class="relative">
                  <!-- v-on:change="(event) => handleMongolianAddress(event, item)" -->
                  <!-- :value="userInfo.lastName ? userInfo.lastName : registerInfo.mongolianAddress" -->
                  <input
                    v-model="registerInfo.mongolianAddress"
                    name="mongolianAddress"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                  />
                  <div
                    @click="
                      () => {
                        registerInfo.mongolianAddress = '';
                      }
                    "
                  >
                    <img src="../../assets/common/cancel-svgrepo-com.svg" alt="cancel" class="absolute top-1 right-2 p-1 cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="grid md:grid-cols-3 gap-5">
              <div class="md:py-2 mt-6 md:mt-4 mb-2">
                <label for="regNum" class="block text-xs font-medium mb-1 ml-1">
                  <span class="text-red-500 font-bold">*</span> Регистрийн дугаар / National ID num</label
                >
                <input
                  name="regNum"
                  v-model="registerInfo.registerNum"
                  placeholder="2 үсэгээ крилээр бичнэ! Жнь: ЖЛ67890076"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>

              <div class="md:py-2 md:mt-6">
                <div
                  @click="handleRegisterNum"
                  class="flex justify-center items-center appearance-none border rounded md:mt-3 p-[10px] text-sm outline-primary bg-sky-500 text-white"
                >
                  <loading-spinner v-if="isLoading" />
                  Check national ID
                </div>
              </div>
            </div> -->

            <!-- <div>
              <div class="mt-6">
                <span class="block text-xs font-medium mb-4 ml-1 text-red-500"
                  >Танд солонгос хаяг байхгүй бол монгол хаяг оруулна уу. / If you do not have a Korean address, please enter a Mongolian
                  address.</span
                >
              </div>
              <div class="grid md:grid-cols-2 gap-5">
                <div>
                  <div>
                    <label for="koreanAddress" class="block text-xs font-medium mb-1 ml-1">
                      <span class="text-red-500 font-bold">*</span> Солонгос хаяг / Korean address</label
                    >
                    <input
                      v-if="isKoreanAddress"
                      v-model="registerInfo.koreanAddress"
                      :disabled="isKoreanAddress || isMongolianAddress"
                      name="koreanAddress"
                      type="text"
                      class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    />
                  </div>
                  <div>
                    <div
                      @click="searchAddress"
                      class="appearance-none border rounded p-2 text-sm outline-primary text-center w-auto bg-sky-500 text-white cursor-pointer hover:bg-gray-800"
                      :class="isKoreanAddress ? 'mt-4' : 'mt-0'"
                    >
                      Солонгос хаяг / Korean address
                    </div>
                  </div>
                </div>

                <div>
                  <label for="mongolianAddress" class="block text-xs font-medium mb-1 ml-1"> Монгол хаяг /Mongolian address </label>
                  <input
                    @change="handleMongoAddress"
                    @click="restoreMongolianAddress"
                    v-model="registerInfo.mongolianAddress"
                    name="mongolianAddress"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    placeholder="Монгол хаягаа энд бичнэ үү! / Please enter your Mongolian address here!"
                  />
                </div>
              </div>
            </div> -->
          </div>
          <!-- <div class="mt-5">
            <label for="address" class="block text-xs font-medium mb-1 ml-1">Хаяг / Detail address</label>
            <input
              v-model="registerInfo.detailedAddress"
              @change="handleDetailAddress"
              type="text"
              name="address"
              class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
            />
          </div>
          <div class="flex items-center gap-3 mt-4">
            <input type="checkbox" v-model="registerInfo.checkBox" />
            <p class="text-sm">
              Та GEMMA гишүүн болж хямдаар бараа худалдан авахыг хүсвэл сонгоно уу. / Are you interested in becoming a member of Gemma? You have the
              privilege of purchasing products at a reduced cost.
            </p>
          </div>
          <div class="flex justify-end pt-5">
            <button
              @click="saveRegister"
              type="button"
              class="flex justify-center items-center gap-2 px-5 py-2 bg-sky-500 text-white uppercase rounded text-sm"
            >
              <loading-spinner v-if="loading" />
              Хадгалах / Submit
            </button>
          </div> -->
        </form>
      </div>

      <!-- nemelt medeelel **************************** -->
      <div class="p-5 rounded-lg border border-slate-200 bg-white mb-4">
        <div class="flex justify-between flex-col border-b border-slate-200 pb-2 px-2">
          <h3 class="font-medium text-base text-gray-600">Нэмэлт мэдээлэл бүртгэх</h3>
        </div>

        <form class="py-2 px-2">
          <div class="mt-4">
            <div class="grid md:grid-cols-3 gap-5">
              <div>
                <label for="email" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> N-мэйл</label
                >
                <input
                  v-model="registerInfo.email"
                  placeholder="И-мэйл ээ оруулна уу"
                  name="email"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>

              <div>
                <label for="telephone" class="block text-xs font-medium mb-1 ml-1 text-gray-500">
                  <span class="text-red-500 font-bold">*</span> Утасны дугаар</label
                >
                <input
                  v-model="registerInfo.phoneNum"
                  placeholder="Устасны дугаараа оруулна уу"
                  name="telephone"
                  type="text"
                  class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                />
              </div>

              <div>
                <label for="telephone" class="block text-xs font-medium mb-1 ml-1 text-gray-500">Солонгос хаяг</label>
                <div class="relative" v-if="isKoreanAddress">
                  <input
                    v-model="registerInfo.koreanAddress"
                    name="koreanAddress"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                  />
                  <div
                    @click="
                      () => {
                        isKoreanAddress = false;
                        registerInfo.koreanAddress = '';
                      }
                    "
                  >
                    <img src="../../assets/common/cancel-svgrepo-com.svg" alt="cancel" class="absolute top-1 right-2 p-1 cursor-pointer" />
                  </div>
                </div>

                <!-- <div class="border p-2"> -->
                <!-- </div> -->
                <div
                  v-else
                  class="w-full bg-sky-500 hover:bg-gray-500 border border-gray-200 rounded px-4 py-2 text-sm outline-primary text-white cursor-pointer text-center"
                  @click="searchAddress"
                >
                  Танд солонгос хаяг байвал энд дарна уу.
                </div>
              </div>
            </div>

            <!-- <div>
              <div class="grid md:grid-cols-2 gap-5">
                <div>
                  <div>
                    <label for="koreanAddress" class="block text-xs font-medium mb-1 ml-1">
                      <span class="text-red-500 font-bold">*</span> Солонгос хаяг / Korean address</label
                    >
                    <input
                      v-if="isKoreanAddress"
                      v-model="registerInfo.koreanAddress"
                      :disabled="isKoreanAddress || isMongolianAddress"
                      name="koreanAddress"
                      type="text"
                      class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    />
                  </div>

                  <div>
                    <div
                      @click="searchAddress"
                      class="appearance-none border rounded p-2 text-sm outline-primary text-center w-auto bg-sky-500 text-white cursor-pointer hover:bg-gray-800"
                      :class="isKoreanAddress ? 'mt-4' : 'mt-0'"
                    >
                      Солонгос хаяг / Korean address
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="mt-5">
            <label for="address" class="block text-xs font-medium mb-1 ml-1 text-gray-500">Дэлгэрэнгүй хаяг</label>
            <input
              v-model="registerInfo.detailedAddress"
              placeholder="Дэлгэрэнгүй хаягаа оруулна уу"
              type="text"
              name="address"
              class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
            />
            <!-- @change="handleDetailAddress" -->
          </div>
          <div class="flex items-center gap-3 mt-4">
            <input type="checkbox" v-model="registerInfo.checkBox" />
            <p class="text-sm text-gray-500">Та GEMMA гишүүн болж хямдаар бараа худалдан авахыг хүсвэл сонгоно уу.</p>
          </div>

          <div class="flex justify-end pt-5">
            <button
              @click="saveRegister"
              type="button"
              class="flex justify-center items-center gap-2 px-5 py-2 bg-sky-500 hover:bg-gray-500 text-white uppercase rounded text-sm"
            >
              <loading-spinner v-if="loading" />
              Хадгалах
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { reactive, ref, onMounted } from "vue";
import Cookies from "js-cookie";
import instance from "@/lib/common/axios";
import { useValidation } from "../../lib/common/useValidation";
import LoadingSpinner from "../../components/ui/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  setup() {
    const { validateEmail, validatePassword } = useValidation();
    const route = useRoute();
    const store = useStore();

    const hasEmptyProperty = ref(false);
    const isKoreanAddress = ref(false);

    const loading = ref(false);

    const confirmPass = ref("");
    const passwordVisible = ref(false);
    const confirmPassVisible = ref(false);

    const userInfo = ref(null);

    // const isMongolianAddress = ref(false);
    // const isLoading = ref(false);
    const registerInfo = reactive({
      userID: "",
      pass: "",
      lastName: "",
      name: "",
      birthday: "",
      gender: "",
      registerNum: "",
      phoneNum: "",
      email: "",
      koreanAddress: "",
      mongolianAddress: "",
      detailedAddress: "",
      checkBox: false,
      // newUser: false,
    });

    const testing = async () => {
      window.location.href = window.location.origin + "/account/service";
    };

    const testing2 = async () => {
      try {
        const res = await instance.get("/account/get-user-data");
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    };

    onMounted(async () => {
      if (route.query.authenticated === "true") {
        userInfo.value = await testing2();
        if (userInfo.value) {
          registerInfo.lastName = userInfo.value.lastname;
          registerInfo.name = userInfo.value.firstname;
          registerInfo.birthday = userInfo.value.birthdate;
          registerInfo.gender = userInfo.value.gender;
          registerInfo.registerNum = userInfo.value.regnum;
          registerInfo.mongolianAddress = userInfo.value.passportAddress;
        }
      }
    });

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const toggleConfirmPassVisiblity = () => {
      confirmPassVisible.value = !confirmPassVisible.value;
    };

    // CHECK USER ID
    const checkUserID = async () => {
      try {
        if (!registerInfo.userID) {
          alert("Хоосон байна та ID оруулна уу!");
        } else {
          if (registerInfo.userID.length > 15) {
            alert("Таны үүсгэсэн ID хамгийн ихдээ 15 оронтой байна.");
          } else {
            // isLoading.value = true;
            const res = await instance.post("/account/checkID", { userID: registerInfo.userID });
            if (res.data.status === "already registered") {
              // isLoading.value = false;
              alert("Энэ ID боломжгүй өөр ID хийнэ үү!");
            } else {
              // isLoading.value = false;
              alert("Энэ ID боломжтой.");
            }
          }
        }
      } catch (e) {
        console.error(e);
        return e;
      }
    };

    const searchAddress = async (e) => {
      e.preventDefault();
      window.daum.postcode.load(function () {
        new window.daum.Postcode({
          oncomplete: function (data) {
            if (data) {
              isKoreanAddress.value = true;
              registerInfo.koreanAddress = data.address;
              // registerInfo.mongolianAddress = "";
              // isMongolianAddress.value = false;
            } else {
              alert("Хаяг олдсонгүй!");
            }
          },
        }).open();
      });
    };

    const saveRegister = async () => {
      const propertiesToCheck = ["userID", "pass", "lastName", "name", "birthday", "gender", "registerNum", "mongolianAddress", "email", "phoneNum"];

      for (const property of propertiesToCheck) {
        if (!registerInfo[property]) {
          // if (property === "koreanAddress" || property === "mongolianAddress") {
          //   continue;
          // }
          alert("Улаан одоор тэмдэглэсэн мэдээллийг бүрэн бөглөнө үү!");
          hasEmptyProperty.value = true;
          break;
        } else {
          hasEmptyProperty.value = false;
        }
      }

      // if (displayRegisterError.value) {
      //   alert("Энэ регистрийн дугаар системд аль хэдийн бүртгэгдсэн байна. Та өөр регистрийн дугаар ашиглан бүртгүүлнэ үү!");
      // } else {

      if (!hasEmptyProperty.value) {
        if (!registerInfo.checkBox) return alert("Та Gemma гишүүн болохыг зөвшөөрнө үү.");
        if (registerInfo.userID.length > 15) {
          alert("Таны үүсгэсэн ID хамгийн ихдээ 15 оронтой байна.");
        } else {
          loading.value = true;
          const resID = await instance.post("/account/checkID", { userID: registerInfo.userID });
          if (resID.data.status === "already registered") {
            loading.value = false;
            alert("Энэ ID боломжгүй өөр ID хийнэ үү!");
          } else {
            loading.value = false;
            const resValidatePass = validatePassword(registerInfo.pass);
            if (!resValidatePass) {
              alert("Таны нууц үг хамгийн багадаа 8 оронтой байх ёстой.");
            } else {
              if (registerInfo.pass === confirmPass.value) {
                const validateEmailRes = validateEmail(registerInfo.email);
                if (!validateEmailRes) {
                  alert("Та мэйл хаягаа зөв бичиж оруулна уу!");
                } else {
                  const emailRes = await instance.post("account/checkEmail", { email: registerInfo.email });
                  if (emailRes.data.status === "empty") {
                    // const status = validateRegisterNum(registerInfo.registerNum);
                    // if (!status) {
                    //   alert("Та регистрийн дугаараа зөв оруулна уу");
                    // } else {
                    loading.value = true;
                    const resRegister = await instance.post("/account/checkRegisterNum", { registerNum: registerInfo.registerNum });
                    if (resRegister.data.status === "already registered") {
                      loading.value = false;
                      alert("Таны оруулсан регистрийн дугаар системд аль хэдийн бүртгэгдсэн байна. Дахин бүртгүүлэх боломжгүй!");
                    } else {
                      loading.value = false;
                      try {
                        registerInfo.koreanAddress = registerInfo.koreanAddress
                          ? registerInfo.koreanAddress
                          : "CHIMEDDORJ SUKHGEREL서울특별시 중구 을지로44길 12 (뉴금호타워) 302호";
                        const res = await store.dispatch("user/postRegister", registerInfo);
                        const expirationDate = new Date();
                        expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000);
                        Cookies.set("BStoken", res.data.token, {
                          expires: expirationDate,
                        });
                        if (res.status) {
                          window.location.href = "/";
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }
                    // }
                  } else if (emailRes.data.status === "already registered") {
                    alert("Таны оруулсан мэйл хаяг системд аль хэдийн бүртгэгдсэн байна. Та өөр мэйл хаяг ашиглан бүртгүүлнэ үү!");
                  }
                }
              } else {
                alert("Таны нууц үг баталгаажуулах нууц үгтэй ижил байх ёстой.");
              }
            }
          }
        }
      }
    };

    // const handleGender = (event) => {
    //   registerInfo.gender = event.target.value;
    // };

    // CHECK REGISTER
    // const handleRegisterNum = async () => {
    //   const status = validateRegisterNum(registerInfo.registerNum);
    //   if (!status) {
    //     alert("Та регистрийн дугаараа зөв оруулна уу");
    //   } else {
    //     isLoading.value = true;
    //     const res = await instance.post("/account/checkRegisterNum", { registerNum: registerInfo.registerNum });
    //     if (res.data.status === "already registered") {
    //       isLoading.value = false;
    //       alert("Энэ регистрийн дугаар системд аль хэдийн бүртгэгдсэн байна. Та өөр регистрийн дугаар ашиглан бүртгүүлнэ үү!");
    //     } else {
    //       isLoading.value = false;
    //       alert("Энэ регистрийн дугаар зөв байна");
    //     }
    //   }
    // };

    // const restoreMongolianAddress = () => {
    //   if (route.query.authenticated === "true") {
    //     registerInfo.mongolianAddress = userInfo.value.passportAddress;
    //     registerInfo.koreanAddress = "";
    //     isKoreanAddress.value = false;
    //   }
    // };

    // const handleMongoAddress = (e) => {
    //   const regex = /^[а-яА-ЯёЁ0-9\s!@#$&*().,-]+$/;

    //   if (!e.target.value.length) {
    //     isMongolianAddress.value = false;
    //     registerInfo.mongolianAddress = "";
    //     return;
    //   }

    //   if (regex.test(e.target.value)) {
    //     isMongolianAddress.value = true;
    //     registerInfo.mongolianAddress = e.target.value;
    //     registerInfo.koreanAddress = "";
    //     isKoreanAddress.value = false;
    //     if (registerInfo.detailedAddress) {
    //       regex.test(registerInfo.detailedAddress) ? registerInfo.detailedAddress : (registerInfo.detailedAddress = "");
    //     }
    //   } else {
    //     isMongolianAddress.value = false;
    //     registerInfo.mongolianAddress = "";
    //     alert("Монгол хаягаа кириллээр бичнэ үү! / Please enter your Mongolian address in Cyrillic!");
    //   }
    // };

    // const handleDetailAddress = (e) => {
    //   const regex = /^[а-яА-ЯёЁ0-9\s!@#$&*().,-]+$/;
    //   if (isMongolianAddress.value) {
    //     if (regex.test(e.target.value)) {
    //       registerInfo.detailedAddress = e.target.value;
    //     } else {
    //       registerInfo.detailedAddress = "";
    //       alert("Дэлгэрэнгүй хаягаа кириллээр бичнэ үү! / Please enter your detailed address in Cyrillic!");
    //     }
    //   }
    //   registerInfo.detailedAddress = e.target.value;
    // };

    return {
      testing,
      registerInfo,
      // handleGender,
      // handleRegisterNum,
      // handleMongoAddress,
      // handleDetailAddress,
      searchAddress,
      saveRegister,
      isKoreanAddress,
      // isMongolianAddress,
      // isLoading,
      confirmPass,
      checkUserID,
      loading,
      togglePasswordVisibility,
      toggleConfirmPassVisiblity,
      passwordVisible,
      confirmPassVisible,
      userInfo,
      // restoreMongolianAddress,
    };
  },
};
</script>
