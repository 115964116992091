<template>
  <div class="bg-slate-50 flex-grow mt-16 md:mt-0">
    <div class="max-w-[1240px] mx-auto">
      <div class="md:py-5">
        <div class="bg-white">
          <div class="md:grid md:grid-cols-5 md:gap-6 md:p-8">
            <div class="col-span-3">
              <div>
                <section>
                  <div class="grid grid-cols-1 md:grid-cols-6 md:gap-2">
                    <div :class="!product?.image_url ? `md:col-span-5 animate-pulse` : `md:col-span-5`">
                      <div class="relative aspect-square">
                        <img
                          v-if="product?.image_url"
                          loading="lazy"
                          class="absolute inset-0 w-full h-full bg-white object-contain object-center"
                          :src="product?.image_url"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="p-3 md:p-2 col-span-2">
              <div>
                <div id="product-title">
                  <p class="font-medium text-lg capitalize">{{ product?.name }}</p>
                </div>
                <div v-if="product?.description" id="product-description">
                  <span class="bg-gray-200 uppercase text-xs rounded-sm px-1 link link-underline link-underline-black text-black">{{
                    product?.description
                  }}</span>
                </div>
                <div class="border-t border-slate-200 my-6 hidden md:block"></div>

                <div id="product-price">
                  <div>
                    <div class="pt-3 md:bg-gray-100 w-full md:px-4 md:py-3 rounded">
                      <!-- <div class="flex justify-start items-center" v-if="product?.does_have_discount && product?.discount">
                        <div class="flex justify-start items-center mb-1">
                          <p class="text-xs font-medium text-white bg-red-500 p-1 rounded-sm mr-2">{{ product?.discount }}%</p>
                        </div>
                      </div> -->
                      <div class="flex justify-between mt-1 md:mt-2">
                        <!-- <div v-if="product?.does_have_discount">
                          <p class="font-light text-xs">Хямдарсан үнэ</p>
                          <p class="font-medium text-2xl">{{ product?.discount_price.toLocaleString() }} вон</p>
                        </div> -->
                        <div>
                          <p class="font-light text-xs">Барааны үнэ</p>
                          <!-- <p :class="priceClass">{{ product?.price.toLocaleString() }} вон</p> -->
                          <p class="text-2xl font-medium text-gray-500 decoration-1">{{ product?.price.toLocaleString() }} вон</p>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="font-medium text-base">
                          Солонгос доторх хүргэлтийн төлбөр
                          <span class="text-sky-500 font-bold"> 3,000 </span>вон
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a href="/">
                  <div class="flex flex-row items-center justify-end mt-3 gap-1">
                    <!-- svg zurag oruulah -->
                    <span class="text-slate-800">хэмжээний заавар</span>
                  </div>
                </a>
                <div v-if="!product?.stock" class="mt-5 md:nt-6">
                  <span class="rounded flex items-center justify-between w-full border border-slate-200 p-5 text-red-500">Бараа дууссан !</span>
                </div>

                <div v-else id="product-option" class="mt-5 md:mt-6">
                  <!-- variant 1 -->
                  <div v-if="product?.variant1?.values.length > 0">
                    <div class="dropdown inline-block w-full relative mb-5">
                      <select
                        class="rounded flex items-center justify-between w-full border border-slate-200 p-5"
                        @change="handleType1($event)"
                        v-if="product?.does_have_variants"
                      >
                        <option class="mr-1">Сонгох (өнгө, хэмжээ гм...) / Choose (color, size ...)</option>
                        <option v-for="(variant, index) in product?.variant1.values" :key="index" :value="variant">
                          <!-- {{ product?.variant1?.prices.length > 0 ? `${variant} + ${product?.variant1?.prices[index]}вон` : variant }} -->
                          {{
                            product?.variant1?.prices.length > 0
                              ? `${variant} ${
                                  product?.variant1?.prices[index] !== "0"
                                    ? "+ " + parseFloat(product?.variant1?.prices[index]).toLocaleString() + "вон"
                                    : ""
                                }`
                              : variant
                          }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- variant 2 -->
                  <div v-if="product?.variant2.length > 0 && showVarient2">
                    <div class="dropdown inline-block w-full relative mb-5">
                      <select class="rounded flex items-center justify-between w-full border border-slate-200 p-5" @change="handleType2($event)">
                        <option class="mr-1">Сонгох (өнгө, хэмжээ гм...) / Choose (color, size ...)</option>
                        <option v-for="(variant, index) in product?.variant2" :key="index" :value="variant">{{ variant }}</option>
                      </select>
                    </div>
                  </div>

                  <!-- variant 3 -->
                  <div v-if="product?.variant3.length > 0 && showVarient3">
                    <div class="dropdown inline-block w-full relative mb-5">
                      <select
                        class="rounded flex items-center justify-between w-full border border-slate-200 p-5"
                        @change="handleType3($event)"
                        v-if="product?.does_have_variants"
                      >
                        <option class="mr-1">Сонгох (өнгө, хэмжээ гм...) / Choose (color, size ...)</option>
                        <option v-for="(variant, index) in product?.variant3" :key="index" :value="variant">{{ variant }}</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="showDetailOrder">
                    <div class="bg-gray-100 p-4 mb-2 text-sm text-gray-700 rounded-lg text-left">
                      <div class="option-stock-top">
                        <div class="flex flex-row">
                          <div @click="removeOrder">
                            <img class="mr-4 cursor" src="../assets/common/cancel-svgrepo-com.svg" alt="" />
                          </div>
                          <div>
                            <ul>
                              <li>{{ backet.products.variants1 }}</li>
                              <li>{{ backet.products.variants2 }}</li>
                              <li>{{ backet.products.variants3 }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <hr class="my-1" />
                      <div class="option-stock-price flex lex-row justify-between">
                        <div class="option-price-title">
                          <span v-if="backet.products.price" class="text-lg font-semibold"
                            >{{ (backet.products.price * backet.products.quantity).toLocaleString() }} вон</span
                          >
                          <span v-else-if="product?.does_have_discount"
                            >{{ (product?.discount_price * backet.products.quantity).toLocaleString() }} вон</span
                          >
                          <span v-else>{{ (product?.price * backet.products.quantity).toLocaleString() }} вон</span>
                        </div>
                        <div
                          class="font-bold border-2 border-secondary text-secondary rounded bg-slate-50 grid grid-cols-3 flex justify-center items-center col-span-1"
                        >
                          <button @click="minusQuantity" class="flex justify-center items-center">
                            <img
                              :class="{ 'opacity-50 cursor-not-allowed': backet.products.quantity === 1 }"
                              src="../assets/common/minus.svg"
                              alt=""
                            />
                          </button>
                          <span class="text-center">{{ backet.products.quantity }}</span>
                          <button @click="addQuantity" class="flex justify-center items-center h-full hover:bg-slate-100">
                            <img src="../assets/common/add.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="add-card" class="grid grid-cols-3 gap-1 mt-6">
                    <div class="col-span-1"></div>
                    <button
                      @click="backetList(product)"
                      class="col-span-2 grow bg-sky-600 text-white text-center text-xs font-bold uppercase rounded h-10 flex justify-center items-center"
                    >
                      <span>Сагсанд нэмэх / Add to cart</span>
                    </button>
                  </div>
                </div>

                <!-- Modal -->
                <BacketModal :showModal="openModal" :product="backet" :total="total" @modal-updated="handleDataUpdate"></BacketModal>
                <!-- Modal -->

                <div class="flex justify-end mt-2 md:mt-5 relative">
                  <button
                    @click="addDataToList(product)"
                    class="text-[#87CEEB] text-center text-xs font-black uppercase rounded h-10 flex justify-end items-center whitespace-nowrap p-2"
                  >
                    <span class="flex items-center justify-center" v-if="!isWishList">
                      <img src="../assets/common/heart.svg" alt="" class="w-6 h-6" />
                      <span class="text-xs">Хүслийн жагсаалт </span>
                    </span>
                    <span class="flex items-center justify-center" v-if="isWishList">
                      <img src="../assets/common/heart-filled.svg" alt="" class="w-6 h-6" />
                      <span>Хүслийн жагсаалтад орсон</span>
                    </span>
                  </button>
                  <button @click="shareUrl" class="text-black text-center text-xs font-bold uppercase rounded h-10 flex justify-end items-center p-2">
                    <img src="../assets/common/share.svg" alt="" class="mr-1" />
                    <span>Хуваалцах</span>
                  </button>
                  <button v-if="Copied" class="absolute top-8 right-0">
                    <div>
                      <div class="p-3 grid grid-cols-1 gap-3">
                        <div class="flex justify-start items-center text-xs cursor-pointer">
                          <img src="../assets/common/copy.svg" alt="" />
                          <span class="pl-1 text-green-500">Линк хуулагдлаа</span>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full h-full" v-if="product?.video_url">
          <iframe
            width="100%"
            height="640px"
            :src="product?.video_url"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div class="relative" v-if="product?.urls[0]">
          <!-- product?.url?.length > 0 &&  -->
          <div class="grid gap-3">
            <div class="md:col-span-9 col-span-12 relative">
              <!-- Product huudas -->
              <div id="detail" :class="containerClass">
                <span>
                  <img :src="product && product?.urls[0]" alt="" />
                  <br />
                  <img :src="product && product?.urls[1]" alt="" />
                  <br />
                  <img :src="product && product?.urls[2]" alt="" />
                  <br />
                  <img :src="product && product?.urls[3]" alt="" />
                  <br />
                  <img :src="product && product?.urls[4]" alt="" />
                  <br />
                  <img :src="product && product?.urls[5]" alt="" />
                </span>
              </div>

              <!-- delgerengui harah -->
              <div v-if="!showImage" class="w-full flex justify-center items-end bg-gradient-to-t from-white absolute z-2 bottom-0 h-36">
                <div v-if="product?.urls?.length >= 1">
                  <button
                    @click="showImage = true"
                    class="bg-sky-600 text-white w-64 text-center m-3 rounded p-3 uppercase font-bold flex justify-center items-center"
                  >
                    <span class="animate-pulse mr-1">
                      <img src="../assets/common/chevron-down.svg" alt="" />
                    </span>
                    <span>Дэлгэрэнгүй харах</span>
                  </button>
                </div>
              </div>

              <!-- delgerengui huraah -->
              <div v-if="showImage" class="w-full flex justify-center items-end">
                <div>
                  <button
                    @click="showImage = false"
                    class="bg-sky-600 text-white w-64 text-center m-3 rounded p-3 uppercase font-bold flex justify-center items-center"
                  >
                    <span class="animate-pulse mr-1">
                      <img src="../assets/common/chevron-up.svg" alt="" />
                    </span>
                    <span>Дэлгэрэнгүй хураах</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- hajuu taliin scroll -->

            <!-- <div class="hidden md:block mt-5 flex flex-col bg-white col-span-3 p-2">
              <div class="w-72 sticky top-3">
                <div class="dropdown inline-block w-full relative mb-5">
                  <select class="rounded flex items-center justify-between w-full border border-slate-200 p-5" v-if="product?.does_have_variants">
                    <option class="mr-1">Сонгох (өнгө, хэмжээ гм...)</option>
                    <option v-for="(variant, index) in product?.variants1" :key="index">{{ variant }}</option>
                  </select>
                </div>

                <div id="add-cart-scroll" class="flex flex-row gap-1 mt-5">
                  <button
                    class="bg-black col-span-2 grow bg-black text-white text-center text-xs font-bold uppercase rounded h-10 flex justify-center items-center"
                  >
                    <span>Сагсанд нэмэх</span>
                  </button>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="p-4">
          <div class="mb-5">
            <div>
              <p class="border-b-1 pb-2 mb-3 text-[14px] flex gap-2 items-center">
                <img class="w-[18px]" src="../assets/common/truck.svg" alt="" /><span class="font-bold"> 배송안내 </span>DELIVERY
              </p>
            </div>
            <ul class="text-[12px]">
              <li>- 배송비 : 기본배송료는 3,000원 입니다. (도서,산간,오지 일부지역은 배송비가 추가될 수 있습니다)</li>
              <li>
                - 본 상품의 평균 배송일은 2~3일입니다.(입금 확인 후) 설치 상품의 경우 다소 늦어질수 있습니다.[배송예정일은 주문시점(주문순서)에
                따른유동성이 발생하므로 평균 배송일과는 차이가 발생할 수 있습니다.]
              </li>
              <li>
                - 본 상품의 배송 가능일은 2~3일 입니다. 배송 가능일이란 본 상품을 주문 하신 고객님들께 상품 배송이 가능한 기간을 의미합니다. (단, 연휴
                및 공휴일은 기간 계산시 제외하며 현금 주문일 경우 입금일 기준 입니다.)
              </li>
            </ul>
          </div>
          <div class="mb-5">
            <div>
              <p class="border-b-1 pb-2 mb-3 text-[14px] flex gap-2 items-center">
                <img class="w-[18px]" src="../assets/common/exchange.png" alt="" /><span class="font-bold">교환 및 반품안내 </span>EXCHANGE & RETURNS
              </p>
            </div>
            <ul class="text-[12px]">
              <li>- 상품 택(tag)제거 또는 개봉으로 상품 가치 훼손 시에는 상품수령후 7일 이내라도 교환 및 반품이 불가능합니다.</li>
              <li>- 저단가 상품, 일부 특가 상품은 고객 변심에 의한 교환, 반품은 고객께서 배송비를 부담하셔야 합니다(제품의 하자,배송오류는 제외)</li>
              <li>- 일부 상품은 신모델 출시, 부품가격 변동 등 제조사 사정으로 가격이 변동될 수 있습니다.</li>
              <li>- 신발의 경우, 실외에서 착화하였거나 사용흔적이 있는 경우에는 교환/반품 기간내라도 교환 및 반품이 불가능 합니다.</li>
              <li>
                - 수제화 중 개별 주문제작상품(굽높이,발볼,사이즈 변경)의 경우에는 제작완료, 인수 후에는 교환/반품기간내라도 교환 및 반품이 불가능
                합니다.
              </li>
              <li>
                - 수입,명품 제품의 경우, 제품 및 본 상품의 박스 훼손, 분실 등으로 인한 상품 가치 훼손 시 교환 및 반품이 불가능 하오니, 양해 바랍니다.
              </li>
              <li>
                - 일부 특가 상품의 경우, 인수 후에는 제품 하자나 오배송의 경우를 제외한 고객님의 단순변심에 의한 교환, 반품이 불가능할 수 있사오니, 각
                상품의 상품상세정보를 꼭 참조하십시오.
              </li>
            </ul>
          </div>

          <div class="mb-5">
            <p class="border-b-1 pb-2 mb-3 text-[14px] flex gap-2 items-center">
              <img src="../assets/common/payment.svg" alt="" class="w-[20px]" /><span class="font-bold">환불안내</span> REFUND
            </p>
            <ul class="text-[12px]">
              <li>- 상품 청약철회 가능기간은 상품 수령일로 부터 7일 이내 입니다.</li>
            </ul>
          </div>

          <div>
            <p class="border-b-1 pb-2 mb-3 text-[14px] flex gap-3 items-center">
              <img src="../assets/common/truck.svg" alt="" class="w-[18px]" /><span class="font-bold">AS안내</span> AFTER SERVICE
            </p>
            <ul class="text-[12px]">
              <li>- 소비자분쟁해결 기준(공정거래위원회 고시)에 따라 피해를 보상받을 수 있습니다.</li>
              <li>- A/S는 판매자에게 문의하시기 바랍니다.</li>
            </ul>
          </div>
        </div>

        <div class="mx-5 lg:mx-0 py-4">
          <div class="flex items-center pb-4">
            <span class="text-slate-800 text-lg font-semibold mr-4">Санал болгох барааанууд</span>
          </div>
          <swiper-comp
            :product="swiperProducts?.popularProducts || []"
            :modules="modules"
            :onSwiper="onSwiper"
            :breakpoints="breakpoints"
          ></swiper-comp>
        </div>
      </div>
    </div>
    <back-to-top></back-to-top>
    <!-- <loading-new></loading-new> -->
  </div>
</template>
<script>
import { ref, computed, reactive, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import instance from "@/lib/common/axios";
import BacketModal from "../components/product/BacketModal.vue";
import BackToTop from "@/components/ui/BackToTop.vue";
import Cookies from "js-cookie";
import { addToWishList, addToBacketList } from "@/lib/common/helper";
import jwt_decode from "jwt-decode";
import SwiperComp from "@/components/product/SwiperComp.vue";
import { Navigation, Autoplay } from "swiper";

// import LoadingNew from "@/components/ui/LoadingNew.vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

export default {
  name: "ProductDetail",
  components: {
    BacketModal,
    BackToTop,
    SwiperComp,
    // LoadingNew,
  },

  async setup() {
    const store = useStore();
    const route = useRoute();

    const product = ref(null);
    const showImage = ref(false);
    const showOption = ref(false);
    const showDetailOrder = ref(false);
    const showVarient2 = ref(false);
    const showVarient3 = ref(false);
    const openModal = ref(false);
    const Copied = ref(false);
    const isWishList = ref(false);
    const total = ref({});
    const backet = reactive({
      userId: "",
      products: {
        product_id: null,
        name: "",
        variants1: "",
        variants2: "",
        variants3: "",
        price: null,
        quantity: 1,
        image_url: "",
        total: null,
        pvPoint: 0,
        stock: null,
      },
    });

    onMounted(async () => {
      try {
        const { data } = await instance.get(`/products/${route.params.id}/${Cookies.get("BStoken")}`);
        product.value = data;
        // console.log(product.value);

        await store.dispatch("products/fetchHomePageProducts");
      } catch (error) {
        console.log(error);
      }

      Cookies.get("BStoken") ? (backet.userId = jwt_decode(Cookies.get("BStoken")).user_id) : backet.userId == null;
    });

    watch(
      () => product.value,
      (newVal) => {
        if (
          newVal.variant1?.prices?.length == 0 &&
          newVal.variant1.values.length == 0 &&
          newVal.variant2.length == 0 &&
          newVal.variant3.length == 0
        ) {
          showDetailOrder.value = true;
        }
      }
    );

    const swiperProducts = computed(() => store.getters["products/getHomePageProducts"]);

    const onSwiper = (swiper) => {
      swiper.params.loop = true;
      swiper.params.autoplay = {
        delay: 3000,
      };
    };

    const breakpoints = {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 10,
      },
    };

    const containerClass = computed(() => {
      return showImage.value
        ? "w-full overflow-x-scroll bg-white mt-5 p-3 mb-12 md:mb-0 md:p-8 object-scale-down overflow-x-hidden"
        : "w-full overflow-x-scroll bg-white mt-5 p-3 mb-12 md:mb-0 md:p-8 object-scale-down overflow-x-hidden overflow-hidden max-h-screen";
    });

    const priceClass = computed(() => {
      return product?.value?.does_have_discount
        ? "text-2xl font-medium text-gray-500 decoration-1 line-through"
        : "text-2xl font-medium text-gray-500 decoration-1";
    });

    const minusQuantity = () => {
      if (backet.products.quantity > 1) {
        backet.products.quantity -= 1;
      }
    };

    const addQuantity = () => {
      if (backet.products.quantity < product.value.stock) {
        backet.products.quantity += 1;
      } else {
        alert(`Та хамгийн ихдээ ${product.value.stock} бараа захиалах боломжтой!`);
      }
    };

    const handleType1 = (e) => {
      backet.products.variants1 = e.target.value;
      showVarient2.value = true;
      if (product.value.variant1.values.length > 0 && product.value.variant2.length == 0 && product.value.variant3.length == 0) {
        showDetailOrder.value = true;
      }
      const selectedIndex = product.value.variant1.values.indexOf(e.target.value);
      if (selectedIndex !== -1) {
        const selectedPrice = parseInt(product.value.variant1.prices[selectedIndex]);
        backet.products.price = product?.value.does_have_discount
          ? product?.value.discount_price + selectedPrice
          : product.value?.price + selectedPrice;
      }
    };

    const handleType2 = (e) => {
      backet.products.variants2 = e.target.value;
      showVarient3.value = true;

      if (product.value.variant1.values.length > 0 && product.value.variant2.length > 0 && product.value.variant3.length == 0) {
        showDetailOrder.value = true;
      }
    };

    const handleType3 = (e) => {
      backet.products.variants3 = e.target.value;
      if (product.value.variant1.values.length > 0 && product.value.variant2.length > 0 && product.value.variant3.length > 0) {
        showDetailOrder.value = true;
      }
    };

    const backetList = async () => {
      if (showDetailOrder.value) {
        backet.products.product_id = product?.value.id;
        backet.products.name = product?.value.name;
        backet.products.stock = product?.value.stock;
        backet.products.pvPoint = product?.value.pv_point;

        (backet.products.price = backet.products.price
          ? backet.products.price
          : product?.value.does_have_discount
          ? product?.value.discount_price
          : product?.value.price),
          (backet.products.image_url = product?.value.image_url);
        backet.products.total = backet.products.price * backet.products.quantity;
        if (Cookies.get("BStoken")) {
          try {
            openModal.value = true;
            const res = await store.dispatch("products/insertUpdateDeleteBacket", backet);
            if (res.status == 200) {
              total.value = res.data;
              store.commit("products/setBacketListCount", res.data.backetListCount);
            }
          } catch (e) {
            console.error(e);
            return;
          }
        } else {
          openModal.value = true;
          addToBacketList(backet);
          store.commit("products/setBacketListCount", JSON.parse(localStorage.getItem("backetList"))?.length);
        }
      } else {
        alert("Барааны төрөл, өнгө, хэмжээ сонгоогүй байна!");
      }
    };

    const removeOrder = () => {
      showDetailOrder.value = false;
      backet.products.quantity = 1;
      showVarient2.value = false;
      showVarient3.value = false;
    };

    const addDataToList = async (product) => {
      if (Cookies.get("BStoken")) {
        try {
          const userId = jwt_decode(Cookies.get("BStoken")).user_id;
          isWishList.value = !isWishList.value;
          const res = await store.dispatch("products/addToWishList", { products: product, userId: userId });
          res.data > 0 ? store.commit("products/setWishListCount", res.data) : 0;
        } catch (e) {
          console.error(e);
          return;
        }
      } else {
        isWishList.value = !isWishList.value;
        addToWishList(product);
        store.commit("products/setWishListCount", JSON.parse(localStorage.getItem("wishList")).length);
      }
    };

    const handleDataUpdate = (data) => {
      openModal.value = data;
    };

    const shareUrl = async () => {
      try {
        await navigator.clipboard.writeText(window.location.href);
        Copied.value = true;
      } catch (error) {
        console.error("Failed to copy URL:", error);
      }
    };

    return {
      product,
      swiperProducts,
      backet,
      showImage,
      showOption,
      containerClass,
      minusQuantity,
      addQuantity,
      handleType2,
      handleType1,
      showVarient2,
      handleType3,
      showVarient3,
      removeOrder,
      showDetailOrder,
      backetList,
      addDataToList,
      isWishList,
      openModal,
      handleDataUpdate,
      shareUrl,
      Copied,
      priceClass,
      onSwiper,
      breakpoints,
      modules: [Navigation, Autoplay],
      total,
    };
  },
};
</script>
