<template>
  <div class="flex bg-gray-100 min-h-screen">
    <div class="w-72 bg-gray-800 text-white flex flex-col">
      <div class="flex items-center justify-center h-16 bg-gray-900">
        <span class="text-lg font-semibold">Админ</span>
      </div>
      <nav class="flex-1 flex flex-col py-4 px-2 space-y-2">
        <a
          href="/admin/addcategory"
          :class="isRoute == '/admin/addcategory' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Категори нэмэх</a
        >
        <a
          href="/admin/editcategory"
          :class="isRoute == '/admin/editcategory' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Категори засах/устгах</a
        >
        <a
          href="/admin/addproduct"
          :class="isRoute == '/admin/addproduct' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Бараа нэмэх</a
        >
        <a
          href="/admin/productlist"
          :class="isRoute == '/admin/productlist' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Барааны жагсаалт</a
        >
        <a
          href="/admin/banner"
          :class="isRoute == '/admin/product-list' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Баннер</a
        >
        <!-- create drop down menu that shows two options when clicked -->
        <span class="flex justify-between items-center px-4 py-2 hover:bg-gray-700 rounded-md cursor-pointer" @click="toggleSubmenu">
          Захиалгууд
          <svg
            class="w-6 h-6 inline-block"
            :class="showSubmenu ? `transform rotate-60` : `transform -rotate-90`"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L10 11.5858L13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289C15.0976 8.68342 15.0976 9.31658 14.7071 9.70711L10.7071 13.7071Z"
              fill="currentColor"
            />
          </svg>
        </span>

        <div class="flex-col gap-4 pl-2" :class="showSubmenu ? `flex` : `hidden`">
          <a
            href="/admin/orderlist"
            :class="isRoute == '/admin/orderlist' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >
            Бүртгэлтэй хэрэглэгчийн захиалгууд
          </a>
          <a
            href="/admin/non-userorderlist"
            :class="
              isRoute == '/admin/non-userorderlist' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`
            "
          >
            Бүртгэлгүй хэрэглэгчийн захиалгууд
          </a>
        </div>

        <a
          href="/admin/userlist"
          :class="isRoute == '/admin/userlist' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Хэрэглэгчид</a
        >
        <a
          href="/admin/adminUsers"
          :class="isRoute == '/admin/adminUsers' ? `px-4 py-2 hover:bg-gray-700 bg-gray-700 rounded-md` : `px-4 py-2 hover:bg-gray-700  rounded-md`"
          >Админ хэрэглэгч</a
        >
      </nav>
      <div class="flex items-center justify-center h-16 bg-gray-900">
        <button @click="signOut" class="text-lg font-semibold text-blue-50 hover:text-blue-400">Гарах</button>
      </div>
    </div>

    <!-- Content -->
    <div class="flex-1 p-4">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Cookies from "js-cookie";

export default {
  name: "AdminPage",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isRoute = route.path;
    const showSubmenu = ref(false);

    const toggleSubmenu = () => {
      showSubmenu.value = !showSubmenu.value;
    };

    const signOut = () => {
      Cookies.remove("Admintoken");
      router.push("/admin/login");
    };
    return {
      isRoute,
      showSubmenu,
      toggleSubmenu,
      signOut,
    };
  },
};
</script>
