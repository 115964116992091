import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";

import HomePage from "./pages/HomePage.vue";
import CategoryPage from "./pages/CategoryPage.vue";
import ProductPage from "./pages/ProductPage.vue";
import LoginPage from "./pages/login/LoginPage.vue";
import WishList from "./pages/WishList.vue";
import BacketPage from "./pages/BacketPage.vue";
import AddressPage from "./pages/order/AddressPage.vue";
import NonUserOrder from "./pages/order/NonUserOrder.vue";
import CompleteOrder from "./pages/order/CompleteOrder.vue";
import ViewAllPage from "./pages/ViewAllPage.vue";
import SearchList from "./pages/SearchList.vue";
import ResetPassPage from "./pages/login/ResetPassPage.vue";

import UserOrder from "./pages/user/UserOrder.vue";
import UserProfile from "./pages/user/UserProfile.vue";
import RegisterQuestion from "./pages/login/RegisterQuestion.vue";
import NotFoundPage from "./pages/NotFoundPage.vue";

import AdminPage from "./pages/admin/AdminPage";
import AdminLogin from "./pages/admin/AdminLogin";
import AddCategoryPage from "./pages/admin/AddCategoryPage.vue";
import EditCategory from "./pages/admin/EditCategory.vue";
import AddProductPage from "./pages/admin/AddProductPage.vue";
import ProductList from "./pages/admin/ProductList.vue";
import BannerAddEdit from "./pages/admin/BannerAddEdit.vue";
import OrderList from "./pages/admin/OrderList.vue";
import NonUserOrderList from "./pages/admin/NonUserOrderList.vue";
import AdminOrderDetail from "./pages/admin/AdminOrderDetail.vue";
import UserList from "./pages/admin/UserList.vue";
import AdminUsers from "./pages/admin/AdminUsers.vue";

import PaymentTest from "./pages/PaymentTest.vue";
import SuccessPage from "./pages/paymentstatus/SuccessPage.vue";
import FailPage from "./pages/paymentstatus/FailPage.vue";

import CompanyPage from "./pages/footerMenu/CompanyPage.vue";
import ContractPage from "./pages/footerMenu/ContractPage.vue";
import PrivatePage from "./pages/footerMenu/PrivatePage.vue";
import GuidePage from "./pages/footerMenu/GuidePage.vue";
import CScenterPage from "./pages/footerMenu/CScenterPage.vue";
import BoardPage from "./pages/footerMenu/BoardPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: HomePage,
      meta: { title: "Нүүр" },
    },
    {
      path: "/category/:id/sortby=:sortby",
      name: "Category",
      component: CategoryPage,
      props: true,
      meta: { title: "Категор" },
    },
    {
      path: "/viewall/:id",
      name: "ViewAll",
      component: ViewAllPage,
      props: true,
      meta: { title: "Бүгд" },
    },
    {
      path: "/product/pid=:id",
      name: "Product",
      component: ProductPage,
      meta: { title: "Бүтээгдэхүүн" },
    },
    {
      path: "/search/:search",
      name: "Search",
      component: SearchList,
      meta: { title: "Хайлт" },
    },
    {
      path: "/wishlist",
      name: "WishList",
      component: WishList,
      meta: { title: "Хүслийн жагсаалт" },
    },
    {
      path: "/cart",
      name: "Cart",
      component: BacketPage,
      meta: { title: "Сагс" },
    },
    {
      path: "/order/address",
      name: "Address",
      component: AddressPage,
      meta: { title: "Хаяг" },
    },
    {
      path: "/order/nonuser",
      name: "NonUser",
      component: NonUserOrder,
      meta: { title: "Захиалга" },
    },
    {
      path: "/order/complete/orderid=:id",
      name: "Complete",
      component: CompleteOrder,
      meta: { title: "Захиалга" },
    },
    {
      path: "/login",
      name: "Login",
      component: LoginPage,
      meta: { title: "Нэвтрэх" },
    },
    {
      path: "/login/resetPassword",
      name: "ResetPassword",
      component: ResetPassPage,
      meta: { title: "Нууц үгээ шинэчлэх" },
    },
    {
      path: "/user/order",
      name: "User",
      component: UserOrder,
      meta: { title: "Миний захиалга" },
    },
    {
      path: "/user/order/orderid=:id",
      name: "UserComplete",
      component: UserOrder,
      meta: { title: "Миний захиалга" },
    },
    {
      path: "/user/profile",
      name: "Userprofile",
      component: UserProfile,
      meta: { title: "Хувийн мэдээлэл" },
    },
    {
      path: "/register",
      name: "RegisterQuestion",
      component: RegisterQuestion,
      meta: { title: "Бүртгэлийн асуулт" },
    },
    {
      path: "/admin",
      name: "Admin",
      component: AdminPage,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/login",
      name: "AdminLogin",
      component: AdminLogin,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/addcategory",
      name: "AddCategory",
      component: AddCategoryPage,
      meta: { title: "Админ" },
    },

    {
      path: "/admin/editcategory/",
      name: "EditeCategory",
      component: EditCategory,
      props: true,
      meta: { title: "Админ" },
    },

    {
      path: "/admin/addproduct",
      name: "AddProduct",
      component: AddProductPage,
      meta: { title: "Админ" },
    },

    {
      path: "/admin/productlist",
      name: "ProductList",
      component: ProductList,
      meta: { title: "Админ" },
    },

    {
      path: "/admin/editproduct/:id",
      name: "EditProduct",
      component: AddProductPage,
      props: true,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/banner",
      name: "Banner",
      component: BannerAddEdit,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/orderlist",
      name: "OrderList",
      component: OrderList,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/non-userorderlist",
      name: "NonUserOrderList",
      component: NonUserOrderList,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/orderdetail/user=:value/order=:id",
      name: "OrderDetail",
      component: AdminOrderDetail,
      props: true,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/userlist",
      name: "UserList",
      component: UserList,
      meta: { title: "Админ" },
    },
    {
      path: "/admin/adminUsers",
      name: "AdminUsers",
      component: AdminUsers,
      meta: { title: "Админ" },
    },
    {
      path: "/paymenttest",
      name: "PaymentTest",
      component: PaymentTest,
      meta: { title: "Төлбөр" },
    },
    {
      path: "/payment/success",
      name: "PaymentSuccess",
      component: SuccessPage,
      meta: { title: "Төлбөр" },
    },
    // {
    //   path: "/payment/success?orderId=:id&paymentKey=:key&amount=:amount",
    //   name: "PaymentSuccess",
    //   component: SuccessPage,
    //   meta: { title: "Төлбөр" },
    // },
    {
      path: "/payment/fail",
      name: "PaymentFail",
      component: FailPage,
      meta: { title: "Төлбөр" },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "Page404",
      component: NotFoundPage,
    },
    {
      path: "/service/company",
      name: "CompanyPage",
      component: CompanyPage,
    },
    {
      path: "/service/agreement",
      name: "ContractPage",
      component: ContractPage,
    },
    {
      path: "/service/private",
      name: "PrivatePage",
      component: PrivatePage,
    },
    {
      path: "/service/guide",
      name: "GuidePage",
      component: GuidePage,
    },
    {
      path: "/service",
      name: "CScenterPage",
      component: CScenterPage,
    },
    {
      path: "/board",
      name: "BoardPage",
      component: BoardPage,
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   document.title = "MongolTown" + " | " + to.meta.title;
//   if (to.path !== "/login" && !to.path.includes("/admin") && !to.path.includes("/payment")) {
//     // && !to.path.includes("/user")
//     next();
//   }
//   // else if (to.path === "/login" && to.path !== "/user/questions") {
//   //   if (Cookies.get("BStoken")) {
//   //     next("/");
//   //   } else {
//   //     next();
//   //   }
//   // }
//   // else if (to.path.includes("/user")) {
//   //   if (Cookies.get("BStoken") && jwt_decode(Cookies.get("BStoken")).new_user) {
//   //     next("/user/questions");
//   //   } else if (Cookies.get("BStoken") && !jwt_decode(Cookies.get("BStoken")).new_user) {
//   //     next("/");
//   //   }
//   // }
//   else if (to.path.includes("/admin") && to.path !== "/admin/login") {
//     if (Cookies.get("Admintoken")) {
//       next();
//     } else {
//       next("/admin/login");
//     }
//   } else if (to.path.includes("/admin") && to.path === "/admin/login") {
//     if (Cookies.get("Admintoken")) {
//       next("/admin");
//     } else {
//       next();
//     }
//   }
// });

// router.beforeEach((to, from, next) => {
//   document.title = "MongolTown" + " | " + to.meta.title;
//   // if (to.path !== "/user/questions") {
//   //   if (Cookies.get("BStoken")) {
//   //     const decodedToken = jwt_decode(Cookies.get("BStoken"));
//   //     if (decodedToken.new_user) {
//   //       if (!to.path.startsWith("/admin")) {
//   //         next("/user/questions");
//   //         return;
//   //       }
//   //     }
//   //   }
//   // } else if (to.path === "/user/questions") {
//   //   if (Cookies.get("BStoken")) {
//   //     const decodedToken = jwt_decode(Cookies.get("BStoken"));
//   //     if (!decodedToken.new_user) {
//   //       if (!to.path.startsWith("/admin")) {
//   //         next("/");
//   //         return;
//   //       }
//   //     }
//   //   }
//   // }

//   if (to.path !== "/login" && !to.path.includes("/admin") && !to.path.includes("/payment")) {
//     // if (Cookies.get("BStoken")) {
//     //   next();
//     // } else {
//     //   next("/login");
//     // }
//     next();
//   } else if (to.path === "/login" && Cookies.get("BStoken")) {
//     next("/");
//   } else if (to.path.includes("/user")) {
//     if (Cookies.get("BStoken")) {
//       next();
//     } else {
//       next("/login");
//     }
//   } else if (to.path.includes("/admin") && to.path !== "/admin/login") {
//     if (Cookies.get("Admintoken")) {
//       next();
//     } else {
//       next("/admin/login");
//     }
//   } else if (to.path.includes("/admin") && to.path === "/admin/login") {
//     if (Cookies.get("Admintoken")) {
//       next("/admin");
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  if (to.path === "/admin/orderlist") {
    console.log("admin/orderlist");
  }
  // document.title = "MongolTown" + " | " + to.meta.title;
  document.title = to.meta.title === undefined || to.meta.title === null ? "MglTown" : "MglTown" + " | " + to.meta.title;
  // if (to.path !== "/user/questions") {
  //   if (Cookies.get("BStoken")) {
  //     const decodedToken = jwt_decode(Cookies.get("BStoken"));
  //     if (decodedToken.new_user) {
  //       if (!to.path.startsWith("/admin")) {
  //         next("/user/questions");
  //         return;
  //       }
  //     }
  //   }
  // } else if (to.path === "/user/questions") {
  //   if (Cookies.get("BStoken")) {
  //     const decodedToken = jwt_decode(Cookies.get("BStoken"));
  //     if (!decodedToken.new_user) {
  //       if (!to.path.startsWith("/admin")) {
  //         next("/");
  //         return;
  //       }
  //     }
  //   }
  // }

  if (to.path !== "/login" && !to.path.includes("/admin") && !to.path.includes("/payment") && to.path !== "/register") {
    // if (Cookies.get("BStoken")) {
    //   next();
    // } else {
    //   next("/login");
    // }
    next();
  } else if (to.path === "/register" && Cookies.get("BStoken")) {
    next("/");
  } else if (to.path === "/login" && Cookies.get("BStoken")) {
    next("/");
  } else if (to.path.includes("/user") && !to.path.includes("/admin/userlist") && !to.path.includes("/admin/orderdetail/user=")) {
    if (Cookies.get("BStoken")) {
      next();
    } else {
      next("/login");
      // next("/register");
    }
  } else if (to.path.includes("/admin") && to.path !== "/admin/login") {
    if (Cookies.get("Admintoken")) {
      next();
    } else {
      next("/admin/login");
    }
  } else if (to.path.includes("/admin") && to.path === "/admin/login") {
    if (Cookies.get("Admintoken")) {
      next("/admin");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
