<template>
  <admin-page>
    <p class="text-2xl font-bold mb-4">Захиалгын дэлгэрэнгүй</p>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div v-if="tableOrderDetailData.length > 0">
        <hot-table :settings="hotOrderDetailSettings" :data="tableOrderDetailData" ref="hotTable"></hot-table>
      </div>
    </div>
    <p class="text-2xl font-bold mb-4">Захиалсан бараанууд</p>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div v-if="tableProductData?.length > 0">
        <hot-table :settings="hotProductSettings" :data="tableProductData" ref="hotTable"></hot-table>
      </div>
    </div>
  </admin-page>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

import instance from "@/lib/common/axios";
export default {
  name: "AdminOrderDetail",
  components: {
    HotTable,
  },
  setup() {
    const route = useRoute();
    const tableOrderDetailData = ref([]);
    const tableProductData = ref([]);

    onBeforeMount(async () => {
      if (!route.params.id) return;

      if (route.params.id && route.params.value === "true") {
        try {
          const { data } = await instance.get(`/admin/getorderbyorderid/${route.params.id}`);
          tableOrderDetailData.value = [data];
          tableProductData.value = data?.products;
        } catch (error) {
          console.log(error);
        }
      } else if (route.params.id && route.params.value === "false") {
        try {
          const { data } = await instance.get(`/admin/getnon-user-orderbyorderid/${route.params.id}`);
          tableOrderDetailData.value = [data];
          tableProductData.value = data?.products;
        } catch (error) {
          console.log(error);
        }
      }
    });

    const hotProductSettings = {
      colHeaders: ["Барааны нэр", "Барааны зураг", "Барааны үнэ", "Төрөл 1", "Төрөл 2", "Төрөл 3", "Захиалсан тоо", "Нийт үнэ"],
      columns: [
        {
          data: "name",
          width: 200,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "image",
          width: 100,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center"><img src="${value}" alt="" class="w-20 h-20"></div>`;
          },
        },
        {
          data: "price",
          width: 100,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "variants1",
          width: 150,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "variants2",
          width: 150,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "variants3",
          width: 150,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "quantity",
          width: 100,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "totalPrice",
          width: 100,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}вон</div>`;
          },
        },
      ],
      rowHeaders: true,
      manualRowResize: true,
      manualColumnResize: true,
      width: "100%",
      height: "auto",
      // rowHeights: 30,
      // manualRowResize: true,
      // manualColumnResize: true,
      // manualRowMove: true,
      // manualColumnMove: true,
      // rowHeaderWidth: 50,
      // columnHeaderHeight: 50,
      // contextMenu: true,
      // filters: true,
      // dropdownMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };
    const hotOrderDetailSettings = {
      colHeaders: ["Захиалгын дугаар", "Захиалсан хэрэглэгч", "Захиалсан огноо", "Захиалгын нийт дүн", "Хаяг"],
      columns: [
        {
          data: "orderId",
          width: 300,
          readOnly: true,
        },
        {
          data: "name",
          width: 200,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "orderDate",
          width: 200,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${new Date(value).toLocaleDateString()}</div>`;
          },
        },
        {
          data: "total",
          width: 200,
          readOnly: true,
          renderer(instance, td, row, col, prop, value) {
            td.innerHTML = `<div class="flex justify-center items-center">${value}вон</div>`;
          },
        },
        {
          data: "deliveryAddress",
          width: 300,
          readOnly: true,
        },
      ],
      // rowHeaders: true,
      manualRowResize: true,
      manualColumnResize: true,
      width: "100%",
      height: "70",
      // rowHeights: 30,
      // manualRowResize: true,
      // manualColumnResize: true,
      // manualRowMove: true,
      // manualColumnMove: true,
      // rowHeaderWidth: 50,
      // columnHeaderHeight: 50,
      // contextMenu: true,
      // filters: true,
      // dropdownMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    registerAllModules();

    return {
      tableOrderDetailData,
      tableProductData,
      hotOrderDetailSettings,
      hotProductSettings,
    };
  },
};
</script>
