<template>
  <div class="mt-3 mr-1" v-for="item in product" :key="item.id">
    <div class="py-3">
      <div class="md:grid md:grid-cols-7 md:gap-3 md:flex items-center mb-5">
        <div class="col-span-4 grid grid-cols-5">
          <div class="col-span-2 md:col-span-1 p-1 mr-5">
            <div class="w-full pb-full overflow-hidden relative bg-white rounded-xl">
              <a :href="`/product/pid=${item.products.product_id}`">
                <img :src="item.products.image_url" alt="" />
              </a>
            </div>
          </div>

          <div class="col-span-3">
            <a :href="`/product/pid=${item?.products.product_id}`" class="text-sm"> {{ item?.products.name }} </a>
            <p class="text-sm font-bold mt-3">
              <span v-if="item?.products?.variants1">{{ item.products.variants1 }}</span>
              <span v-if="item?.products?.variants2">- {{ item.products.variants2 }}</span>
              <span v-if="item?.products?.variants3">- {{ item.products.variants3 }}</span>
            </p>
          </div>
        </div>
        <div class="col-span-3 grid grid-cols-3 flex items-center mt-5 md:mt-0">
          <div class="hidden md:block col-span-1 text-center text-sm">{{ item?.products.price.toLocaleString() }} вон</div>
          <div class="col-span-1 font-bold bg-gray-200 text-secondary rounded bg-slate-50 grid grid-cols-3 flex justify-center items-center text-sm">
            <button @click="minusQuantity(item)" class="flex justify-center items-center h-full hover:bg-gray-300 hover:rounded-l py-1">
              <img :class="{ 'opacity-50 cursor-not-allowed': item.products.quantity === 1 }" src="../../assets/common/minus.svg" alt="" />
            </button>
            <span class="text-center">{{ item.products.quantity }}</span>

            <button @click="addQuantity(item)" class="flex justify-center items-center h-full hover:bg-gray-300 hover:rounded-r py-1">
              <img src="../../assets/common/add.svg" alt="" />
            </button>
          </div>

          <div class="col-span-2 md:col-span-1 text-right text-sm">
            <span class="pr-3 text-xs uppercase font-medium md:hidden text-gray-500"> Нийт үнэ: </span
            >{{ (item.products.price * item.products.quantity).toLocaleString() }} вон
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end items-center">
      <button @click="deleteBacketById(item.products, item.id)" class="flex justify-end items-center text-gray-700 mb-5">
        <img src="../../assets/common/recycle-bin.svg" alt="" />
        <span class="text-sm pl-1">Устгах</span>
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive } from "vue";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { addToBacketList } from "@/lib/common/helper";

export default {
  props: {
    product: {
      type: Array,
      required: true,
    },
  },
  emits: ["minusTotal", "addTotal", "removeProductById"],
  setup(props, { emit }) {
    const store = useStore();
    const backet = reactive({
      userId: "",
      products: {},
    });

    const deleteBacketById = async ({ product_id, variants1, variants2, variants3, price, quantity }, id) => {
      if (Cookies.get("BStoken")) {
        const userID = jwt_decode(Cookies.get("BStoken")).user_id;
        const response = await store.dispatch("products/insertUpdateDeleteBacket", {
          id: id,
          userId: userID,
        });
        if (response.statusText == "OK" || response.status == 200) window.location.reload();
        else alert(response.statusText);
      } else {
        const index = props.product.findIndex((item) => {
          if (!item.products.variants1 && !item.products.variants2 && !item.products.variants3) return item.products.product_id === product_id;
          else
            return (
              (!product_id || item.products.product_id === product_id) &&
              (!variants1 || item.products.variants1 === variants1) &&
              (!variants2 || item.products.variants2 === variants2) &&
              (!variants3 || item.products.variants3 === variants3)
            );
        });
        if (index > -1) {
          emit("removeProductById", { index: index, quantity: quantity, total: price * quantity });
        }
      }
    };

    const minusQuantity = async (item) => {
      if (item.products.quantity > 1) {
        item.products.quantity -= 1;
        emit("minusTotal", { price: item.products.price });
        if (Cookies.get("BStoken")) {
          const userID = jwt_decode(Cookies.get("BStoken")).user_id;
          await store.dispatch("products/insertUpdateDeleteBacket", { id: item.id, quantity: item.products.quantity, userId: userID });
        } else {
          backet.products = item.products;
          addToBacketList(backet);
        }
      } else if (item.products.quantity === 1) {
        item.products.quantity = 1;
      }
    };

    const addQuantity = async (item) => {
      if (item.products.quantity < item.products.stock) {
        item.products.quantity += 1;
        emit("addTotal", { price: item.products.price });
        if (Cookies.get("BStoken")) {
          const userID = jwt_decode(Cookies.get("BStoken")).user_id;
          await store.dispatch("products/insertUpdateDeleteBacket", { id: item.id, quantity: item.products.quantity, userId: userID });
        } else {
          backet.products = item.products;
          addToBacketList(backet);
        }
      } else {
        alert(`Та хамгийн ихдээ ${item.products.stock} бараа захиалах боломжтой!`);
      }
    };

    return {
      deleteBacketById,
      minusQuantity,
      addQuantity,
    };
  },
};
</script>
