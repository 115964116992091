<template>
  <div>
    <h1>주문서</h1>
    <div id="payment-method"></div>
    <button @click="clickPaymentButton">결제하기</button>
    <div>
      <button @click="tossPayment">төлбөр</button>
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";
const paymentWidget = window.PaymentWidget(process.env.VUE_APP_TOSS_CLIENT_KEY, process.env.VUE_APP_TOSS_SECRET_KEY);
const tossPayments = window.TossPayments(process.env.VUE_APP_TOSS_CLIENT_KEY);

export default {
  setup() {
    onMounted(() => {
      paymentWidget.renderPaymentMethods("#payment-method", { value: 50_000 });
    });
    const clickPaymentButton = () => {
      paymentWidget
        .requestPayment({
          orderId: "order-1234", // 주문번호
          orderName: "리팩터링 2판 외 1권",
          successUrl: window.location.origin + "/success",
          failUrl: window.location.origin + "/fail",
          customerEmail: "customer123@gmail.com", // 고객 이메일 (선택)
          customerName: "김토스", // 고객 이름 (선택)
          currency: "USD", // 통화 (선택)
        })
        .then((v) => console.log(v));
    };
    // 카드
    const tossPayment = () => {
      try {
        tossPayments.requestPayment("카드", {
          amount: 101,
          orderId: "order-123777",
          orderName: "리팩터링 2판 외 1권",
          successUrl: window.location.origin + "/payment/success",
          failUrl: window.location.origin + "/payment/fail",
          customerName: "김토스",
          customerEmail: "s",
          currency: "KRW", // 통화 (선택)
        });
      } catch (e) {
        console.log(e);
      }
    };
    return {
      clickPaymentButton,
      tossPayment,
    };
  },
};
</script>
