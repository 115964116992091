<template>
  <div class="fixed inset-0 flex items-center justify-center z-[170]">
    <div class="absolute w-full h-full bg-gray-900 opacity-75"></div>
    <div class="relative flex flex-col items-center space-y-4">
      <svg class="animate-spin h-8 w-8 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.86-3.14 7-7 7V17z"
        ></path>
      </svg>
      <p class="text-white">Loading...</p>
    </div>
  </div>
</template>
