<template>
  <div class="bg-white">
    <div class="flex justify-center items-center max-w-[650px] mx-auto px-5">
      <div class="flex flex-col justify-between w-full md:w-4/6 py-10 md:py-5">
        <div class="my-10">
          <div id="logo" class="flex justify-center mx-auto mb-4">
            <img :src="require('../../assets/common/logo.jpeg')" class="w-32" href="/" />
          </div>
          <div>
            <div class="py-5 px-8 md:px-10 md:py-8 flex justify-center items-center">
              <div class="grid grid-cols-1 gap-4 content-center text-center w-full">
                <div class="flex relative">
                  <input
                    class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                    placeholder="Нууц үгээ шинэчлэх / new password"
                    v-model="newUserPassword"
                    :type="passwordVisible ? 'text' : 'password'"
                    autocomplete="new-password"
                  />
                  <button @click="togglePasswordVisibility" class="absolute top-3 right-3 cursor-pointer">
                    <img src="../../assets/common/eye-off.svg" alt="" v-if="!passwordVisible" />
                    <img src="../../assets/common/eye-on.svg" alt="" v-else />
                  </button>
                </div>

                <div class="flex relative">
                  <input
                    class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                    placeholder="confirm your new password / Шинэ нууц үгээ давтан оруулна уу"
                    v-model="newUserConfirmPassword"
                    :type="confirmVisible ? 'text' : 'password'"
                    autocomplete="new1-password"
                  />
                  <button @click="toggleConfirmPassVisiblity" class="absolute top-3 right-3 cursor-pointer">
                    <img src="../../assets/common/eye-off.svg" alt="" v-if="!confirmVisible" />
                    <img src="../../assets/common/eye-on.svg" alt="" v-else />
                  </button>
                </div>

                <p v-if="passValid" class="text-red-500 text-xs">
                  <span>
                    Таны нууц үгэнд нэг том үсэг, нэг жижиг үсэг,нэг тоо, нэг тэмдэгт орсон байх ёстой ба хамгийн багадаа 8 оронтой байх ёстой.
                  </span>
                </p>

                <button @click="submitNewPass" class="bg-slate-500 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-sm" type="button">
                  Нууц үг шинэчлэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useValidation } from "../../lib/common/useValidation";

export default {
  name: "RestPassPage",
  setup() {
    const store = useStore();
    const router = useRouter();
    const newUserPassword = ref("");
    const newUserConfirmPassword = ref("");

    const passwordVisible = ref(false);
    const confirmVisible = ref(false);
    const passValid = ref(false);

    const { validatePassword } = useValidation();

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const toggleConfirmPassVisiblity = () => {
      confirmVisible.value = !confirmVisible.value;
    };

    const submitNewPass = async () => {
      if (newUserPassword.value == newUserConfirmPassword.value) {
        const userPass = validatePassword(newUserPassword.value);
        const confirmPass = validatePassword(newUserConfirmPassword.value);
        if (userPass && confirmPass) {
          const res = await store.dispatch("login/resetPass", { newPassword: newUserPassword.value, email: router.currentRoute.value.query.email });
          if (res.status == 200) {
            router.push({
              path: "/login",
              query: { status: "successfully" },
            });
          }
        } else {
          passValid.value = true;
        }
      } else {
        alert("Таны шинэ нууц үг давтан нууц үгтэй ижил байх ёстой");
      }
    };

    return {
      submitNewPass,
      togglePasswordVisibility,
      toggleConfirmPassVisiblity,
      newUserPassword,
      newUserConfirmPassword,
      passwordVisible,
      confirmVisible,
      passValid,
    };
  },
};
</script>
