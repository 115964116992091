<template>
  <div class="flex-grow bg-white">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5">
        <div class="mt-10 grid grid-cols-2 justify-center items-center border-2 border-gray-400 pb-2 p-4">
          <div class="border-r-1 border-slate-300">
            <h3 class="font-medium text-sm text-gray-500 mb-2">자주묻는 질문 검색</h3>
            <div class="flex justify-center items-center gap-2 mr-4">
              <input
                class="bg-textbox border border-gray-300 w-full py-2 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                type="text"
                placeholder="검색어를 입력하세요"
              />
              <div class="bg-gray-400 border border-gray-300 text-center py-[10px] w-20 gap-2">
                <span class="text-sm text-gray-600 font-bold">검색</span>
              </div>
            </div>
          </div>

          <div class="ml-4">
            <h3 class="font-medium text-sm text-gray-500 mb-2">찾으시는 질문이 없다면?</h3>
            <div class="border border-gray-400 w-[100px] p-1 text-center">
              <a href="" class="text-xs text-gray-500">1:1 문의하기</a>
            </div>
          </div>
        </div>

        <div class="mt-10">
          <div class="flex justify-between items-center border-b border-gray-400 pb-2 px-2">
            <h3 class="font-medium text-lg text-gray-600">BEST FAQ</h3>
          </div>
          <div class="mt-2">
            <ul class="flex gap-4 text-gray-400 text-sm cursor-pointer">
              <div @click="handleAll">
                <li>전체</li>
              </div>

              <div @click="handleOne">
                <li>회원가입/정보</li>
              </div>
              <div @click="handleTwo">
                <li>결제/배송</li>
              </div>
              <div @click="handleThree">
                <li>교환/반품/환불</li>
              </div>

              <div @click="handleFour">
                <li>마일리지 적립</li>
              </div>
              <div @click="handleFive">
                <li>기타</li>
              </div>
            </ul>
          </div>

          <div class="mt-2" v-if="show.showAll">
            <table class="w-full mt-2 border-collapse">
              <thead class="border-t border-b border-gray-400 bg-gray-200">
                <tr class="text-gray-500 text-sm">
                  <th class="px-4 py-2">번호</th>
                  <th class="px-4 py-2">분류</th>
                  <th class="px-4 py-2">내용</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center text-gray-500 border-b border-gray-400 text-xs">
                  <td class="px-4 py-2">3</td>
                  <td class="px-4 py-2">[결제/배송]</td>
                  <td class="flex gap-4 px-4 py-2">
                    <div>Q</div>
                    <div>Бараа захиалга(Бүртгэлгүй)</div>
                  </td>
                </tr>

                <tr class="text-center text-xs text-gray-500 border-b border-gray-400">
                  <td class="px-4 py-2">2</td>
                  <td class="px-4 py-2">[결제/배송]</td>
                  <td class="flex gap-4 px-4 py-2">
                    <div>Q</div>
                    <div>Бараа захиалга(Бүртгүүлэх)</div>
                  </td>
                </tr>

                <tr class="text-center text-xs text-gray-500 border-b border-gray-400">
                  <td class="px-4 py-2">1</td>
                  <td class="px-4 py-2">[회원가입/정보]</td>
                  <td class="flex gap-4 px-4 py-2">
                    <div>Q</div>
                    <div>Шинээр бүртгүүлэх</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-2" v-if="show.showDisplayOne">
            <table class="w-full mt-2 border-collapse">
              <thead class="border-t border-b border-gray-400 bg-gray-200">
                <tr class="text-gray-500 text-sm">
                  <th class="px-4 py-2">번호</th>
                  <th class="px-4 py-2">분류</th>
                  <th class="px-4 py-2">내용</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center text-xs text-gray-500 border-b border-gray-400">
                  <td class="px-4 py-2">2</td>
                  <td class="px-4 py-2">[결제/배송]</td>
                  <td class="flex gap-4 px-4 py-2">
                    <div>Q</div>
                    <div>Бараа захиалга(Бүртгүүлэх)</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-2" v-if="show.showDisplayTwo">
            <table class="w-full mt-2 border-collapse">
              <thead class="border-t border-b border-gray-400 bg-gray-200">
                <tr class="text-gray-500 text-sm">
                  <th class="px-4 py-2">번호</th>
                  <th class="px-4 py-2">분류</th>
                  <th class="px-4 py-2">내용</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center text-gray-500 border-b border-gray-400 text-xs">
                  <td class="px-4 py-2">3</td>
                  <td class="px-4 py-2">[결제/배송]</td>
                  <td class="flex gap-4 px-4 py-2">
                    <div>Q</div>
                    <div>Бараа захиалга(Бүртгэлгүй)</div>
                  </td>
                </tr>

                <tr class="text-center text-xs text-gray-500 border-b border-gray-400">
                  <td class="px-4 py-2">1</td>
                  <td class="px-4 py-2">[회원가입/정보]</td>
                  <td class="flex gap-4 px-4 py-2">
                    <div>Q</div>
                    <div>Шинээр бүртгүүлэх</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-2" v-if="show.showDisplayThree">
            <table class="w-full mt-2 border-collapse">
              <thead class="border-t border-b border-gray-400 bg-gray-200">
                <tr class="text-gray-500 text-sm">
                  <th class="px-4 py-2">번호</th>
                  <th class="px-4 py-2">분류</th>
                  <th class="px-4 py-2">내용</th>
                </tr>
              </thead>
              <!-- <tbody></tbody> -->
            </table>
          </div>

          <div class="mt-2" v-if="show.showDisplayFour">
            <table class="w-full mt-2 border-collapse">
              <thead class="border-t border-b border-gray-400 bg-gray-200">
                <tr class="text-gray-500 text-sm">
                  <th class="px-4 py-2">번호</th>
                  <th class="px-4 py-2">분류</th>
                  <th class="px-4 py-2">내용</th>
                </tr>
              </thead>
              <!-- <tbody></tbody> -->
            </table>
          </div>

          <div class="mt-2" v-if="show.showDisplayFive">
            <table class="w-full mt-2 border-collapse">
              <thead class="border-t border-b border-gray-400 bg-gray-200">
                <tr class="text-gray-500 text-sm">
                  <th class="px-4 py-2">번호</th>
                  <th class="px-4 py-2">분류</th>
                  <th class="px-4 py-2">내용</th>
                </tr>
              </thead>
              <!-- <tbody></tbody> -->
            </table>
          </div>
        </div>

        <div class="grid grid-cols-2 mt-40">
          <div class="mb-20">
            <div class="flex justify-between items-center">
              <h3 class="text-sm text-gray-700">공지사항</h3>
              <a href="">
                <span class="text-xs text-gray-400"> 더보기 </span>
              </a>
            </div>
            <div class="border-b border-gray-300 flex justify-between items-center text-xs mt-4 pb-2 text-gray-400">
              <p>[이벤트] 신규 오픈 이벤트를 진행합니다.</p>
              <p>2018.03.22</p>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  setup() {
    const show = reactive({
      showAll: true,
      showDisplayOne: false,
      showDisplayTwo: false,
      showDisplayThree: false,
      showDisplayFour: false,
      showDisplayFive: false,
    });

    const handleAll = () => {
      for (const key in show) {
        if (key !== "showAll") {
          show[key] = false;
        }
      }
      show.showAll = true;
    };
    const handleOne = () => {
      for (const key in show) {
        if (key !== "showDisplayOne") {
          show[key] = false;
        }
      }
      show.showDisplayOne = true;
    };
    const handleTwo = () => {
      for (const key in show) {
        if (key !== "showDisplayTwo") {
          show[key] = false;
        }
      }
      show.showDisplayTwo = true;
    };

    const handleThree = () => {
      for (const key in show) {
        if (key !== "showDisplayThree") {
          show[key] = false;
        }
      }
      show.showDisplayThree = true;
    };
    const handleFour = () => {
      for (const key in show) {
        if (key !== "showDisplayFour") {
          show[key] = false;
        }
      }
      show.showDisplayFour = true;
    };
    const handleFive = () => {
      for (const key in show) {
        if (key !== "showDisplayFive") {
          show[key] = false;
        }
      }
      show.showDisplayFive = true;
    };
    return {
      show,
      handleAll,
      handleOne,
      handleTwo,
      handleThree,
      handleFour,
      handleFive,
    };
  },
};
</script>
