<template>
  <div class="md:h-72">
    <div class="py-32 md:pt-8">
      <div class="flex justify-center">
        <img class="w-32" :src="require('../../assets/common/empty-box.png')" alt="" />
      </div>
      <p class="text-center">Захиалга байхгүй байна</p>
    </div>
  </div>
</template>
