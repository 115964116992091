import instance from "@/lib/common/axios";
import Cookies from "js-cookie";

export default {
  namespaced: true,
  state() {
    return {
      isAuthenticated: null,
      adminAuth: null,
      accounts: [],
    };
  },
  getters: {
    getAuth(state) {
      return state.isAuthenticated;
    },
    getAdminAuth(state) {
      return state.adminAuth;
    },
    getAccounts(state) {
      return state.accounts;
    },
  },

  mutations: {
    setAuth(state, payload) {
      state.isAuthenticated = payload;
    },
    setAdminAuth(state, payload) {
      state.adminAuth = payload;
    },
    setAccounts(state, payload) {
      state.accounts = payload;
    },
  },

  actions: {
    // async fetchProducts({ commit }) {
    //   try {
    //     const { data } = await instance.get("/products");
    //     console.log(data);
    //     commit("setProducts", data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    async postToken(_, payload) {
      try {
        return await instance.post("/account/login", payload);
      } catch (error) {
        console.error(error);
      }
    },

    async postAdmin(_, payload) {
      try {
        return await instance.post("/admin/login", { idToken: payload });
      } catch (error) {
        console.error(error);
      }
    },

    // Ajilgaand oruulah ********************************
    async forgotPass(_, payload) {
      try {
        return await instance.post("/account/login/forgotPass", payload);
      } catch (error) {
        console.error(error);
      }
    },

    async verifyCode(_, payload) {
      try {
        return await instance.post("/account/login/verifyCode", payload);
      } catch (error) {
        console.error(error);
      }
    },

    async resetPass(_, payload) {
      try {
        return await instance.post("/account/login/resetPass", payload);
      } catch (error) {
        console.error(error);
      }
    },

    // with Authentication
    async fetchAccount(context, payload) {
      try {
        const response = await instance.post("/account/fetchAccountByUserID", { userId: payload, token: Cookies.get("BStoken") });
        context.commit("setAccounts", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async updateUserInfo(_, payload) {
      try {
        const response = await instance.post("/account/updateUserInfo", payload, { headers: { "Content-Type": "multipart/form-data" } });
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
