import instance from "@/lib/common/axios";
import { categories, navCategories } from "./category/category";
import Cookies from "js-cookie";

export default {
  namespaced: true,
  state() {
    return {
      products: [],
      categoryProducts: [],
      productsPopular: [],
      homePageProducts: [],
      wishListCount: localStorage.getItem("wishList") ? JSON.parse(localStorage.getItem("wishList")).length : 0,
      backetCount: localStorage.getItem("backetList") ? JSON.parse(localStorage.getItem("backetList"))?.length : 0,
      backetonLocalStorage: localStorage.getItem("backetList") ? JSON.parse(localStorage.getItem("backetList")) : [],
      categories: categories,
      navCategories: navCategories,
      backetData: [],
      wishListData: [],
      searchProducts: [],
      bannerProducts: [],
    };
  },

  getters: {
    getProducts(state) {
      return state.products;
    },

    getCategoryProducts(state) {
      return state.categoryProducts;
    },

    getHomePageProducts(state) {
      return state.homePageProducts;
    },

    getWishListCount(state) {
      return state.wishListCount;
    },

    getSearchProducts(state) {
      return state.searchProducts;
    },

    getBannerProducts(state) {
      return state.bannerProducts;
    },

    // shiljuulj magadgui
    getBacketData(state) {
      return state.backetData;
    },
    getWishList(state) {
      return state.wishListData;
    },
    getBacketCount(state) {
      return state.backetCount;
    },
  },

  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },

    setCategoryProducts(state, payload) {
      state.categoryProducts = payload;
    },

    setHomePageProducts(state, payload) {
      state.homePageProducts = payload;
    },

    setSearchProducts(state, payload) {
      state.searchProducts = payload;
    },

    setBannerProducts(state, payload) {
      state.bannerProducts = payload;
    },

    // shiljuulj magadgui
    setWishListCount(state, payload) {
      state.wishListCount = payload;
    },
    setBacketListCount(state, payload) {
      state.backetCount = payload;
    },
    setDecrementWishListCount(state, payload) {
      state.wishListCount = payload;
    },
    // setItemCount(state, count) {
    //   console.log("setItemCount----->", count)
    //   state.itemCount = count;
    // },

    setBacketData(state, payload) {
      state.backetData = payload;
    },
    setWishListData(state, payload) {
      state.wishListData = payload;
    },
  },

  actions: {
    async fetchProductsByCategory({ commit }, payload) {
      // try {
      //   const { data } = await instance.get(`/categories/${payload.id}/sortby/${payload.sortby}`);
      //   commit("setCategoryProducts", data);
      // } catch (error) {
      //   console.log(error);
      // }
      console.log("payload--->", payload.token);

      const query = new URLSearchParams({
        category: payload.category.id,
        sortby: payload.category.sortby,
        pageNum: payload.pageNum,
        token: payload.token,
      });
      try {
        // const { data } = await instance.post("/categories/category/products", { data: payload });
        const { data } = await instance.get(`/categories/category/products?${query}`);
        // return response;
        commit("setCategoryProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchProductsBySubCategory({ commit }, payload) {
      try {
        const { data } = await instance.get(`/categories/${payload.id}/sub_cat/${payload.subid}`);
        commit("setCategoryProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchProductsPopular({ commit }) {
      try {
        const { data } = await instance.get("/products/views/popular");
        commit("setProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchHomePageProducts({ commit }, payload) {
      try {
        const { data } = await instance.get(`/products/home/townshop/${payload}`);
        commit("setHomePageProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchViewAllPageProducts({ commit }, payload) {
      try {
        const { data } = await instance.get(`/products/view/all/${payload.id}/${payload.token}`);
        commit("setProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async getProductsBySearch({ commit }, payload) {
      try {
        const { data } = await instance.get(`/products/search/${payload}`);
        commit("setSearchProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchBannerProducts({ commit }) {
      try {
        const { data } = await instance.get("/products/banners");
        commit("setBannerProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchFilteredProducts({ commit }, payload) {
      try {
        const { data } = await instance.post("/products/filter", payload);
        commit("setProducts", data);
      } catch (error) {
        console.log(error);
      }
    },

    // Backet API with auth ****************************************************************
    async insertUpdateDeleteBacket(_, payload) {
      try {
        payload.token = Cookies.get("BStoken");
        const response = await instance.post("/backet/insertUpdateDeleteBacket", payload);
        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async getBacketData({ commit }, payload) {
      try {
        const { data } = await instance.post("/backet/getProducts", { userId: payload, token: Cookies.get("BStoken") });
        commit("setBacketData", data.data);
      } catch (error) {
        console.log(error);
      }
    },

    // WishList API with auth ***************************************************************
    async addToWishList(_, payload) {
      payload.token = Cookies.get("BStoken");
      try {
        const response = await instance.post("/wishList/insertUpdateDeleteWishList", payload);
        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async getWishListData({ commit }, payload) {
      try {
        const { data } = await instance.post("/wishList/getProducts", { userId: payload, token: Cookies.get("BStoken") });
        commit("setWishListData", data);
      } catch (error) {
        console.log(error);
      }
    },

    decrementWishListCount({ commit }) {
      const wishList = JSON.parse(localStorage.getItem("wishList"));
      if (wishList) {
        commit("setWishListCount", wishList.length - 1);
      }
    },

    incrementWishListCount({ commit }, payload) {
      const wishList = JSON.parse(localStorage.getItem("wishList"));
      if (wishList) {
        const productIndex = wishList.find((p) => p.id === payload);
        if (productIndex === -1) {
          commit("setWishListCount", wishList.length + 1);
        }
      }
    },
  },
};
