<template>
  <div class="flex-grow bg-white">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5">
        <!-- <div class="flex justify-end items-center my-4">
          <input
            placeholder="search"
            name="lastname"
            type="text"
            class="w-70 bg-transparent border border-slate-300 rounded px-4 py-2 text-sm outline-primary"
          />
          <button class="bg-blue-500 border rounded p-[7px]">
            <img src="../../assets/common/search.svg" alt="" class="w-6" />
          </button>
        </div> -->

        <div class="flex justify-between items-center border-b border-gray-400 pb-2 px-2">
          <h3 class="font-bold text-lg text-gray-800">회사소개</h3>
        </div>
        <p class="text-xs my-6 text-gray-500">
          인터넷 비지니스는 이제 우리 삶에서 가장 중요한 연결 매개체로 자리잡았습니다.<br />
          NHN커머스는 다년간 오직 한길, 전자상거래 솔루션만은 전문적으로 개발/운영하여 왔습니다.<br /><br />
          NHN커머스는 웹비지니스의 구축보다 사후관리를 더욱 중요하게 생각합니다. 운영과정에서 더 큰 도움이 되고자 노력합니다.<br /><br />
          NHN커머스는 치열한 경쟁시대에서 앞서나갈 수 있도록 강력하게 도와드리는 가장 든든한 조력자가 될 것입니다.<br />
          말뿐이 아닌 지속적인 솔루션 개발과 고퀄리티 웹 사이트 맞춤 컨설팅으로 고객 제일주의를 실현하겠습니다.<br /><br />
          고객을 위해 흘리는 땀을 두려워하지 않는 정직한 기업이 될 것을 약속드립니다..<br /><br />
          NHN커머스의 문은 고객여러분께 항상 열려있습니다.<br /><br />
          한분 한분 고객님들과 같이 커가는 NHN커머스가 되겠습니다.<br /><br />
          감사합니다.<br /><br />
        </p>
      </div>
    </div>
  </div>
</template>
