<template>
  <div>
    <div class="max-w-[1240px] mx-auto mb-36">
      <div class="bg-gray-100 p-4 flex gap-6 items-center border-b-1 md:hidden">
        <div class="rounded-full w-12 h-12 border-1 border-slate-950"></div>
        <p>Usernjhbjbhame</p>
      </div>

      <div class="md:flex md:gap-4 md:pt-4">
        <div class="hidden md:block flex-[30%] bg-white rounded-sm">
          <div class="flex items-center gap-4 border-b-1 p-4">
            <div class="rounded-full w-12 h-12 border-1 border-slate-950"></div>
            <p>Username</p>
          </div>

          <div class="grid gap-6 py-4">
            <a href="/user/profile">
              <div class="flex gap-2 items-center px-4">
                <div>
                  <img class="w-8" :src="require('../../assets/common/user.svg')" alt="" />
                </div>
                <p>Хувийн мэдээлэл</p>
              </div>
            </a>

            <a href="/user/order">
              <div class="flex gap-2 items-center px-4 border-l-2 border-slate-900">
                <div>
                  <img class="w-8" :src="require('../../assets/common/cardboard-box.png')" alt="" />
                </div>
                <p>Захиалгууд</p>
              </div>
            </a>

            <div @click="logOut" class="flex gap-3 items-center px-4">
              <div class="pl-1">
                <img class="w-6" :src="require('../../assets/common/logout.png')" alt="" />
              </div>
              <p>Гарах</p>
            </div>
          </div>
        </div>

        <div class="p-4 border-b-1 bg-white cursor-pointer md:hidden" @click="toggleMenu">
          <div class="flex items-center relative gap-4 border-1 border-gray-400 rounded-md px-4 py-2">
            <div>
              <img class="w-6" :src="require('../../assets/common/cardboard-box.png')" alt="" />
            </div>
            <p>Захиалгууд</p>
            <div class="absolute right-5">
              <img v-if="!showMenu" class="w-4" :src="require('../../assets/common/chevron-arrow-down.png')" alt="" />
              <img v-if="showMenu" class="w-4 rotate-180" :src="require('../../assets/common/chevron-arrow-down.png')" alt="" />
            </div>
            <transition name="fade">
              <div class="absolute top-10 left-0 bg-white w-full" v-if="showMenu">
                <ul class="grid gap-4 px-4 py-2 border-1 rounded-sm shadow-lg">
                  <li>
                    <a href="/user/profile">
                      <div class="flex gap-4">
                        <div>
                          <img class="w-6" :src="require('../../assets/common/user.svg')" alt="" />
                        </div>
                        <p>Хувийн мэдээлэл</p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/user/order">
                      <div class="flex gap-4">
                        <div>
                          <img class="w-6" :src="require('../../assets/common/cardboard-box.png')" alt="" />
                        </div>
                        <p>Захиалгууд</p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <div @click="logOut" class="flex gap-4">
                      <div class="pl-1">
                        <img class="w-5" :src="require('../../assets/common/logout.png')" alt="" />
                      </div>
                      <p>Гарах</p>
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
        <div class="bg-white pt-8 md:pt-0 flex-[65%] rounded-sm">
          <div class="hidden md:block py-7 pl-4 border-b-1">
            <p class="uppercase font-semibold">Миний Захиалгууд</p>
          </div>

          <user-order-list v-if="!isOrderDetail" :orderList="orderList"></user-order-list>
          <user-order-detail v-if="isOrderDetail" :orderDetail="orderDetail"></user-order-detail>
          <user-order-empty v-if="!isOrderDetail && orderList.length === 0"></user-order-empty>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import Cookies from "js-cookie";
// import { useStore } from "vuex";
import jwtDecode from "jwt-decode";
import instance from "@/lib/common/axios";

import UserOrderEmpty from "@/components/user/UserOrderEmpty.vue";
import UserOrderList from "@/components/user/UserOrderList.vue";
import UserOrderDetail from "@/components/user/UserOrderDetail.vue";
export default {
  components: {
    UserOrderEmpty,
    UserOrderList,
    UserOrderDetail,
  },
  setup() {
    // const store = useStore();
    const route = useRoute();
    const showMenu = ref(false);
    const orderList = ref({});
    const orderDetail = ref({});
    const isOrderDetail = ref(false);

    onBeforeMount(async () => {
      if (Cookies.get("BStoken")) {
        // await store.dispatch("user/fetchCompleteOrders", jwtDecode(Cookies.get("BStoken")).user_id);
        const userId = jwtDecode(Cookies.get("BStoken")).user_id;
        if (route.params.id) {
          const { data } = await instance.get(`/user/completeorderdetail/${route.params.id}`);
          orderDetail.value = data;
          isOrderDetail.value = true;
        } else {
          const { data } = await instance.get(`/user/completeorder/${userId}`);
          orderList.value = data.orderInfo;
          isOrderDetail.value = false;
        }
      }
    });
    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const logOut = () => {
      Cookies.remove("BStoken");
      window.location.href = "/";
    };

    return {
      orderDetail,
      orderList,
      showMenu,
      isOrderDetail,
      toggleMenu,
      logOut,
    };
  },
};
</script>
