<template>
  <div class="hidden border-y border-slate-100 px-4 md:block bg-white">
    <div class="max-w-[1240px] flex py-4 mx-auto">
      <button @click="$emit('toggle-side-menu')" class="flex gap-2 lg:hidden">
        <img class="w-6 h-6" :src="require('../../assets/common/menu.svg')" alt="" />
        <span class="font-bold uppercase text-slate-900">Ангилал</span>
      </button>
      <div class="hidden lg:flex lg:w-[100%] min-[1180px]:w-[100%] gap-6 flex-wrap">
        <!-- justify-between -->
        <a class="text-slate-900 font-semibold uppercase text-[12px]" href="/viewall/popular">Хамгийн эрэлттэй | 인기 있는</a>
        <a
          class="text-slate-900 font-semibold uppercase text-[12px]"
          v-for="navCategory in navCategories"
          :key="navCategory"
          :href="`/category/${navCategory?.id}/sortby=views`"
        >
          {{ navCategory.name }}</a
        >
        <!-- <a class="text-slate-900 font-semibold uppercase text-[14px]" href="/category/costco">Костко</a> -->
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
export default {
  name: "DesktopNavbar",
  props: {
    toggleSideMenu: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = useStore();
    const navCategories = ref([]);
    onBeforeMount(async () => {
      await store.dispatch("categories/fetchNavCategories");
      navCategories.value = store.getters["categories/getNavCategories"];
    });
    return {
      navCategories,
    };
  },
};
</script>
