<template>
  <div id="app" class="mb-10">
    <ul class="flex justify-center items-center">
      <li
        v-if="currentPage > 1"
        class="px-3 py-2 text-sm font-medium text-white hover:bg-slate-900 bg-slate-800 border border-gray-300 rounded-md mr-2 cursor-pointer"
      >
        <a @click="changePage(currentPage - 1)"> Prev </a>
      </li>

      <li v-if="currentPage > 2" class="text-base px-5 font-medium text-gray-500 cursor-pointer">
        <a @click="changePage(1)"> 1 </a>
      </li>

      <li v-if="currentPage > 2" class="text-base px-5 font-medium text-gray-500">...</li>

      <li
        v-for="page in displayedPages"
        :key="page"
        :class="{ 'text-blue-500': page === currentPage }"
        class="text-base px-5 font-medium text-gray-500 cursor-pointer"
      >
        <a @click="changePage(page)">
          {{ page }}
        </a>
      </li>

      <li v-if="displayedPages[displayedPages.length - 1] !== totalPages" class="text-base px-5 font-medium text-gray-500">...</li>
      <li v-if="displayedPages[displayedPages.length - 1] !== totalPages" class="text-base px-5 font-medium text-gray-500 cursor-pointer">
        <a @click="changePage(totalPages)">
          {{ totalPages }}
        </a>
      </li>
      <li
        v-if="currentPage < totalPages"
        class="px-3 py-2 text-sm font-medium text-white hover:bg-slate-900 bg-slate-800 border border-gray-300 rounded-md ml-2 cursor-pointer"
      >
        <a @click="changePage(currentPage + 1)"> Next </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { onMounted, onUpdated, ref, computed } from "vue";

export default {
  name: "PaginationVue",
  props: {
    totalProductPages: Number,
  },
  async setup(props, { emit }) {
    const currentPage = ref(1);
    const totalPages = ref(0);

    const displayedPages = computed(() => {
      const pages = [];
      let startPage = 1;
      let endPage = totalPages.value;
      const maxDisplayedPages = 3;

      if (totalPages.value > maxDisplayedPages) {
        const middlePage = Math.ceil(maxDisplayedPages / 2);
        startPage = currentPage.value - middlePage + 1;
        endPage = currentPage.value + middlePage - 1;

        if (startPage < 1) {
          endPage += Math.abs(startPage) + 1;
          startPage = 1;
        }

        if (endPage > totalPages.value) {
          startPage -= endPage - totalPages.value;
          endPage = totalPages.value;
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    });

    const changePage = (page) => {
      currentPage.value = page;
    };

    onMounted(() => {
      totalPages.value = props.totalProductPages;
    });

    onUpdated(() => {
      emit("sent-pageNum", currentPage.value);
      totalPages.value = props.totalProductPages;
    });

    return {
      displayedPages,
      changePage,
      currentPage,
      totalPages
    };
  },
};
</script>
