<template>
  <div class="flex justify-center h-[70vh] bg-gray-100 text-gray-700 text-2xl font-bold">
    <div class="flex flex-col items-center">
      <div class="flex justify-center items-center border-2 rounded-full mt-16">
        <img class="h-40 w-40" src="../../assets/common/fail.svg" alt="" />
      </div>
      <div class="text-center mt-5">
        <h1 class="text-2xl font-bold">Төлбөр амжилтгүй боллоо.</h1>
        <p class="text-sm mt-2">{{ errorMes ? errorMes : "Таны захиалга амжилтгүй боллоо." }}</p>
        <p class="text-sm mt-2">Та дахин оролдоно уу.</p>
      </div>
      <div class="flex justify-center items-center mt-5">
        <button class="bg-black text-white text-center text-xs font-bold uppercase rounded h-10 w-48 hover:shadow-lg" @click="backToBacket">
          Сагс руу буцах
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const errorMes = ref("");

    onMounted(async () => {
      if (route.query.message) {
        errorMes.value = route.query.message;
      }
    });
    const backToBacket = () => {
      router.push("/cart");
      window.scrollTo(0, 0);
    };
    return {
      backToBacket,
      errorMes,
    };
  },
};
</script>
