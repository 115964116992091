import axios from "axios";

let baseURL = "";

if (process.env.NODE_ENV === "production") {
  baseURL = "https://mgltown.com";
} else {
  // baseURL = "http://localhost";
  baseURL = "https://mgltown.com";
}

const instance = axios.create({
  baseURL,
});

export default instance;
