<template>
  <admin-page>
    <h1 class="text-2xl font-semibold mb-4">Бүтээгдэхүүний жагсаалт</h1>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div v-if="userList.length > 0"><hot-table :settings="hotSettings" :data="userList" ref="hotTable"></hot-table></div>
    </div>
  </admin-page>
</template>
<script>
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import instance from "@/lib/common/axios";
import { ref, onBeforeMount } from "vue";
export default {
  name: "UserList",
  components: {
    HotTable,
  },
  setup() {
    const userList = ref([]);
    registerAllModules();

    onBeforeMount(async () => {
      const { data } = await instance.get("/admin/getuserlist");
      userList.value = data;
    });

    const hotSettings = {
      data: [],
      columnSorting: {
        initialConfig: {
          column: 2,
          sortOrder: "asc",
        },
      },
      width: "100%",
      height: "auto",
      rowHeaders: true,
      licenseKey: "non-commercial-and-evaluation",
      colHeaders: ["Овог", "Нэр", "Имэйл", "Утас", "Хаяг", "Бүртгүүлсэн огноо", "Хүйс", "Женма гишүүн", "PV оноо"],
      colWidths: [200, 200, 200, 100, 200, 200, 100, 150, 150],
      columns: [
        {
          data: "lastName",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          data: "firstName",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center capitalize">${value ? value : ""}</div>`;
          },
        },
        {
          data: "email",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "phone",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "address",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "createdAt",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "gender",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "isGenma",
          type: "checkbox",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? "Тийм" : "Үгүй"}</div>`;
          },
        },
        {
          data: "pvPoint",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            console.log(value);

            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value.toLocaleString() : ""}</div>`;
          },
        },
      ],
    };

    return {
      userList,
      hotSettings,
    };
  },
};
</script>
