<template>
  <div class="p-4">
    <div v-for="order in orderList" :key="order.orderId" class="p-4 pt-2 shadow-md mb-8 rounded-md">
      <a :href="`/user/order/orderid=${order?.orderId}`">
        <div class="border-b-1">
          <div class="flex justify-between mb-1">
            <p class="text-gray-600">Захиалгын дугаар</p>
            <p class="font-semibold">{{ order?.orderId }}</p>
          </div>
          <div class="flex justify-between mb-2">
            <p class="text-gray-600">Огноо</p>
            <p class="">{{ order?.orderDate }}</p>
          </div>
        </div>

        <div class="flex justify-between mt-2">
          <p class="text-gray-600">Захиалагын дүн</p>
          <p class="font-semibold">{{ order?.total.toLocaleString() }} вон</p>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderList: {
      type: Object,
      default: null,
    },
  },
};
</script>
