<template>
  <swiper
    :modules="module"
    navigation
    :autoplay="{ delay: 2000, disableOnInteraction: false }"
    class="mySwiper"
    @swiper="onSwiper"
    :breakpoints="breakpoint"
  >
    <template v-for="product in products" :key="product.id">
      <swiper-slide>
        <product-card :product="product" :isLoading="isLoading" :isLoggedIn="isLoggedIn"></product-card>
      </swiper-slide>
    </template>

    <template v-if="shouldDuplicateSlides">
      <swiper-slide v-for="product in firstFewProducts" :key="'end' + product.id">
        <product-card :product="product" :isLoading="isLoading" :isLoggedIn="isLoggedIn"></product-card>
      </swiper-slide>
    </template>

    <template v-if="shouldDuplicateSlides">
      <swiper-slide v-for="product in lastFewProducts" :key="'start' + product.id">
        <product-card :product="product" :isLoading="isLoading" :isLoggedIn="isLoggedIn"></product-card>
      </swiper-slide>
    </template>
  </swiper>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ProductCard from "@/components/product/ProductCard.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    ProductCard,
  },
  props: {
    product: {
      type: Array,
      required: true,
    },
    slidePerView: {
      type: Number,
      default: 3,
    },
    modules: {
      type: Array,
      default: () => [],
    },
    breakpoints: {
      type: Object,
      default: () => ({}),
    },
    onSwiper: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const products = ref(props.product);
    onMounted(() => {
      // if(isLoggedIn){}
      products.value = props.product;
    });

    const firstFewProducts = computed(() => {
      return products.value.slice(0, 5);
    });

    const lastFewProducts = computed(() => {
      const endIndex = products.value.length - 1;
      const startIndex = endIndex - props.slidePerView + 1;
      return products.value.slice(startIndex, endIndex + 1);
    });

    const shouldDuplicateSlides = computed(() => {
      return products.value && products.value.length > 5;
    });

    return {
      module: props.modules,
      breakpoint: props.breakpoints,
      products,
      firstFewProducts,
      lastFewProducts,
      shouldDuplicateSlides,
    };
  },
};
</script>
