<template>
  <div>
    <div class="max-w-[1240px] mx-auto mb-36">
      <div class="bg-gray-100 p-4 flex gap-6 items-center border-b-1 md:hidden">
        <div class="rounded-full w-12 h-12 border-1 border-slate-950"></div>
        <p>UserName: {{ userName }}</p>
      </div>

      <div class="md:flex md:gap-4 md:pt-4">
        <div class="hidden md:block flex-[30%] bg-white rounded-sm">
          <div class="flex items-center gap-4 border-b-1 p-4">
            <div class="rounded-full w-12 h-12 border-1 border-slate-950 cursor-pointer flex justify-center items-center" @click="openImageDialog">
              <img
                v-if="getUserData?.data && getUserData?.data[0]?.profile_img !== null"
                class="w-12 h-12 rounded-full object-fit"
                :src="getUserData?.data[0]?.profile_img"
                alt=""
              />
              <img v-else src="../../assets/common/add.svg" alt="" />
            </div>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="image"
              type="file"
              name="image"
              style="display: none"
              @change="handleImageChange"
            />
            <p v-if="getUserData?.data && getUserData?.data[0]?.first_name !== null">{{ getUserData?.data[0]?.first_name }}</p>
            <p v-else>UserName</p>
          </div>
          <div class="grid gap-6 py-4">
            <a href="/user/profile">
              <div class="flex gap-2 items-center px-4 border-l-2 border-slate-900">
                <div>
                  <img class="w-8" :src="require('../../assets/common/user.svg')" alt="" />
                </div>
                <p>Хувийн мэдээлэл / Personal information</p>
              </div>
            </a>

            <a href="/user/order">
              <div class="flex gap-2 items-center px-4">
                <div>
                  <img class="w-8" :src="require('../../assets/common/cardboard-box.png')" alt="" />
                </div>
                <p>Захиалгууд / Orders</p>
              </div>
            </a>

            <div @click="logOut" class="flex gap-3 items-center px-4 cursor-pointer">
              <div class="pl-1">
                <img class="w-6" :src="require('../../assets/common/logout.png')" alt="" />
              </div>
              <p>Гарах / Sign Out</p>
            </div>
          </div>
        </div>
        <div class="p-4 border-b-1 bg-white cursor-pointer md:hidden" @click="toggleMenu">
          <div class="flex items-center relative gap-4 border-1 border-gray-400 rounded-md px-4 py-2">
            <div>
              <img class="w-6" :src="require('../../assets/common/cardboard-box.png')" alt="" />
            </div>
            <p>Хувийн мэдээлэл / Personal information</p>
            <div class="absolute right-5">
              <img v-if="!showMenu" class="w-4" :src="require('../../assets/common/chevron-arrow-down.png')" alt="" />
              <img v-if="showMenu" class="w-4 rotate-180" :src="require('../../assets/common/chevron-arrow-down.png')" alt="" />
            </div>
            <transition name="fade">
              <div class="absolute top-10 left-0 bg-white w-full" v-if="showMenu">
                <ul class="grid gap-4 px-4 py-2 border-1 rounded-sm shadow-lg">
                  <li>
                    <a href="/user/profile">
                      <div class="flex gap-4">
                        <div>
                          <img class="w-6" :src="require('../../assets/common/user.svg')" alt="" />
                        </div>
                        <p>Хувийн мэдээлэл / Personal information</p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/user/order">
                      <div class="flex gap-4">
                        <div>
                          <img class="w-6" :src="require('../../assets/common/cardboard-box.png')" alt="" />
                        </div>
                        <p>Захиалгууд</p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <div @click="logOut" class="flex gap-4 cursor-pointer">
                      <div class="pl-1">
                        <img class="w-5" :src="require('../../assets/common/logout.png')" alt="" />
                      </div>
                      <p>Гарах</p>
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>

        <div class="bg-white pt-8 md:pt-0 flex-[65%] rounded-sm">
          <div class="hidden md:block py-7 pl-4 border-b-1">
            <p class="uppercase font-semibold">Хувийн мэдээлэл / Personal information</p>
          </div>
          <div class="md:h-auto">
            <!-- md:h-96 -->
            <loading-spinner class="flex justify-center items-center md:h-96" v-if="isLoading" />
            <div v-else>
              <div class="md:mt-4 pb-8" v-if="getUserData.data">
                <div class="px-4 md:my-4 sm:mb-4">
                  <span class="text-xs text-red-500">Та хувийн мэдээллээ засахдаа кириллээр бичин оруулна уу!</span>
                </div>

                <form action="" v-for="item in getUserData.data" :key="item.id">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                    <div>
                      <label for="lastName" class="block text-sm mb-2"> Овог / LastName</label>
                      <input
                        v-on:change="(event) => handleLastName(event, item)"
                        :value="userInfo.lastName ? userInfo.lastName : item.last_name"
                        id="lastName"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div>
                      <label for="firstName" class="block text-sm mb-2"> Нэр / FirstName</label>
                      <input
                        v-on:change="(event) => handleName(event, item)"
                        :value="userInfo.name ? userInfo.name : item.first_name"
                        id="firstName"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div>
                      <label for="birthday" class="block text-sm mb-2"> Birthday</label>
                      <input
                        v-on:change="(event) => handleBirthday(event, item)"
                        :value="userInfo.birthday ? userInfo.birthday : item.birthday"
                        id="birthday"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="date"
                      />
                    </div>

                    <div>
                      <label class="block text-sm mb-2"> Хүйс</label>
                      <select class="border-1 py-1 px-2 rounded-md w-full" v-on:change="(event) => handleGender(event)">
                        <option selected disabled hidden :value="userInfo.gender ? userInfo.gender : item.gender">
                          {{ item.gender }}
                        </option>
                        <option value="эр">эр</option>
                        <option value="эм">эм</option>
                      </select>
                    </div>

                    <div>
                      <label for="email" class="block text-sm mb-2"> Имайл / Email</label>
                      <input
                        id="email"
                        v-on:change="(event) => handleEmail(event, item)"
                        :value="userInfo.email ? userInfo.email : item.email"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div>
                      <label for="phoneNumber" class="block text-sm mb-2"> Утас / Mobile Number</label>
                      <input
                        v-on:change="(event) => handlePhoneNumber(event, item)"
                        :value="userInfo.phoneNumber ? userInfo.phoneNumber : item.phone_num"
                        id="phoneNumber"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div>
                      <label for="regNum" class="block text-sm mb-2"> Регистрийн дугаар / National ID</label>
                      <input
                        v-on:change="(event) => handleRegisteraNum(event, item)"
                        :value="userInfo.registerNumber ? userInfo.registerNumber : item.register_num"
                        id="regNum"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div>
                      <label for="address" class="block text-sm mb-2">Хаяг : Солонгос / Монгол</label>
                      <input
                        id="address"
                        v-on:change="(event) => handleAddress(event, item)"
                        :value="userInfo.address ? userInfo.address : item.address"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div>
                      <label for="detailAddress" class="block text-sm mb-2">Дэлгэрэнгүй хаяг</label>
                      <input
                        id="detailAddress"
                        v-on:change="(event) => handleDetailAddress(event, item)"
                        :value="userInfo.detailAddress ? userInfo.detailAddress : item.detailed_address"
                        class="border-1 py-1 px-2 rounded-md w-full"
                        type="text"
                      />
                    </div>

                    <div class="md:mt-7">
                      <div
                        @click="searchAddress"
                        class="bg-sky-500 text-sm text-white border p-[6px] rounded-md text-center hover:bg-gray-400 cursor-pointer"
                      >
                        Солонгос хаяг / Korean address
                      </div>
                    </div>

                    <div class="flex justify-center items-center gap-6 cursor-pointer">
                      <div
                        @click="submitUser(item)"
                        class="text-base text-gray-700 hover:text-gray-500 hover:bg-gray-300 border p-1 rounded-md w-full text-center"
                      >
                        Засах / Edit
                      </div>
                    </div>
                  </div>
                  <div class="px-4 mt-4">
                    <span class="text-xs text-red-500"
                      >Та өөрийн хаягаа солонгос болгох эсвэл солонгос хаягаа өөрчлөхийг хүсвэл солонгос хаяг товч дарж өөрчилнө. Хэрэв монгол хаяг
                      оруулах, өөрчлөх тохиолдолд хаяг инпут дээр дарж монгол хаягаа кириллээр бичин оруулна уу!</span
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import instance from "../../lib/common/axios";
import { useValidation } from "../../lib/common/useValidation";
import LoadingSpinner from "../../components/ui/LoadingSpinner.vue";
import jwtDecode from "jwt-decode";

export default {
  components: {
    LoadingSpinner,
  },
  setup() {
    const store = useStore();
    const showMenu = ref(false);

    const userName = ref("");
    const imageInput = ref(null);
    const isLoading = ref(false);

    const { validateRegisterNum, validateEmail } = useValidation();

    const userInfo = reactive({
      lastName: "",
      name: "",
      registerNumber: "",
      phoneNumber: "",
      email: "",
      birthday: "",
      gender: "",
      address: "",
      detailAddress: "",
    });

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const logOut = () => {
      Cookies.remove("BStoken");
      window.location.href = "/";
    };

    onMounted(async () => {
      if (Cookies.get("BStoken")) {
        const decodedToken = jwt_decode(Cookies.get("BStoken"));
        await store.dispatch("login/fetchAccount", decodedToken.user_id);
        imageInput.value = document.getElementById("image");
      }
    });

    const openImageDialog = () => {
      imageInput.value.click();
    };

    const handleImageChange = async (event) => {
      try {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("id", getUserData.value.data[0].id);
        formData.append("userId", jwt_decode(Cookies.get("BStoken")).user_id);
        formData.append("token", Cookies.get("BStoken"));
        const res = await store.dispatch("login/updateUserInfo", formData);
        if (res.status === 200) {
          window.location.reload();
        }
      } catch (e) {
        console.error(e.message);
      }
    };

    const getUserData = computed(() => store.getters["login/getAccounts"]);

    const handleLastName = (e) => {
      userInfo.lastName = e.target.value;
    };

    const handleName = (e) => {
      userInfo.name = e.target.value;
    };

    const handleBirthday = (e) => {
      userInfo.birthday = e.target.value;
    };

    const handleGender = (e) => {
      userInfo.gender = e.target.value;
    };

    const handleEmail = (e) => {
      userInfo.email = e.target.value;
    };

    const handlePhoneNumber = (e) => {
      userInfo.phoneNumber = e.target.value;
    };

    const handleRegisteraNum = (e) => {
      userInfo.registerNumber = e.target.value;
    };

    const handleAddress = (e) => {
      userInfo.address = e.target.value;
    };

    const handleDetailAddress = (e) => {
      userInfo.detailAddress = e.target.value;
    };

    const searchAddress = async (e) => {
      e.preventDefault();
      window.daum.postcode.load(function () {
        new window.daum.Postcode({
          oncomplete: function (data) {
            if (data) {
              userInfo.address = data.address;
            } else {
              alert("Хаяг олдсонгүй!");
            }
          },
        }).open();
      });
    };

    const submitUser = async (item) => {
      const data = {
        id: item.id,
        lastName: userInfo.lastName ? userInfo.lastName : item.last_name,
        name: userInfo.name ? userInfo.name : item.first_name,
        registerNum: userInfo.registerNumber ? userInfo.registerNumber : item.register_num,
        phoneNum: userInfo.phoneNumber ? userInfo.phoneNumber : item.phone_num,
        email: userInfo.email ? userInfo.email : item.email,
        birthday: userInfo.birthday ? userInfo.birthday : item.birthday,
        gender: userInfo.gender ? userInfo.gender : item.gender,
        address: userInfo.address ? userInfo.address : item.address,
        detailAddress: userInfo.detailAddress ? userInfo.detailAddress : item.detailed_address,
      };

      const validateEmailRes = validateEmail(data.email);
      if (!validateEmailRes) {
        alert("Та мэйл хаягаа зөв бичиж оруулна уу! / Please enter your email address correctly!");
      } else {
        isLoading.value = true;
        const emailRes = await instance.post("account/checkEmailByUserID", {
          email: data.email,
          userId: jwt_decode(Cookies.get("BStoken")).user_id,
          token: Cookies.get("BStoken"),
        });
        if (emailRes.data.status === "empty") {
          isLoading.value = false;
          const status = validateRegisterNum(data.registerNum);
          if (!status) {
            alert("Та регистрийн дугаараа зөв оруулна уу");
          } else {
            isLoading.value = true;
            const resRegister = await instance.post("/account/checkRegisterNumByUserID", {
              registerNum: data.registerNum,
              userId: jwt_decode(Cookies.get("BStoken")).user_id,
              token: Cookies.get("BStoken"),
            });
            if (resRegister.data.status === "already registered") {
              isLoading.value = false;
              alert(
                "Таны оруулсан регистрийн дугаар системд аль хэдийн бүртгэгдсэн байна. Та өөр регистрийн дугаар ашиглан бүртгүүлнэ үү! / The registration number you entered is already registered in the system. Please register using a different registration number!"
              );
            } else {
              try {
                isLoading.value = false;
                data.token = Cookies.get("BStoken");
                data.userId = jwtDecode(Cookies.get("BStoken")).user_id;
                const res = await store.dispatch("login/updateUserInfo", data);
                if (res.status == 200) return window.location.reload();
              } catch (err) {
                console.error(err);
              }
            }
          }
        } else if (emailRes.data.status === "already registered") {
          isLoading.value = false;
          alert(
            "Таны оруулсан мэйл хаяг системд аль хэдийн бүртгэгдсэн байна. Та өөр мэйл хаяг ашиглан бүртгүүлнэ үү! / The email you entered is already registered in the system. Please register using a different email!"
          );
        }
      }
    };

    return {
      showMenu,
      userName,
      getUserData,
      logOut,
      toggleMenu,
      handleLastName,
      handleName,
      handleBirthday,
      handleGender,
      handleEmail,
      handlePhoneNumber,
      handleRegisteraNum,
      handleAddress,
      handleDetailAddress,
      submitUser,
      openImageDialog,
      handleImageChange,
      userInfo,
      searchAddress,
      isLoading,
    };
  },
};
</script>
