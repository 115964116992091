<template>
  <div className="flex flex-col gap-4 justify-center items-center h-screen">
    <div id="buttonDiv"></div>
    <p v-if="isShow" class="text-red-500 text-base text-center p-4">
      Таны мэйл хаяг амжилттай бүртгэгдлээ. Та нэвтрэхийг хүсвэл Админтай холбогдон бүртгэлээ баталгаажуулна уу!
    </p>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "LoginPage",
  setup() {
    const store = useStore();
    const isShow = ref(false);
    const router = useRouter();

    const initializeGoogleSignIn = () => {
      window.google?.accounts.id.initialize({
        client_id: "677235543627-k0kuc9d2e9hhb7h6gkt61i6tug9knj5u.apps.googleusercontent.com", // Replace with your window.Google Client ID
        callback: handleCredentialResponse,
      });

      window.google?.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" } // customization attributes
      );

      window.google?.accounts.id.prompt(); // also display the One Tap dialog
    };

    onMounted(() => {
      initializeGoogleSignIn();
    });

    const handleCredentialResponse = async (response) => {
      try {
        const res = await store.dispatch("login/postAdmin", response.credential);
        console.log("m1", res);
        if (res.data.token) {
          const expirationDate = new Date();
          expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000);
          Cookies.set("Admintoken", res.data.token, {
            expires: expirationDate,
          });
          router.push({ path: "/admin/addcategory" });
        } else if (res.data.unregistered == "success") {
          isShow.value = true;
        }
      } catch (e) {
        console.error(e);
      }
    };

    return {
      handleCredentialResponse,
      isShow,
    };
  },
};
</script>
