import { createStore } from "vuex";
import products from "./products";
import categories from "./categories";
import admin from "./admin";
import login from "./login";
import order from "./order/order";
import orderNonUser from "./order/orderNonUser";
import user from "./user/user";

export default createStore({
  modules: {
    products,
    categories,
    login,
    admin,
    order,
    orderNonUser,
    user,
  },
});
