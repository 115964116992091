<template>
  <div class="bg-white rounded cursor-pointer">
    <a :href="`/product/pid=${product?.products.id}`">
      <div class="relative aspect-square md:p-2">
        <img class="w-full h-full bg-white object-contain object-center" :src="product?.products.image_url" alt="" />
        <div
          class="absolute top-0 left-0 bg-red-500 text-white text-xs text-center font-medium px-1 mt-4 ml-4"
          v-if="product?.products.does_have_discount && product?.products.discount"
        >
          {{ product?.products.discount }}%
        </div>
      </div>
      <div class="px-5">
        <div class="mt-1">
          <div class="line-clamp-2 text-sm flex flex-row md:mt-0 mt-3">
            <p class="truncate capitalize text-[16px]">{{ product?.products.name }}</p>
          </div>
        </div>

        <!-- <div class="pt-2">
          <div class="flex justify-start items-center">
            <p class="font-extrabold text-secondary text-lg mr-2">{{ product?.price }}вон</p>
          </div>
          <div class="flex justify-between items-center">
            <p class="text-xs">{{ product?.description }}</p>
          </div>
        </div> -->
      </div>
    </a>
    <div class="flex justify-end items-center px-4 pb-2 mt-4">
      <div>
        <button class="flex justify-end items-center text-gray-700 pr-3 md:pr-0 text-sm" @click="$emit('removeFromWishList', product.id)">
          <img src="../../assets/common/recycle-bin.svg" alt="" />
          <span class="pl-1">Устгах</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
