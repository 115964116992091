import instance from "@/lib/common/axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
export default {
  namespaced: true,
  state() {
    return {
      orderDetail: {
        userId: "",
        orderId: "",
        customer: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          registerNum: "",
          address: "",
        },
        products: [],
        productsTotal: 0,
        shippingAddress: "",
        shippingAddresses: [],
        paymentMethod: "",
        deliveryCountry: "",
        deliveryMethod: "",
        deliveryWay: "",
        deliveryFee: 0,
        vat: 0,
        total: 0,
        pvPoint: 0,
        isNotReciever: false,
        recieverAddress: "",
        recieverDetailedAddress: "",
        recieverLastName: "",
        recieverName: "",
        recieverPhone: "",
        recieverSecondPhone: "",
        recieverRegisterNum: "",
        recieverEmail: "",
      },
    };
  },
  getters: {
    getAddress(state) {
      return state.address;
    },
    getOrderDetail(state) {
      return state.orderDetail;
    },
  },
  mutations: {
    setOrderId(state, payload) {
      state.orderDetail.orderId = payload;
    },
    setAddress(state, payload) {
      state.address = payload;
    },
    setShippingAddresses(state, payload) {
      state.orderDetail.shippingAddresses = payload;
    },
    setAddShippingAddresses(state, payload) {
      state.orderDetail.shippingAddresses.push(payload);
    },
    setUserId(state, payload) {
      state.orderDetail.userId = payload;
    },
    setCustomerEmail(state, payload) {
      state.orderDetail.customer.email = payload;
    },
    setCustomer(state, payload) {
      state.orderDetail.customer.firstName = payload.firstName;
      state.orderDetail.customer.lastName = payload.lastName;
      state.orderDetail.customer.phone = payload.phone;
      state.orderDetail.customer.address = payload.address;
      state.orderDetail.customer.registerNum = payload.registerNum;
    },
    setProducts(state, payload) {
      state.orderDetail.products = payload;
    },
    setProductsTotal(state, payload) {
      state.orderDetail.productsTotal = payload;
    },
    setPvPoint(state, payload) {
      state.orderDetail.pvPoint = payload;
    },
    setPaymentMethod(state, payload) {
      state.orderDetail.paymentMethod = payload;
    },
    setDeliveryCountry(state, payload) {
      if (payload === "korea") {
        // state.orderDetail.total -= state.orderDetail.deliveryFee;
        // state.orderDetail.deliveryFee = 0;
        state.orderDetail.deliveryCountry = payload;
      } else if (payload === "mongolia") {
        state.orderDetail.deliveryCountry = payload;
      }
    },
    setDeliveryMethod(state, payload) {
      // console.log("set delivery method", payload);

      state.orderDetail.deliveryMethod = payload;
    },
    setDeliveryWay(state, payload) {
      state.orderDetail.deliveryWay = payload;
    },

    setDeliveryFee(state, payload) {
      if (state.orderDetail.deliveryMethod !== payload.method) {
        state.orderDetail.total -= state.orderDetail.deliveryFee; // subtract old fee from total
        state.orderDetail.deliveryFee = payload.fee;
        state.orderDetail.total += payload.fee; // add new fee to total
      }
    },

    setShippingAddress(state, payload) {
      state.orderDetail.shippingAddress = payload;
    },
    setVat(state, payload) {
      state.orderDetail.vat = state.orderDetail.total * payload;
      state.orderDetail.total += state.orderDetail.vat;
    },
    updateVat(state, payload) {
      if (payload === "mongolia") {
        state.orderDetail.vat = state.orderDetail.productsTotal * 0.1;
        state.orderDetail.total += state.orderDetail.vat;
      } else if (payload === "korea") {
        state.orderDetail.total -= state.orderDetail.vat;
        state.orderDetail.vat = 0;
      }
    },
    setTotalAmount(state, payload) {
      state.orderDetail.total = Math.floor(payload);
    },

    // when user click back button
    resetBackToOrginalDetail(state) {
      state.orderDetail = {
        userId: "",
        orderId: "",
        customer: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          registerNum: "",
          address: "",
        },
        products: [],
        productsTotal: 0,
        shippingAddress: "",
        shippingAddresses: [],
        paymentMethod: "",
        deliveryCountry: "",
        deliveryMethod: "",
        deliveryFee: 0,
        vat: 0,
        total: 0,
        isNotReciever: false,
        recieverName: "",
        recieverLastName: "",
        recieverEmail: "",
        recieverPhone: "",
        recieverSecondPhone: "",
        recieverRegisterNum: "",
        recieverAddress: "",
      };
    },

    setIsNotReciever(state, payload) {
      state.orderDetail.isNotReciever = payload;
    },

    // setting reciever info individually ================
    setRecievername(state, payload) {
      // console.log("set reciever name", payload);

      state.orderDetail.recieverName = payload;
    },
    setRecieverphoneNum(state, payload) {
      state.orderDetail.recieverPhone = payload;
    },
    setRecieverdetailAddress(state, payload) {
      state.orderDetail.recieverDetailedAddress = payload;
    },
    // setting reciever info individually ================

    setRecieverInfo(state, payload) {
      // console.log("set reciever info", payload);

      state.orderDetail.recieverName = payload.firstName;
      state.orderDetail.recieverLastName = payload.lastName;
      state.orderDetail.recieverAddress = payload.address;
      state.orderDetail.recieverDetailedAddress = payload.detailAddress;
      state.orderDetail.recieverPhone = payload.phoneNum;
      state.orderDetail.recieverSecondPhone = payload.secondPhoneNum;
      state.orderDetail.recieverRegisterNum = payload.registerNum;
      state.orderDetail.recieverEmail = payload.email;
    },
    resetRecieverInfo(state) {
      state.orderDetail.isNotReciever = false;
      state.orderDetail.recieverName = "";
      state.orderDetail.recieverLastName = "";
      state.orderDetail.recieverAddress = "";
      state.orderDetail.recieverDetailedAddress = "";
      state.orderDetail.recieverPhone = "";
      state.orderDetail.recieverSecondPhone = "";
      state.orderDetail.recieverRegisterNum = "";
      state.orderDetail.recieverEmail = "";
    },
    setRecieverDeliveryAddress(state, payload) {
      state.orderDetail.recieverAddress = payload;
    },
  },
  actions: {
    async getCompletedOrderDetail(_, payload) {
      try {
        const { data } = await instance.get(`/order/getCompletedOrderDetail/${{ id: payload }}`);
        return data;
      } catch (error) {
        console.error(error);
      }
    },

    // with Authentication
    async postAddress(context, payload) {
      payload.token = Cookies.get("BStoken");
      try {
        return await instance.post("/order/insertUpdateAddress", payload);
      } catch (error) {
        console.error(error);
      }
    },

    async insertCompletedOrder(context, payload) {
      payload.token = Cookies.get("BStoken");
      payload.userId = jwtDecode(Cookies.get("BStoken")).user_id;
      try {
        return await instance.post("/order/insertCompletedOrder", payload);
      } catch (error) {
        console.error(error);
      }
    },

    // post new deliver info
    // async postNewDeliverInfo({ commit }, payload) {
    //   try {
    //     commit("setRecieverInfo", payload);
    //     return await instance.post("/order/add/newdeliveryaddress", payload);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    // async fetchAddress({ commit }, payload) {
    //   console.log("fetchAddress", payload);
    //   try {
    //     const { data } = await instance.post("/order/getAddressByUserId", { userId: payload, token: Cookies.get("BStoken") });
    //     commit("setAddress", data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
};
