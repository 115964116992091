<template>
  <div class="flex-grow bg-white">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5">
        <div class="pb-2 px-2">
          <h3 class="font-bold text-lg text-gray-800">광고ㆍ제휴게시판</h3>
          <table class="w-full bg-gray-100 mt-4">
            <colgroup>
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr class="border-b">
                <th class="p-4 text-xs text-gray-400">제목</th>
                <td>
                  <div class="mr-10">
                    <input type="text" class="border p-[2px] w-full" />
                  </div>
                </td>
              </tr>

              <tr class="border-b">
                <th class="p-4 text-xs text-gray-400">작성자</th>
                <td>
                  <div class="mr-10">
                    <input type="text" class="border p-[2px] w-full" />
                  </div>
                </td>
              </tr>
              <tr class="border-b">
                <th class="p-4 text-xs text-gray-400">비밀번호</th>
                <td>
                  <div class="mr-10">
                    <input type="text" class="border p-[2px] w-full" />
                  </div>
                </td>
              </tr>
              <tr class="border-t">
                <th class="p-4 text-xs text-gray-400">첨부파일</th>
                <td>
                  <div class="grid grid-cols-2">
                    <div class="flex items-center gap-2">
                      <input type="text" class="border p-[2px]" />
                      <div class="text-xs p-[6px] bg-gray-300 text-gray-500 font-bold">찾아보기</div>
                      <div class="border border-gray-200 text-xs font-bold text-gray-500 p-[6px]">+ 추가</div>
                    </div>
                    <div></div>
                  </div>
                </td>
              </tr>

              <tr class="border-t">
                <th class="p-4 text-xs text-gray-400">본문</th>
                <td>
                  <div class="pb-4">
                    <div class="flex items-center gap-2 mt-3">
                      <input type="checkbox" />
                      <label for="checkbox" class="text-gray-500 text-xs">비밀글</label>
                    </div>
                    <div class="mt-4 mr-10">
                      <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" :style="{ width: '500px', height: '600px' }" />
                    </div>
                  </div>
                </td>
              </tr>

              <tr class="border-t">
                <th class="p-4 text-xs text-gray-400">자동등록방지</th>
                <td>
                  <div class="grid grid-cols-2">
                    <div class="flex items-center gap-6">
                      <div class="border h-24 w-44 bg-white relative">
                        <img src="../../assets//common/captcha-bg.png" alt="" class="h-24 w-44" />
                        <span class="text-gray-400 absolute top-8 left-10 tracking-widest italic font-thin text-[24px]">F567JK</span>
                      </div>
                      <div>
                        <p class="text-xs text-gray-400 mt-3">
                          보이는 순서대로 <br />
                          숫자 및 문자를 모두 입력해 주세요.
                        </p>
                        <input type="text" class="border mt-2" />
                        <div class="bg-gray-300 border text-xs text-center mt-2 mb-3 p-[2px] flex justify-center items-center gap-2">
                          <img src="../../assets//common/tryagain.svg" alt="" />
                          <span class="text-gray-500 font-bold">이미지 새로고침</span>
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="w-full bg-gray-100 pb-4">
            <div class="flex gap-2 items-center ml-8">
              <input type="checkbox" />
              <label for="accept-terms">
                <strong class="text-xs text-gray-500">비회원 글작성에 대한 개인정보 수집 및 이용동의</strong>
                <a href="/service/private" target="blank" class="text-[10px] text-gray-400 font-bold"> 전체보기 > </a>
              </label>
            </div>
            <div class="ml-8 bg-white border border-gray-300 mr-10">
              <p class="text-xs text-gray-500 p-3">
                - 수집항목: 이름, 전화번호, 이메일주소 <br />
                - 수집/이용목적: 게시글 접수 및 결과 회신 <br />
                - 이용기간: 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보전할
                필요가 있는 경우 일정기간 동안 개인정보를 보관할 수 있습니다.<br />
                그 밖의 사항은 (주) 000 개인정보처리방침을 준수합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue"; // Import the watch function
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import SimpleUPLOADAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
export default {
  name: "MyCKEditor",
  setup() {
    const editor = ClassicEditor;
    const editorData = ref("<p>Initial content</p>");

    // Watch for changes in editorData
    watch(editorData, (newValue) => {
      console.log(newValue); // Log the new content whenever it changes
    });

    const editorConfig = {
      // plugins: [SimpleUPLOADAdapter],
      fontFamily: {
        options: [
          "default",
          "Arial, Helvetica, sans-serif",
          "Courier New, Courier, monospace",
          "Georgia, serif",
          // ... other font families ...
        ],
      },

      fontSize: {
        options: [10, 12, "default", 16, 18, 20, 24, 32],
      },

      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
      },

      image: {
        toolbar: ["imageTextAlternative", "imageStyle:inline", "imageStyle:block", "imageStyle:side"],
        styles: [
          "full",
          "alignLeft",
          "alignRight",
          // ... other styles
        ],
        resizeUnit: "px",
      },

      heading: {
        options: [
          { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
          { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
          { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
          // ... other heading levels ...
        ],
      },

      blockToolbar: ["paragraph", "heading1", "heading2", "heading3", "|", "bulletedList", "numberedList", "|", "blockQuote", "imageUpload"],

      language: "en",
    };

    return {
      editor,
      editorData,
      editorConfig,
    };
  },
};
</script>

<!-- <style>
/* CKEditor CSS import */
@import "path-to-ckeditor-css/ckeditor.css";
</style> -->
