<template>
  <div class="bg-slate-800 notranslate">
    <div class="max-w-[1240px] md:h-[30rem] mx-auto">
      <!-- md:h-[28rem] -->
      <div class="border-b border-footer-text px-5">
        <ul class="md:flex grid items-center gap-4 pb-2 pt-4 text-gray-400 font-bold text-xs cursor-pointer">
          <a href="/service/company">
            <li>COMPANY</li>
          </a>
          <a href="/service/agreement">
            <li>CONTRACT</li>
          </a>
          <a href="/service/private">
            <li>PRIVACY</li>
          </a>
          <a href="/service/guide">
            <li>GUIDE</li>
          </a>

          <a href="/board">
            <li>ADVERTISEMENT/AFFILIATION</li>
          </a>

          <a href="/service">
            <li>CSCENTER</li>
          </a>
        </ul>
      </div>

      <div class="md:flex grid gap-4 text-white pt-10 px-5">
        <div class="flex-1 md:border-r border-slate-500">
          <h1 class="text-sm font-bold mb-3 text-zinc-100">CS CENTER</h1>
          <ul class="text-xs">
            <li class="pt-2">
              <h1 class="text-base font-bold text-gray-400">02-2269-3278</h1>
              <span class="text-sm text-gray-400">
                <br />★ UB +976-7786-0012 <br />
                MON_SUN AM 10:00 ~ PM 7:00 (GMT+9) <br />
                LUNCH. PM 12:00 ~PM 1:00 (GMT+9) <br />
                [HOLIDAY OFF]
              </span>
            </li>
          </ul>
        </div>

        <div class="flex-1 md:border-r border-slate-500">
          <h1 class="text-sm font-bold mb-3 text-zinc-100">ACCOUNT NUMBER</h1>
          <ul class="text-xs">
            <li class="pt-2">
              <span class="text-sm text-gray-400">우리은행</span>
              <span class="text-sm text-gray-400">1005701712024</span>
            </li>
            <li class="pt-2">
              <span class="text-sm text-gray-400">TDB</span>
              <span class="text-sm text-gray-400">499216218</span>
              <span class="text-sm text-gray-400">
                <br />
                예금주 : CHIMEDDORJ SUKHGEREL</span
              >
            </li>
          </ul>
        </div>

        <div class="flex-1 md:border-r border-slate-500">
          <h1 class="text-sm font-bold mb-3 text-zinc-100">FOLLOW US</h1>
          <ul class="text-xs">
            <li class="pt-2 flex items-center gap-2">
              <a href="https://www.facebook.com/mongoltownkorea" target="_black">
                <img src="../../assets/common/facebook.svg" alt="" />
              </a>
              <a href="/">
                <img src="../../assets//common/instagram.svg" alt="" />
              </a>
            </li>
          </ul>

          <h1 class="text-sm font-bold mt-8 mb-4 text-zinc-100">APPS</h1>
          <ul class="text-xs ml-1">
            <li class="pt-2 flex items-center gap-4">
              <a href="https://apps.apple.com/kr/app/mongol-town/id6464448073" target="_black">
                <img src="../../assets/common/appStore.svg" alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bowsys.Mongoltownapp&hl=en&gl=US" target="_black">
                <img src="../../assets/common/playstore.svg" alt="" />
              </a>
            </li>
          </ul>
        </div>

        <div class="flex flex-col">
          <div class="mb-3">
            <h1 class="text-sm mb-3 text-zinc-100">order tracking</h1>
            <ul class="text-xs">
              <li class="pt-2">
                <span class="text-sm text-gray-400">택배사 홈페이지에서 배송정보를 확인하실 수 있습니다.</span>
                <div class="flex justify-between mt-4">
                  <p class="text-base text-gray-400">CJ대한통운 1588-1255</p>
                  <a
                    href="https://www.doortodoor.co.kr/parcel/pa_004.jsp"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="text-sm border p-1 text-gray-400"
                    >배송위치 조회</a
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="border-t border-slate-500">
            <h1 class="text-sm mb-3 mt-5 text-zinc-100">delivery</h1>
            <ul class="text-xs">
              <li class="pt-2">
                <span class="text-sm text-gray-400"
                  >서울특별시 중구 을지로44길 12 (뉴금호타워) 302호<br />
                  공지사항 및 이용안내를 확인하신 뒤 교환/반품 접수해주세요.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="border-t border-footer-text mt-5 px-5 flex items-center gap-4">
        <div class="text-xs lg:my-2">
          <h5 class="text-sm font-bold text-gray-400 pb-2">울란바타르2</h5>
          <span class="text-sm text-gray-400 flex gap-3"
            ><span>대표 : CHIMEDDORJ SUKHGEREL 사업자등록번호 : 201-10-58521</span>
            <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2011058521&apv_perm_no=" target="_blank">
              <img
                src="https://cdn-pro-web-212-222.cdn-nhncommerce.com/sokcompany1_godomall_com/data/skin/front/jacelyn_v2/img/btn/btn_bizinfo.gif"
                alt=""
              /> </a
            ><br
          /></span>
          <span class="text-sm text-gray-400"
            >대표번호 : 02-2269-3278 팩스번호 : 02-2285-3278 메일 : <a href="/">cs@mongoltownkorea.com </a> 통신판매업신고번호 : 2020-서울중구-1783
            개인정보관리자 : CHIMEDDORJ SUKHGEREL</span
          >
          <span class="text-sm text-gray-400">서울특별시 중구 을지로44길 12 (뉴금호타워) 302호 </span>
        </div>
        <div>
          <!-- <img
            src="https://cdn-pro-web-212-222.cdn-nhncommerce.com/sokcompany1_godomall_com/data/skin/front/jacelyn_v2/img/banner/af83b53a2ff236b10b518f16898b76d4_68820.jpg"
            alt=""
          /> -->
        </div>
      </div>

      <div class="border-t border-footer-text lg:mt-5 px-5">
        <div class="text-xs text-center text-white">
          <p class="text-slate-500 lg:mt-3">
            COPYRIGHT ⓒ mgltown.com ALL RIGHT RESERVED.<a href="https://bowsys.mn/" target="_blank" class="text-slate-400 hover:text-orange-500">
              Developed By Bow LLC
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
