<template>
  <div class="max-w-[1240px] mx-auto min-h-screen">
    <div class="px-3">
      <p class="my-4">(нийт бүтээгдэхүүн {{ products.length }})</p>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pb-4" v-if="products.length > 0">
        <ProductCard v-for="product in products" :key="product.id" :product="product" />
      </div>
      <div v-else class="flex justify-center items-center h-[500px]">
        <p class="text-2xl text-gray-400">Бүтээгдэхүүн олдсонгүй</p>
      </div>
    </div>
    <back-to-top></back-to-top>
  </div>
</template>
<script>
import { ref, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { addToWishList } from "@/lib/common/helper";
import ProductCard from "@/components/product/ProductCard.vue";
import BackToTop from "@/components/ui/BackToTop.vue";

export default {
  name: "ViewAllPage",
  components: {
    ProductCard,
    BackToTop,
  },

  async setup() {
    const store = useStore();
    const route = useRoute();
    const products = ref([]);
    const isWishList = ref(false);

    onBeforeMount(async () => {
      await store.dispatch("products/getProductsBySearch", route.params.search);
      products.value = store.getters["products/getSearchProducts"];
    });

    watch(
      () => route.params.search,
      async (newVal) => {
        await store.dispatch("products/getProductsBySearch", newVal);
        products.value = store.getters["products/getSearchProducts"];
      }
    );

    const addToList = (product) => {
      isWishList.value = !isWishList.value;

      addToWishList(product);
    };

    return {
      isWishList,
      addToList,
      products,
    };
  },
};
</script>
