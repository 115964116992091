<template>
  <div class="max-w-[1240px] mx-auto min-h-screen relative">
    <div>
      <div class="md:grid md:grid-cols-11 gap-3 md:mt-3">
        <div class="bg-white md:col-span-3 md:px-5 md:py-3 hidden md:block">
          <div>
            <div class="font-medium py-3 uppercase">АНГИЛАЛ</div>
            <div v-for="category in categories" :key="category?.id">
              <div class="text-sm text-ring capitalize font-medium hover:underline mb-2 flex flex-row">
                <span class="mr-2 mt-1">
                  <img src="../assets/common/play-button-svgrepo-com.svg" alt="" />
                </span>
                <transition name="fade" mode="out-in">
                  <a :href="`/category/${category?.id}/sortby=views`">
                    <span>{{ category?.name }}</span>
                  </a>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="md:col-span-8">
          <div class="hidden md:block">
            <div class="bg-white px-3 py-1 mb-3 flex justify-between items-centers">
              <div class="mt-1">
                <div>
                  <select class="border rounded pt-1 pb-1 pr-8 pl-8" @change="handleSortChange">
                    <option value="views" :selected="route.params.sortby === 'views'">Эрэлттэй нь эхэндээ</option>
                    <option value="price-asc" :selected="route.params.sortby === 'price-asc'">Үнэ өсөхөөр эрэмблэх</option>
                    <option value="price-desc" :selected="route.params.sortby === 'price-desc'">Үнэ буурахаар эрэмблэх</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white px-5 md:py-3">
            <div class="mx-3 md:mx-0"></div>
            <p class="text-xs py-4 px-3 text-black">
              ( <span>нийт бүтээгдэхүүн: </span> <span class="font-bold">{{ categoryProducts?.products?.length }}</span
              >)
            </p>
            <transition name="fade" mode="out-in">
              <div class="grid grid-cols-2 gap-3 lg:grid-cols-4 md:grid-cols-3" v-if="categoryProducts?.products?.length > 0">
                <product-card v-for="product in categoryProducts.products" :key="product?.id" :product="product"></product-card>
              </div>
            </transition>

            <div class="flex justify-center" v-if="categoryProducts?.products?.length === 0">
              <div class="text-center">
                <!-- <img src="../assets/common/empty-box.svg" alt="" /> -->
                <p class="text-xl text-gray-500 my-3">Бүтээгдэхүүн олдсонгүй</p>
              </div>
            </div>
            <div class="pb-1" v-if="categoryProducts?.products?.length > 0">
              <PaginationVue @sent-pageNum="handleCompleted" :totalProductPages="categoryProducts.totalPages" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed bottom-0 h-12 w-full border-t-1 border-gray-300 bg-white flex items-center justify-around md:hidden">
      <div class="flex gap-2 justify-center cursor-pointer" @click="isCategory = !isCategory">
        <img class="w-6" src="../assets/common/category.png" alt="" />
        <span>Ангилал</span>
      </div>

      <div class="absolute left-[50%] h-12 w-[1px] bg-slate-300"></div>

      <div class="flex gap-2 justify-center cursor-pointer" @click="isSort = !isSort">
        <img class="w-8" src="../assets/common/sort.png" alt="" />
        <span>Шүүлт</span>
      </div>
    </div>

    <transition name="slide">
      <div v-if="isCategory" class="side-menu fixed inset-y-0 left-0 z-50 w-76 bg-white shadow-lg py-4 w-[300px]">
        <div class="flex justify-center items-center px-4 border-b-1 border-gray-300 pb-4">
          <p class="text-xl font-bold">Ангилал</p>
        </div>
        <div class="px-4">
          <div class="mt-3">
            <div v-for="category in categories" :key="category?.id">
              <div class="text-sm text-ring capitalize font-medium hover:underline mb-2 flex flex-row">
                <span class="mr-2 mt-1">
                  <img src="../assets/common/play-button-svgrepo-com.svg" alt="" />
                </span>
                <a :href="`/category/${category?.id}/sortby=views`">
                  <span>{{ category?.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div v-if="isSort" class="side-menu fixed inset-y-0 right-0 z-50 bg-white shadow-lg py-4 w-[300px]">
        <div class="flex justify-center items-center px-4 border-b-1 border-gray-300 pb-4">
          <p class="text-xl font-bold">Шүүлт</p>
        </div>
        <div class="py-4 flex justify-center">
          <select class="border border-black rounded py-2 pr-8 pl-8" @change="handleSortChange">
            <option value="views" :selected="route.params.sortby === 'views'">Эрэлттэй нь эхэндээ</option>
            <option value="price-asc" :selected="route.params.sortby === 'price-asc'">Үнэ өсөхөөр эрэмблэх</option>
            <option value="price-desc" :selected="route.params.sortby === 'price-desc'">Үнэ буурахаар эрэмблэх</option>
          </select>
        </div>
      </div>
    </transition>

    <div class="backdrop" v-if="isCategory || isSort" @click="handleBackDrop"></div>
    <back-to-top></back-to-top>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import BackToTop from "@/components/ui/BackToTop.vue";
import ProductCard from "@/components/product/ProductCard.vue";
import PaginationVue from "../components/pagination/Pagination.vue";

import Cookies from "js-cookie";

export default {
  components: {
    ProductCard,
    PaginationVue,
    BackToTop,
  },
  async setup() {
    const store = useStore();
    const route = useRoute();

    const isCategory = ref(false);
    const isSort = ref(false);

    const categories = ref([]);
    const categoryProducts = ref([]);
    const pageNum = ref(0);

    const fetchData = async () => {
      await store.dispatch("categories/fetchCategoryByParentId", route.params.id);
      await store.dispatch("products/fetchProductsByCategory", { category: route.params, pageNum: 1, token: Cookies.get("BStoken") });

      categories.value = store.getters["categories/getSubCategories"];
      categoryProducts.value = store.getters["products/getCategoryProducts"];
    };

    onBeforeMount(async () => {
      await fetchData();
    });

    const handleSortChange = async (e) => {
      window.location.href = `/category/${route.params.id}/sortby=${e.target.value}`;
    };

    watch(
      () => store.getters["products/getCategoryProducts"],
      (newVal) => {
        categoryProducts.value = newVal;
      }
    );

    const handleBackDrop = () => {
      isCategory.value = false;
      isSort.value = false;
    };

    const handleCompleted = async (data) => {
      pageNum.value = data;

      if (pageNum.value !== data)
        await store.dispatch("products/fetchProductsByCategory", { category: route.params, pageNum: data, token: Cookies.get("BStoken") });
    };

    return {
      route,
      isSort,
      isCategory,
      categories,
      categoryProducts,
      handleBackDrop,
      handleSortChange,
      handleCompleted,
    };
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
