<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { addToWishList } from "@/lib/common/helper";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default {
  name: "ProductCard",
  components: {},
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = useStore();
    const isWishList = ref(false);

    const addToList = async (product) => {
      const token = Cookies.get("BStoken");
      if (token) {
        try {
          isWishList.value = !isWishList.value;
          const res = await store.dispatch("products/addToWishList", { products: product, userId: jwt_decode(token).user_id });
          res.data > 0 ? store.commit("products/setWishListCount", res.data) : 0;
        } catch (e) {
          console.error(e);
          return;
        }
      } else {
        isWishList.value = !isWishList.value;
        addToWishList(product);
        store.commit("products/setWishListCount", JSON.parse(localStorage.getItem("wishList")).length);
      }
    };

    return {
      isWishList,
      addToList,
    };
  },
};
</script>
<template>
  <transition>
    <div class="bg-white rounded cursor-pointer" :class="{ 'animate-pulse': isLoading }">
      <a :href="`/product/pid=${product?.id}`">
        <div class="relative aspect-square md:p-2">
          <div class="w-full h-full" :class="{ 'image-wrapper': !product }">
            <img class="w-full h-full bg-white object-contain object-center transition duration-500 ease-in-out" :src="product?.image_url" alt="" />
          </div>
          <!-- <div
            class="absolute top-0 left-0 bg-red-500 text-white text-xs text-center font-medium px-1 mt-4 ml-4"
            v-if="product?.does_have_discount && product?.discount"
          >
            {{ product?.discount }}%
          </div> -->
        </div>
        <div class="px-5">
          <div class="mt-1">
            <div class="line-clamp-2 text-sm flex flex-row md:mt-0 mt-3">
              <p class="truncate capitalize">{{ product?.name }}</p>
            </div>
          </div>
          <div class="pt-2">
            <div class="flex justify-between items-center">
              <p class="font-extrabold text-secondary text-lg mr-2">
                <!-- {{ (product?.price - product?.price * (product?.discount / 100)).toLocaleString() }} вон -->
                {{ product?.price?.toLocaleString() }} вон
              </p>
              <!-- <p class="text-md text-gray-500 line-through" v-if="product?.does_have_discount && product?.discount">
                {{ product?.price.toLocaleString() }} вон
              </p> -->
            </div>
            <!-- <div class="flex justify-between items-center">
              <p class="text-xs">{{ product?.description.substring(0, 15) }}</p>
            </div> -->
          </div>
        </div>
      </a>
      <div class="flex justify-end items-center px-4 pb-2">
        <div>
          <span @click="addToList(product)">
            <img v-if="!isWishList" src="../../assets/common/heart.svg" alt="" />
            <img v-else src="../../assets/common/heart-filled.svg" alt="" />
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.animate-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.image-wrapper {
  background-color: #f0f0f0; /* Placeholder color */
  width: 300px;
  height: 200px;
}
</style>
