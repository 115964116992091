<template>
  <div>
    <!-- Modal -->
    <div v-if="isModalOpen" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-center justify-center min-h-screen px-4">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="closeModal"></div>
        <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:max-w-lg" role="document">
          <!-- Modal content -->
          <div class="px-4 py-6">
            <h2>
              <span class="flex justify-center items-center text-gray-900 relative">
                <span class="mr-2">
                  <img src="../../assets/common/checked.svg" alt="" />
                </span>
                <span class="text-sm font-bold">Сагсанд нэмэгдлээ!</span>
                <div @click="closeModal" class="absolute right-0 hover:cursor-pointer">
                  <img src="../../assets/common/cancel-svgrepo-com.svg" alt="" />
                </div>
              </span>
            </h2>

            <div class="mt-3">
              <div class="divide-y" v-if="product?.products">
                <div class="md:grid md:grid-cols-3 mx-5 gap-5 md:w-96 pb-3">
                  <div class="cols-span-1 w-full pb-full overflow-hidden relative bg-white rounded-xl">
                    <img :src="product?.products?.image_url" alt="" />
                  </div>
                  <div class="col-span-2">
                    <span class="text-sm">{{ product?.products?.name }} </span>
                    <div class="mt-2">
                      <div class="flex flex-col text-xs font-bold w-full">
                        <div class="flex flex-row justify-between">
                          <div class="flex flex-col">
                            <span v-if="product?.products.variants1">{{ product?.products.variants1 }}</span>
                            <span v-if="product?.products.variants2">{{ product?.products.variants2 }}</span>
                            <span v-if="product?.products.variants3">{{ product?.products.variants3 }}</span>
                          </div>
                          <div class="p-1 bg-gray-100 rounded-sm h-6">x{{ product?.products.quantity }}</div>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="font-bold text-base mt-2">{{ (product?.products.price * product?.products.quantity).toLocaleString() }} вон</div>
                  </div>
                </div>
                <div class="grid justify-items-center md:grid md:grid-cols-2 md:gap-5 md:flex md:items-center py-5">
                  <div class="text-sm text-center" v-if="showTotal">
                    <p>
                      <span class="font-medium">Сагсанд нийт </span>
                      <span class="font-bold">
                        {{ total.totalQuantity }}
                      </span>
                      <span class="font-medium"> бүтээгдэхүүн!</span>
                    </p>

                    <div class="mt-2">
                      <p class="font-medium">НИЙТ ҮНИЙН ДҮН</p>
                      <span class="font-bold text-2xl">
                        {{ total && total.totalAmount ? total.totalAmount.toLocaleString() + " вон" : null }}
                      </span>
                    </div>
                  </div>
                  <div class="mt-2 text-center">
                    <button
                      @click="backetcart"
                      class="w-48 border border-black text-black text-center text-xs font-bold uppercase rounded h-10 flex justify-center items-center hover:shadow-lg"
                    >
                      <span>Сагс харах</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const totalAmount = ref(0);
    const totalQuantity = ref(0);
    const showTotal = ref(false);
    const openModal = () => {
      emit("modal-updated", true);
    };
    const closeModal = () => {
      emit("modal-updated", false);
    };

    const isModalOpen = ref(false);

    watch(
      () => props.showModal,
      (newValue) => {
        isModalOpen.value = newValue;
      }
    );
    const backetcart = () => {
      router.push("/cart");
    };

    onMounted(() => {
      if (Cookies.get("BStoken")) {
        showTotal.value = true;
      }
    });

    return {
      isModalOpen,
      openModal,
      closeModal,
      backetcart,
      totalAmount,
      totalQuantity,
      showTotal,
    };
  },
};
</script>
