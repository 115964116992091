<template>
  <div class="flex justify-between items-center p-4 border-b-1 md:hidden sticky top-0 w-full z-10 bg-white">
    <div class="flex gap-8 items-center">
      <button @click="$emit('toggle-side-menu')">
        <img :src="require('../../assets/common/menu.svg')" alt="" />
      </button>
      <a href="/">
        <img class="h-8" :src="require('../../assets/common/logo.jpeg')" alt="" />
      </a>
    </div>

    <div class="flex gap-4 items-center">
      <button @click="toggleSearch">
        <img :src="require('../../assets/common/search.svg')" alt="" />
      </button>
      <a v-if="showUser" class="flex flex-col justify-center items-center" href="/user/profile">
        <img :src="require('../../assets/common/user.svg')" alt="" />
        <p v-if="UserName" class="text-xs capitalize">{{ UserName }}</p>
        <p v-else class="text-xs">Нэвтэрсэн</p>
      </a>

      <a v-if="!showUser" class="flex flex-col justify-center items-center" href="/login">
        <img :src="require('../../assets/common/user.svg')" alt="" />
        <p class="text-xs">Нэвтрэх</p>
      </a>

      <div class="relative">
        <a class="flex flex-col justify-center items-center" href="/cart">
          <img :src="require('../../assets/common/shopping-cart.svg')" alt="" />
          <p class="text-xs">Сагс</p>
        </a>
        <div
          v-if="backetlistcount > 0"
          class="absolute -top-1 -right-1 bg-red-500 rounded-full w-4 h-4 flex justify-center items-center text-white text-[12px]"
        >
          <span>{{ backetlistcount }}</span>
        </div>
      </div>
      <div class="relative">
        <a class="flex flex-col justify-center items-center" href="/wishlist">
          <img :src="require('../../assets/common/heart-black.svg')" alt="" />
          <p class="text-xs">Хүсэл</p>
        </a>
        <div
          v-if="wishlistcount > 0"
          class="absolute -top-1 -right-1 bg-red-500 rounded-full w-4 h-4 flex justify-center items-center text-white text-[12px]"
        >
          <span>{{ wishlistcount }}</span>
        </div>
      </div>

      <!--  -->
      <div @click="logOut" v-if="userToken">
        <a class="flex flex-col justify-center items-center">
          <img :src="require('../../assets/common/signoutSvg.svg')" alt="" class="mt-[4px]" />
          <p class="text-xs mt-[5px]">Log out</p>
        </a>
      </div>
    </div>

    <transition name="fade">
      <div class="absolute w-full top-16 left-0 bg-white p-2 border-t-1" v-if="isSearch">
        <div class="relative">
          <input
            type="text"
            placeholder="Бүтээгдэхүүн хайх..."
            class="border-1 rounded-r-full rounded-l-full border-gray-300 px-8 py-3 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 text-sm"
            v-model="search"
            @keyup.enter="handleSearch"
          />
          <button class="absolute right-4 top-2" @click="handleSearch">
            <img :src="require('../../assets/common/search.svg')" alt="" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default {
  name: "MobileHeader",
  setup() {
    const store = useStore();
    const router = useRouter();
    const wishlistcount = ref(0);
    const isSearch = ref(false);
    const backetlistcount = ref(0);
    const showUser = ref(false);
    const UserName = ref("");
    const search = ref("");
    const userToken = ref("");

    const toggleSearch = () => {
      isSearch.value = !isSearch.value;
    };

    const handleSearch = async () => {
      if (!search.value) return;
      router.push({
        name: "Search",
        params: {
          search: search.value,
        },
      });
      isSearch.value = false;
    };

    onMounted(async () => {
      try {
        const token = Cookies.get("BStoken");
        userToken.value = token;

        if (token) {
          showUser.value = true;
          const userId = jwt_decode(token).user_id;

          // Fetch wishlist data and user accounts
          await Promise.all([
            store.dispatch("products/getWishListData", userId),
            store.dispatch("products/getBacketData", userId),
            store.dispatch("login/fetchAccount", userId),
          ]);

          backetlistcount.value = store.getters["products/getBacketData"]?.length;
          wishlistcount.value = store.getters["products/getWishList"]?.length;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    //wishList
    wishlistcount.value = computed(() => {
      return store.getters["products/getWishListCount"];
    }).value;

    watch(
      () => store.getters["products/getWishListCount"],
      (newVal) => {
        wishlistcount.value = newVal;
      }
    );

    //backet
    backetlistcount.value = computed(() => {
      return store.getters["products/getBacketCount"];
    }).value;

    watch(
      () => store.getters["products/getBacketCount"],
      (newVal) => {
        backetlistcount.value = newVal;
      }
    );

    //account
    watch(
      () => store.getters["login/getAccounts"],
      (newVal) => {
        UserName.value = newVal?.data[0]?.first_name;
      }
    );

    // logout
    const logOut = () => {
      Cookies.remove("BStoken");
      window.location.href = "/";
    };

    return {
      isSearch,
      toggleSearch,
      wishlistcount,
      backetlistcount,
      showUser,
      UserName,
      search,
      handleSearch,
      logOut,
      userToken,
    };
  },
};
</script>
