<template>
  <admin-page>
    <h1 class="text-2xl font-semibold mb-4">Админ хэрэглэгчийн жагсаалт</h1>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div v-if="adminUser.length > 0">
        <hot-table :settings="hotSettings" :data="adminUser" ref="hotTable"></hot-table>
      </div>
    </div>
    <div v-if="unregisteredAdmin.length > 0">
      <h1 class="text-2xl font-semibold mb-4">Бүртгэлгүй админы жагсаалт</h1>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div v-if="unregisteredAdmin.length > 0">
          <hot-table :settings="UnregisteredSettings" :data="unregisteredAdmin"></hot-table>
        </div>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import { ref, onMounted } from "vue";
import instance from "@/lib/common/axios";

export default {
  name: "UserList",
  components: {
    HotTable,
  },
  setup() {
    registerAllModules();
    const adminUser = ref([]);
    const unregisteredAdmin = ref([]);
    onMounted(async () => {
      const { data } = await instance.get("/admin/getAdminUsers");
      adminUser.value = data;

      const response = await instance.get("/admin/getUnregisteredAdmin");
      unregisteredAdmin.value = response.data;
    });

    const hotSettings = {
      data: [],
      columnSorting: {
        initialConfig: {
          column: 2,
          sortOrder: "asc",
        },
      },
      width: "100%",
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      colWidths: [50, 200, 200, 200, 200, 200],
      columns: [
        {
          title: "",
          data: "id",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          title: "Овог",
          data: "last_name",
          type: "numeric",
          // readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          title: "Нэр",
          data: "first_name",
          type: "text",
          // readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = value;
          },
        },
        {
          title: "Имэйл",
          data: "email",
          type: "numeric",
          // readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = value;
          },
        },
        {
          title: "Бүртгэсэн огноо",
          data: "created_at",
          type: "date",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            if (value) {
              td.innerHTML = value.split("T")[0];
              td.classList.add("htCenter", "htMiddle");
            } else {
              td.innerHTML = "";
            }
          },
        },
        {
          title: " ",
          data: "",
          readOnly: true,
          renderer: function (inst, td, row) {
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center  gap-2">
                <button id="edit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Засах</button>
                <button id="delete" class="bg-red-500 hover:bg-red-600 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Устгах</button>
            </div>`;

            td.querySelector("#edit").addEventListener("click", function () {
              const data = {
                id: inst.getDataAtRow(row)[0],
                last_name: inst.getDataAtRow(row)[1],
                first_name: inst.getDataAtRow(row)[2],
                email: inst.getDataAtRow(row)[3],
              };
              insertUpdateDeleteAdmin(data);
            });

            td.querySelector("#delete").addEventListener("click", function () {
              insertUpdateDeleteAdmin({ id: inst.getDataAtRow(row)[0] });
              inst.alter("remove_row", row);
              inst.render();
              inst.validateCells();
            });
          },
        },
      ],
    };

    const UnregisteredSettings = {
      data: [],
      columnSorting: {
        initialConfig: {
          column: 2,
          sortOrder: "asc",
        },
      },
      width: "100%",
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      colWidths: [50, 200, 200, 200, 200, 200],
      columns: [
        {
          title: "",
          data: "id",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          title: "Имэйл",
          data: "email",
          type: "numeric",
          // readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = value;
          },
        },
        {
          title: "Бүртгэсэн огноо",
          data: "created_at",
          type: "date",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            if (value) {
              td.innerHTML = value.split("T")[0];
              td.classList.add("htCenter", "htMiddle");
            } else {
              td.innerHTML = "";
            }
          },
        },
        {
          title: "Админ болгож бүртгэх",
          data: "status",
          readOnly: true,
          renderer: function (inst, td, row) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = '<input type="checkbox" class="check-box"></input>';
            const checkBox = td.querySelector(".check-box");
            if (checkBox) {
              checkBox.addEventListener("change", async () => {
                const data = {
                  unregisteredId: inst.getDataAtRow(row)[0],
                  email: inst.getDataAtRow(row)[1],
                };
                // if (window.confirm(`Энэ хэрэглэгчийг админд бүртгэх үү? ${JSON.stringify(data.email)}`)) {
                insertUpdateDeleteAdmin(data);
                inst.alter("remove_row", row);
                inst.render();
                inst.validateCells();
                // }
              });
            }
          },
        },
      ],
    };

    const insertUpdateDeleteAdmin = async (data) => {
      await instance.post("/admin/insertUpdateDeleteAdmin", data).then((response) => {
        if (response.statusText === "OK") {
          alert("Амжилттай :)");
          location.reload();
        } else {
          console.error("Error :", response.statusText);
        }
      });
    };

    return {
      hotSettings,
      adminUser,
      insertUpdateDeleteAdmin,
      UnregisteredSettings,
      unregisteredAdmin,
    };
  },
};
</script>
