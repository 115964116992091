<template>
  <div class="bg-slate-50 flex-grow md:mt-0">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5">
        <div class="md:grid md:grid-cols-12 gap-5 pt-5 md:mt-5">
          <div class="col-span-8 mb-3">
            <div>
              <!-- uls songoh -->
              <div class="p-5 rounded-lg border border-slate-200 bg-white mb-5">
                <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">
                  Хүргүүлэх улсаа сонгоно уу / Choose delivery country
                </h3>
                <div class="grid md:grid-cols-2 gap-3 my-5">
                  <div
                    :class="
                      deliveryCountry == 'korea'
                        ? 'rounded-xl border-2 border-blue-400 p-5 cursor-pointer'
                        : 'rounded-xl border-2 border-slate-200 p-5 cursor-pointer'
                    "
                    @click="handleCountry('korea')"
                  >
                    <div class="flex justify-center items-center font-medium text-gray-600 border-slate-200">
                      <img class="mr-3" style="height: 36px" src="../../assets/common/korea-flag.png" alt="" />
                      Солонгос улс дотроо хүргүүлэх <br />
                      Delivery in Korea
                    </div>
                  </div>

                  <div
                    :class="
                      deliveryCountry == 'mongolia'
                        ? 'rounded-xl border-2 border-blue-400 p-5 cursor-pointer'
                        : 'rounded-xl border-2 border-slate-200 p-5 cursor-pointer'
                    "
                    @click="handleCountry('mongolia')"
                  >
                    <div class="flex justify-center items-center font-medium text-gray-600 border-slate-200">
                      <img class="mr-3" style="height: 30px" src="../../assets/common/mongolia-flag.png" alt="" />
                      Монгол рүү хүргүүлэх <br />
                      Delivery to Mongolia
                    </div>
                  </div>
                </div>
              </div>

              <!-- handling deilvyer method -->
              <div class="p-5 rounded-lg border border-slate-200 bg-white my-5" v-if="deliveryCountry == 'mongolia'">
                <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">Хүргэлтийн төрөл сонгоно уу.</h3>
                <div class="grid md:grid-cols-2 gap-3 my-5">
                  <div
                    :class="
                      deliveryMethod == 'land'
                        ? 'rounded-xl border-2 border-blue-400 p-5 cursor-pointer'
                        : 'rounded-xl border-2 border-slate-200 p-5 cursor-pointer'
                    "
                    @click="handleDeliveryMethod('land')"
                  >
                    <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">
                      <img class="mr-3" style="height: 30px" src="	https://24mall.mn/media/images/shipping/gazar.png" alt="" />
                      Газрын илгээмж
                    </div>
                    <p class="mt-5 text-sm text-center">1 кг - 3000 вон, 10-14 хоногт ирнэ</p>
                  </div>

                  <div
                    :class="
                      deliveryMethod == 'air'
                        ? 'rounded-xl border-2 border-blue-400 p-5 cursor-pointer'
                        : 'rounded-xl border-2 border-slate-200 p-5 cursor-pointer'
                    "
                    @click="handleDeliveryMethod('air')"
                  >
                    <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">
                      <img class="mr-3" style="height: 30px" src="https://24mall.mn/media/images/shipping/plane.png" alt="" />
                      Агаарын илгээмж
                    </div>
                    <p class="mt-5 text-sm text-center">1 кг - 6500 вон, 7-10 хоног</p>
                  </div>
                </div>
                <!-- <div class="mt-5 bg-slate-100 rounded-lg flex justify-start items-center">
                  <img class="ml-2" src="../../assets/common/exclamation-circle.svg" alt="" />
                  <p class="p-3 font-medium text-sm flex flex-col gap-2 md:w-11/12">
                    <span
                      >5кг-аас доош жинтэй эсвэл овор багатай ачааг Газрын каргогоор тээвэрлэдэггүй болохыг анхаарна уу. Жин хүрэхгүй түүвэр бараа
                      зөвхөн Агаарын каргогоор ирнэ.</span
                    >
                    <span>Ачаа ирэх хугацаа нь каргоны компаниас хамаарч өөрчлөгдөж болохыг анхаарна уу.</span>
                  </p>
                </div> -->
              </div>

              <div class="p-5 rounded-lg border border-slate-200 bg-white my-5" v-if="deliveryCountry === 'mongolia'">
                <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">Ачаа хүлээн авах</h3>
                <div class="grid md:grid-cols-2 gap-3 my-5">
                  <div
                    class="rounded-xl border-2 border-primary p-5"
                    @click="handleDeliveryWay('pickup')"
                    :class="{ 'border-blue-400': deliveryWay === 'pickup' }"
                  >
                    <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">
                      Өөрийн биеэр ирж авах
                    </div>
                    <p class="mt-5 text-sm text-center">
                      Танд ачаа ирсэнийг мэдэгдэсний дараа өөрийн биеээр ирж авна. Манай хаяг: Модны 2 World mongolia office 8 давхар 806 тоот.
                    </p>
                  </div>
                  <div
                    class="rounded-xl border-2 border-primary p-5"
                    @click="handleDeliveryWay('delivery')"
                    :class="{ 'border-blue-400': deliveryWay === 'delivery' }"
                  >
                    <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">Хүргэлтээр авах</div>
                    <p class="mt-5 text-sm text-center">
                      Танд ачаа ирсэнийг мэдэгдэсний дараа хүргэлтээр таны ачааг хүргэх бөгөөд хүргэлтийн төлбөр нэмэлтээр төлөгдөнө.
                    </p>
                  </div>
                </div>
                <div class="mt-5 bg-slate-100 rounded-lg flex justify-start items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 ml-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    ></path>
                  </svg>
                  <p class="p-3 font-medium text-sm">Таны захиалга ирсний дараа улс хоорондын Каргоны төлбөр нэмэлтээр тооцогдоно.</p>
                </div>
              </div>

              <!-- burtgeliig haruualah -->
              <div v-if="showAddressInfo" class="p-5 rounded-lg border border-slate-200 bg-white my-4">
                <div class="flex justify-between items-center border-b border-slate-200 pb-2 px-2">
                  <h3 class="font-medium text-lg text-gray-800">Захиалагчийн мэдээлэл / Your shipping information</h3>
                  <button @click="updateRegister" class="text-sm underline text-gray-600">Засах / Edit</button>
                </div>
                <div class="grid gap-3 py-5 px-2 text-sm">
                  <div class="flex justify-start capitalize">
                    <label class="pr-2 text-gray-600">Овог/LastName: </label>
                    <span>{{ orderDetailUserInfo.lastName }}</span>
                  </div>
                  <div class="flex justify-start capitalize">
                    <label class="pr-2 text-gray-600">Нэр/FirstName:</label>
                    <span>{{ orderDetailUserInfo.name }}</span>
                  </div>
                  <div class="flex justify-start">
                    <label class="pr-2 text-gray-600">Регистрийн дугаар/national ID:</label>
                    <span>{{ orderDetailUserInfo.registerNum }}</span>
                  </div>
                  <div class="flex justify-start">
                    <label class="pr-2 text-gray-600">Утасны дугаар/MobileNumber:</label>
                    <span>{{ orderDetailUserInfo.phoneNum }}</span>
                  </div>
                  <div class="flex justify-start md:col-span-2">
                    <label class="pr-2 text-gray-600">Хүргүүлэх хаяг/Delivery address:</label>
                    <span>{{ orderDetail?.shippingAddress }}</span>
                  </div>
                  <!-- <div v-if="!isCountry" class="text-red-500">Хаяг буруу байна!</div> -->
                </div>
              </div>

              <!-- burtgel hiih-->
              <div class="p-5 rounded-lg border border-slate-200 bg-white my-4" v-if="showUpdateAddress">
                <div class="flex justify-between items-center border-b border-slate-200 pb-2 px-2">
                  <h3 class="font-medium text-lg text-gray-800">Хүргэлтийн мэдээлэл/ delivery info</h3>
                  <button @click="cancelEdit" class="text-sm underline text-gray-600">Болих</button>
                </div>
                <!-- <div class="p-5 bg-slate-100 mt-5 text-sm rounded-lg">
                  <span class="font-medium">Санамж:</span>
                  Гаалийн бүрдүүлэлт хийхэд захиалагчийн овог, нэр болон регистрийн дугаар шаардлагатай тул та үнэн зөв бөглөнө үү.
                </div> -->
                <form class="py-5 px-2">
                  <div class="grid md:grid-cols-2 gap-5 mb-6">
                    <div>
                      <label for="lastname" class="block text-xs font-medium mb-1 ml-1">Овог</label>
                      <input
                        v-on:change="(event) => handleLastName(event)"
                        :value="orderDetailUserInfo.lastName"
                        name="lastname"
                        type="text"
                        class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                    <div>
                      <label for="name" class="block text-xs font-medium mb-1 ml-1">Нэр</label>
                      <input
                        v-on:change="(event) => handleName(event)"
                        :value="orderDetailUserInfo.name"
                        name="name"
                        type="text"
                        class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                    <div>
                      <label for="regNum" class="block text-xs font-medium mb-1 ml-1">Регистрийн дугаар</label>
                      <input
                        v-on:change="(event) => handleRegisterNum(event)"
                        :value="orderDetailUserInfo.registerNum"
                        name="regNum"
                        type="text"
                        class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                    <div>
                      <label for="telephone" class="block text-xs font-medium mb-1 ml-1">Утасны дугаар</label>
                      <input
                        v-on:change="(event) => handlePhoneNum(event)"
                        :value="orderDetailUserInfo.phoneNum"
                        name="telephone"
                        type="text"
                        class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                  </div>
                  <div class="mt-5 rounded-lg bg-white cursor-pointer">
                    <div>
                      <label for="regNum" class="block text-xs font-medium mb-1 mt-1">Хаяг</label>
                      <div>
                        <ul class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
                          <div
                            class="border p-4 cursor-pointer flex flex-col justify-between relative rounded"
                            :class="{ 'border-blue-500': isSelectAddress && selectedAddressIndex == 'main' }"
                            @click="selectShippingAddress('main')"
                          >
                            <div class="flex flex-col h-full">
                              <div class="md:flex mb-10">
                                <div class="flex-grow">
                                  <div class="flex-shrink">
                                    <input
                                      type="text"
                                      v-on:change="(event) => handleDetail(event)"
                                      :value="orderDetailUserInfo.detailAddress"
                                      name="address"
                                      class="appearance-none w-full border border-gray-200 rounded p-2 text-sm outline-primary"
                                      @click="resetSuccess"
                                    />
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="flex justify-end flex-grow gap-2">
                                <button type="button" class="h-10 text-black bg-slate-100 rounded hover:bg-slate-200 text-md p-2 w-20">Засах</button>
                              </div> -->
                            </div>
                          </div>
                          <div
                            v-for="(item, index) in shippingAddresses"
                            :key="index"
                            class="border p-4 cursor-pointer flex flex-col justify-between relative rounded"
                            :class="{ 'border-blue-500': isSelectAddress && index == selectedAddressIndex }"
                            @click="selectShippingAddress(index)"
                          >
                            <div class="flex flex-col h-full">
                              <div class="md:flex mb-10">
                                <div class="flex-grow">
                                  <div class="flex-shrink">
                                    <input
                                      type="text"
                                      v-on:change="(event) => handleAddressInput(event, item.id)"
                                      :value="item?.address"
                                      name="address"
                                      class="appearance-none w-full border border-gray-200 rounded p-2 text-sm outline-primary"
                                      @click="resetSuccess"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="flex justify-end flex-grow gap-2">
                                <!-- <button
                                  type="button"
                                  class="h-10 text-black bg-slate-100 rounded hover:bg-slate-200 text-md p-2 w-20"
                                  @click="updateSelectedAddress(index, item.id)"
                                >
                                  Засах
                                </button> -->
                                <button
                                  type="button"
                                  class="h-10 text-black bg-slate-100 rounded hover:bg-slate-200 text-md p-2 w-20"
                                  @click="deleteSelectedAddress(index, item.id)"
                                >
                                  Устгах
                                </button>
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <button @click="searchKoreanAddress" class="px-4 py-2 bg-slate-100 uppercase rounded hover:bg-slate-200 my-4">
                      Хаяг хайж оруулах
                    </button>
                  </div>
                  <div class="flex justify-center pt-5">
                    <button @click="saveUserOrderInfo" type="button" class="px-5 py-2 bg-black text-white uppercase rounded">Хадгалах</button>
                    <!-- <p v-if="successful" class="text-green-500 pl-4 text-center pt-3 text-sm">Хүргэлтийн хаяг амжилттай бүртгэгдлээ.</p> -->
                  </div>
                </form>
              </div>

              <div class="p-5 rounded-lg border border-slate-200 bg-white my-4">
                <div class="flex justify-between items-center border-b border-slate-200 pb-2 px-2">
                  <h3 class="font-medium text-lg text-gray-800">Хүлээн авагчийн мэдээлэл/Receiver information</h3>
                </div>
                <div>
                  <div class="grid gap-5 my-4">
                    <div>
                      <label for="name" class="block text-xs font-medium mb-1 ml-1"><span class="text-red-500 mr-1">*</span>Нэр / Name</label>
                      <input
                        @input="(e) => handleNewDetailInput(e, 'name')"
                        :value="orderDetail?.recieverName"
                        :disabled="willUseUserInformation"
                        name="name"
                        type="text"
                        class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                    <div>
                      <label for="telephone" class="block text-xs font-medium mb-1 ml-1"
                        ><span class="text-red-500 mr-1">*</span>Утасны дугаар / Phone number</label
                      >
                      <input
                        @input="(e) => handleNewDetailInput(e, 'phoneNum')"
                        :value="orderDetail?.recieverPhone"
                        :disabled="willUseUserInformation"
                        name="telephone"
                        type="text"
                        class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                  </div>
                  <div class="rounded-lg bg-white cursor-pointer mt-2">
                    <div>
                      <button
                        @click="searchKoreanAddress1"
                        class="px-4 py-2 bg-slate-100 text-[11px] font-semibold uppercase rounded hover:bg-slate-200 my-1"
                        :disabled="willUseUserInformation"
                        :class="willUseUserInformation ? 'cursor-not-allowed' : ''"
                      >
                        Хаяг хайж оруулах / Search address
                      </button>
                      <div
                        class="md:col-span-2 flex justify-between items-center border border-gray-200 rounded text-sm outline-primary"
                        v-if="newDeliveryInfo.address"
                      >
                        <input
                          type="text"
                          @input="(e) => handleNewDetailInput(e, 'address')"
                          :disabled="willUseUserInformation"
                          :value="newDeliveryInfo.address"
                          name="address"
                          class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                        />
                      </div>
                      <div
                        class="md:col-span-2 flex justify-between items-center border border-gray-200 rounded text-sm outline-primary"
                        v-if="!newDeliveryInfo.address && isNoAddressFound"
                      >
                        Хаяг олдсонгүй!
                      </div>
                    </div>
                    <div class="mt-3">
                      <label for="regNum" class="block text-xs font-medium mb-1 mt-1"
                        ><span class="text-red-500 mr-1">*</span>Дэлгэрэнгүй хаяг / Detailed address</label
                      >
                      <input
                        type="text"
                        @input="(e) => handleNewDetailInput(e, 'detailAddress')"
                        :disabled="willUseUserInformation"
                        :value="orderDetail?.recieverDetailedAddress"
                        name="address"
                        class="appearance-none w-full border border-gray-200 rounded p-2 text-sm outline-primary"
                        @click="resetSuccess"
                      />
                    </div>
                  </div>
                  <div class="flex items-center my-4 gap-2">
                    <input name="name" type="checkbox" :value="willUseUserInformation" @click="willUseUserInformation = !willUseUserInformation" />
                    <label for="name" class="block text-xs font-medium">
                      Захиалагийн хаяг дээр авах бол энд дарна уу. / Use my shipping address
                    </label>
                  </div>
                </div>
              </div>

              <div class="p-5 rounded-lg border border-slate-200 bg-white my-5">
                <div class="flex items-center">
                  <input type="checkbox" class="border border-gray-300 rounded" v-model="isConfirmed" />
                  <label class="font-medium text-sm text-gray-800 border-slate-200 px-2"
                    >Хүргэлтийн мэдээлэл баталгаажуулах / Confirm delivery information
                  </label>
                </div>
              </div>

              <!-- Payment -->
              <div class="p-5 rounded-lg border border-slate-200 bg-white my-4">
                <div class="flex items-center gap-2 text-sm">
                  <input type="radio" id="card" name="payment" value="card" @click="handlePaymentMethod('카드')" />
                  <label for="card"> Карт/Кредит картаар төлөх / Pay with card </label>
                </div>
                <div class="flex items-center gap-2 text-sm">
                  <input type="radio" id="cash" name="payment" value="cash" @click="handlePaymentMethod('가상계좌')" />
                  <label for="cash">Шилжүүлэг/Transfer</label>
                </div>
              </div>
              <!-- payment button -->
              <div class="flex justify-center">
                <button
                  :disabled="!isConfirmed"
                  @click="tossPay"
                  class="bg-black text-white text-center text-xs font-bold uppercase rounded h-10 px-6 hover:shadow-lg"
                >
                  Төлбөр төлөх
                </button>
              </div>

              <!-- <div class="flex justify-between my-5">
                <button
                  @click="backBacket"
                  class="text-secondary text-center text-xs font-bold uppercase rounded h-10 flex justify-start items-center"
                >
                  <img src="../../assets/common/arrow-left.svg" alt="" />
                  <span class="pl-2">Сагс руу буцах / Prev</span>
                </button>
                <button @click="nextPage" class="bg-black text-white text-center text-xs font-bold uppercase rounded h-10 px-6 hover:shadow-lg">
                  Үргэлжлүүлэх / Next
                </button>
              </div> -->
            </div>
          </div>
          <order-detail
            class="md:block col-span-4"
            :orderProducts="orderDetail?.products"
            :productsTotal="orderDetail?.productsTotal"
            :totalAmount="orderDetail?.total"
            :deliveryFee="orderDetail?.deliveryFee"
            :vat="orderDetail?.vat"
          ></order-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import instance from "@/lib/common/axios";
import OrderDetail from "./OrderDetail.vue";
// import UserAddressChange from "../../components/user/UserAddressChange.vue";
export default {
  components: {
    OrderDetail,
    // UserAddressChange,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    // Reference Initialization
    const orderDetail = ref(store.getters["order/getOrderDetail"]);
    console.log(orderDetail.value);

    const shippingAddresses = ref(orderDetail.value?.shippingAddresses) || [];
    const deliveryMethod = ref(orderDetail.value?.deliveryMethod || "");
    const deliveryCountry = ref(orderDetail.value?.deliveryCountry || "");
    const deliveryWay = ref(orderDetail.value?.deliveryWay || "");

    // Modal & UI States
    const showUpdateAddress = ref(false);
    const showAddressInfo = ref(true);
    const successful = ref(false);
    const isLoading = ref(false);
    const isNoAddressFound = ref(false);
    const isCountry = ref(false);
    const isConfirmed = ref(false);
    const isSelectAddress = ref(false);
    const selectedAddressIndex = ref("main");

    // Data for form handling
    const searchAddressValue = ref("");
    const searchAddressResult = ref([]);
    const willUseUserInformation = ref(orderDetail.value?.isNotReciever);

    onMounted(async () => {
      if (!Cookies.get("BStoken")) {
        router.push("/login");
      }
      if (Cookies.get("BStoken")) {
        const decodedToken = jwt_decode(Cookies.get("BStoken"));
        orderDetailUserInfo.userId = decodedToken.user_id;
        // await store.dispatch("order/fetchAddress", decodedToken.user_id);
        // await store.dispatch("products/getBacketData");
      }
    });

    const setupNewDeliveryInfo = (orderDetail) => {
      return reactive({
        address: orderDetail.value?.recieverAddress,
        detailAddress: "",
        name: orderDetail.value?.recieverName,
        registerNum: orderDetail.value?.recieverRegisterNum,
        phoneNum: orderDetail.value?.recieverPhone,
        secondPhoneNum: orderDetail.value?.recieverSecondPhone,
        email: orderDetail.value?.recieverEmail,
      });
    };

    const setupOrderDetailUserInfo = (orderDetail) => {
      return reactive({
        lastName: orderDetail.value?.customer?.lastName,
        name: orderDetail.value?.customer?.firstName,
        registerNum: orderDetail.value?.customer?.registerNum,
        phoneNum: orderDetail.value?.customer?.phone,
        detailAddress: orderDetail.value?.customer?.address,
      });
    };

    const newDeliveryInfo = setupNewDeliveryInfo(orderDetail);
    const orderDetailUserInfo = setupOrderDetailUserInfo(orderDetail);
    // new delivery info values

    const handleNewDetailInput = (event, field) => {
      newDeliveryInfo[field] = event.target.value;
      store.commit(`order/setReciever${field}`, event.target.value);
    };

    // const isValidInput = () => {
    //   const requiredFields = ["lastName", "firstName", "registerNum", "email", "phoneNum", "secondPhoneNum", "address"];
    //   const emailRegex = /\S+@\S+\.\S+/;
    //   for (const field of requiredFields) {
    //     if (!newDeliveryInfo[field]) {
    //       alert("Талбарыг бөглөнө үү!");
    //       return false;
    //     }
    //   }
    //   if (!emailRegex.test(newDeliveryInfo.email)) {
    //     alert("И-мэйл хаяг буруу байна!");
    //     return false;
    //   }
    //   return true;
    // };

    // const saveNewDeliveryInfo = async () => {
    //   if (!isValidInput()) return;
    //   const data = {
    //     userId: orderDetailUserInfo.userId,
    //     ...newDeliveryInfo,
    //     detailAddress: newDeliveryInfo.address + " " + newDeliveryInfo.detailAddress,
    //   };
    //   await store.dispatch("order/postNewDeliverInfo", data);
    //   store.commit("order/setIsNotReciever", true);
    //   // isAddedNewDeliveryInfo.value = true;
    // };

    // country and method
    const handleCountry = (country) => {
      deliveryCountry.value = country;
      store.commit("order/setDeliveryCountry", country); // Update the store when country changes
    };

    const handleDeliveryMethod = (method) => {
      if (deliveryCountry.value === "mongolia") {
        if (method === "land") {
          store.commit("order/setDeliveryFee", { fee: 3000, method, isMongolia: true });
        } else if (method === "air") {
          store.commit("order/setDeliveryFee", { fee: 6500, method, isMongolia: true });
        }
      }

      deliveryMethod.value = method; // Always update the local delivery method value
      store.commit("order/setDeliveryMethod", method); // Commit the method to the store
    };

    const handleDeliveryWay = (way) => {
      deliveryWay.value = way;
      store.commit("order/setDeliveryWay", way);
    };

    const handlePaymentMethod = (method) => {
      paymentMethod.value = method;
      store.commit("order/setPaymentMethod", method);
    };

    const resetSuccess = () => {
      successful.value = false;
    };

    const handleLastName = (event) => {
      orderDetailUserInfo.lastName = event.target.value;
    };
    const handleName = (event) => {
      orderDetailUserInfo.name = event.target.value;
    };
    const handleRegisterNum = (event) => {
      orderDetailUserInfo.registerNum = event.target.value;
    };
    const handlePhoneNum = (event) => {
      orderDetailUserInfo.phoneNum = event.target.value;
    };
    const handleDetail = (event) => {
      orderDetailUserInfo.detailAddress = event.target.value;
    };

    // reset address if user go back to cart
    router.beforeEach((to, from, next) => {
      if (to.path === "/cart") {
        store.commit("order/resetBackToOrginalDetail");
      }
      next();
    });

    //=========================== WATCHERS====================>
    // watch(
    //   () => deliveryCountry.value,
    //   (newVal) => {
    //     store.commit("order/setDeliveryCountry", newVal);
    // store.commit("order/updateVat", newVal);
    //     if (newVal === "korea") {
    //       store.commit("order/setDeliveryFee", { fee: 3000, method: "korea", isMongolia: false });
    //     } else {
    //       store.commit("order/setDeliveryFee", { fee: 0, method: "s", isMongolia: false });
    //     }
    //   }
    // );

    watch(deliveryCountry, (newCountry) => {
      if (newCountry === "korea") {
        store.commit("order/updateVat", newCountry);
        store.commit("order/setDeliveryFee", { fee: 3000, method: "default", isMongolia: false });
      } else if (newCountry === "mongolia") {
        handleDeliveryMethod(deliveryMethod.value);
        store.commit("order/updateVat", newCountry);
      }
      deliveryMethod.value = "";
      deliveryWay.value = "";
    });

    watch(
      () => willUseUserInformation.value,
      (value) => {
        if (value) {
          console.log("orderDetailUserInfo", orderDetail?.value.shippingAddress);
          store.commit("order/setRecieverInfo", {
            firstName: orderDetailUserInfo.name,
            phoneNum: orderDetailUserInfo.phoneNum,
            detailAddress: orderDetail?.value.shippingAddress,
          });
          store.commit("order/setIsNotReciever", true);
        } else {
          newDeliveryInfo.address = "";
          store.commit("order/resetRecieverInfo");
          store.commit("order/setIsNotReciever", false);
        }
      }
    );
    //=========================== WATCHERS====================>

    // go back to cart
    const backBacket = () => {
      router.push("/cart");
    };

    // go to select payment page
    const nextPage = () => {
      if (!deliveryCountry.value) return alert("Улсаа сонгоно уу.");
      if (deliveryCountry.value === "mongolia" && !deliveryMethod.value) return alert("Хүргэлтийн төрөлээ сонгоно уу.");
      if (!isConfirmed.value) return alert("Хүргэлтийн мэдээлэл баталгаажуулахыг зөвшөөрнө үү.");

      store.commit("order/setDeliveryMethod", deliveryMethod.value);
      store.commit("order/setDeliveryCountry", deliveryCountry.value);
      router.push("/order/payment");
      window.scrollTo(0, 0);
    };

    const updateRegister = () => {
      showUpdateAddress.value = true;
      showAddressInfo.value = false;
    };

    const cancelEdit = () => {
      showAddressInfo.value = true;
      showUpdateAddress.value = false;
      orderDetailUserInfo.lastName = orderDetail.value?.customer?.lastName;
      orderDetailUserInfo.name = orderDetail.value?.customer?.firstName;
      orderDetailUserInfo.registerNum = orderDetail.value?.customer?.registerNum;
      orderDetailUserInfo.phoneNum = orderDetail.value?.customer?.phone;
      orderDetailUserInfo.detailAddress = orderDetail.value?.customer?.address;
      store.commit("order/setShippingAddress", orderDetail.value?.customer?.address);
      shippingAddresses.value = store.getters["order/getOrderDetail"].shippingAddresses;

      selectedAddressIndex.value = null;
      isSelectAddress.value = false;
    };

    //============================== newly added to add new address and search address
    const handleSearchAddress = (event) => {
      searchAddressValue.value = event.target.value;
    };

    // handle selected search from list
    const addNewAddress = async (address) => {
      try {
        const res = await instance.post("/order/addaddress", {
          address: address,
          userId: orderDetail.value.userId,
          token: Cookies.get("BStoken"),
        });

        if (res.data.message === "limit") {
          alert("хаягаа");
          return;
        }
        shippingAddresses.value.push({
          address: address,
          id: res.data.id,
        });
        selectedAddressIndex.value = shippingAddresses.value.length - 1;
        isSelectAddress.value = true;
        searchAddressValue.value = "";
        searchAddressResult.value = [];
      } catch (err) {
        console.log(err);
      }
    };

    const handleAddressInput = (e) => {
      shippingAddresses.value[selectedAddressIndex.value].address = e.target.value;
    };

    // Ashiglaj magadgui ********************************
    // const updateSelectedAddress = async (index, id) => {
    //   try {
    //     await instance.post("/order/updateaddress", {
    //       address: shippingAddresses.value[index].address,
    //       id,
    //       userId: orderDetail.value.userId,
    //       token: Cookies.get("BStoken"),
    //     });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    const deleteSelectedAddress = async (index, id) => {
      try {
        const res = await instance.post("/order/deleteaddress", { id, userId: orderDetail.value.userId, token: Cookies.get("BStoken") });
        if (res.status === 200) {
          shippingAddresses.value.splice(index, 1);
        }
      } catch (err) {
        console.log(err);
      }
    };

    // handle selected address from list and main address
    const selectShippingAddress = (e) => {
      if (e === "main") {
        isSelectAddress.value = true;
        selectedAddressIndex.value = "main";
      } else {
        isSelectAddress.value = true;
        selectedAddressIndex.value = e;
      }
    };

    const setShippingInfo = () => {
      store.commit("order/setCustomer", {
        firstName: orderDetailUserInfo.name,
        lastName: orderDetailUserInfo.lastName,
        phone: orderDetailUserInfo.phoneNum,
        registerNum: orderDetailUserInfo.registerNum,
        address: orderDetailUserInfo.detailAddress,
      });
      store.commit("order/setShippingAddresses", shippingAddresses.value);
      if (selectedAddressIndex.value === "main") {
        store.commit("order/setShippingAddress", orderDetailUserInfo.detailAddress);
      } else {
        shippingAddresses.value.length > 0 && store.commit("order/setShippingAddress", shippingAddresses.value[selectedAddressIndex.value].address);
      }
    };

    // search address
    const searchAddress = async (e) => {
      e.preventDefault();
      try {
        isLoading.value = true;
        const res = await store.dispatch("user/fetchAddress", searchAddressValue.value);
        // && res.data.documents.length > 0
        if (res.status === 200 && res.data.documents.length > 0) {
          searchAddressResult.value = res.data.documents;
          isNoAddressFound.value = false;
        } else {
          isNoAddressFound.value = true;
        }
        isLoading.value = false;
      } catch (err) {
        console.log(err);
      }
    };

    //============================== newly added to add new address and search address

    const saveUserOrderInfo = async () => {
      if (orderDetail.value.userId && Cookies.get("BStoken")) {
        setShippingInfo();
        orderDetail.value = store.getters["order/getOrderDetail"];
        const res = await store.dispatch("order/postAddress", orderDetailUserInfo);
        if (res.status === 200) {
          successful.value = true;
          showAddressInfo.value = true;
          showUpdateAddress.value = false;
        }
      } else {
        console.log("save in localStorage");
      }
    };

    const searchKoreanAddress = (e) => {
      e.preventDefault();
      window.daum.postcode.load(function () {
        new window.daum.Postcode({
          oncomplete: function (data) {
            if (data) {
              addNewAddress(data.address);
            } else {
              isNoAddressFound.value = true;
              alert("Хаяг олдсонгүй!");
            }
          },
        }).open();
      });
    };

    const searchKoreanAddress1 = (e) => {
      e.preventDefault();
      window.daum.postcode.load(function () {
        new window.daum.Postcode({
          oncomplete: function (data) {
            if (data) {
              store.commit("order/setRecieverDeliveryAddress", data.address);
              newDeliveryInfo.address = store.getters["order/getOrderDetail"].recieverAddress;
            } else {
              isNoAddressFound.value = true;
              alert("Хаяг олдсонгүй!");
            }
          },
        }).open();
      });
    };

    // ================================ add new delivery info

    // set shipping address 2023-08-21
    const setShippingAddress = () => {
      if (willUseUserInformation.value) {
        store.commit("order/setShippingAddress", orderDetailUserInfo.detailAddress);
      } else {
        store.commit("order/setShippingAddress", newDeliveryInfo.address + " " + newDeliveryInfo.detailAddress);
      }
    };

    // payment part
    const paymentMethod = ref("");
    // const tossPayments = window.TossPayments(process.env.VUE_APP_TOSS_CLIENT_KEY);

    const tossPay = async () => {
      if (!deliveryCountry.value) return alert("Улсаа сонгоно уу.");
      if (deliveryCountry.value === "mongolia" && !deliveryMethod.value)
        return alert("Хүргэлтийн төрөлөө сонгоно уу. / Please select delivery method.");
      if (deliveryCountry.value === "mongolia" && !deliveryWay.value)
        return alert("Хүргэлтийн аргаа сонгоно уу. / Please select how you will recive delivery.");
      if (!isConfirmed.value) return alert("Хүргэлтийн мэдээлэл баталгаажуулахыг зөвшөөрнө үү. / Please confirm shipping address.");
      if (!paymentMethod.value) return alert("Төлбөрийн аргаа сонгоно уу. / Please select payment method.");
      setShippingAddress();
      localStorage.setItem("orderDetail", JSON.stringify(orderDetail.value));
      router.push({
        path: "/payment/success",
        query: { paymentKey: "testkey", orderId: orderDetail.value?.orderId, amount: orderDetail.value?.total },
      });

      // let orderName = "";
      // if (orderDetail.value?.products.length === 1) {
      //   orderName = orderDetail.value?.products[0].products.name;
      // } else {
      //   orderDetail.value?.products.map((item) => {
      //     orderName += item.products.name + ", ";
      //   });
      // }

      // try {
      //   localStorage.setItem("orderDetail", JSON.stringify(orderDetail.value));
      //   tossPayments
      //     .requestPayment(paymentMethod.value, {
      //       amount: orderDetail.value?.total,
      //       orderId: orderDetail.value?.orderId,
      //       orderName: orderName.substring(0, 99),
      //       successUrl: window.location.origin + "/payment/success",
      //       failUrl: window.location.origin + "/payment/fail",
      //       customerName: orderDetail.value?.customer?.firstName,
      //       customerEmail: orderDetail.value?.customer?.email,
      //       currency: "KRW", // 통화 (선택)
      //     })
      //     .catch((e) => {
      //       if (e.code === "결제가 취소되었습니다") {
      //         return;
      //       }
      //     });
      // } catch (e) {
      //   console.log(e);
      // }
    };

    return {
      searchKoreanAddress,
      searchKoreanAddress1,
      willUseUserInformation,
      isCountry,
      isConfirmed,
      isSelectAddress,
      isLoading,
      isNoAddressFound,
      // isDeliveryModal,
      deliveryCountry,
      // vatTotal,
      deliveryMethod,
      orderDetailUserInfo,
      successful,
      orderDetail,
      showUpdateAddress,
      showAddressInfo,
      searchAddressValue,
      searchAddressResult,
      selectedAddressIndex,
      deliveryWay,
      shippingAddresses,
      searchAddress,
      resetSuccess,
      handleCountry,
      handleDeliveryWay,
      handleDeliveryMethod,
      handleAddressInput,
      addNewAddress,
      selectShippingAddress,
      // updateSelectedAddress,
      deleteSelectedAddress,
      handleName,
      handleLastName,
      handlePhoneNum,
      handleRegisterNum,
      handleDetail,
      handleSearchAddress,
      updateRegister,
      cancelEdit,
      backBacket,
      nextPage,
      saveUserOrderInfo,
      newDeliveryInfo,
      handleNewDetailInput,
      // saveNewDeliveryInfo,
      // payment
      paymentMethod,
      handlePaymentMethod,
      tossPay,
    };
  },
};
</script>
