export const categories = {
  townshop: {
    subcategories: [
      {
        id: 0,
        name: "Бор Гурил | Будаа КАША 잡곡/견과 ",
        url: "/category/townshop/rice",
      },
      {
        id: 1,
        name: "음료/생수 Ус | ундаа 음료/생수 ",
        url: "/category/townshop/beverage",
      },
      {
        id: 2,
        name: "Цөцгий масло 유제품 ",
        url: "/category/townshop/oil",
      },
      {
        id: 3,
        name: "Талх | Нарийн боов 과자/식빵/면류 ",
        url: "/category/townshop/bakery",
      },
      {
        id: 4,
        name: "Амтлагч | Цуу 양념/소스/드레싱 ",
        url: "/category/townshop/seasoning",
      },
      {
        id: 5,
        name: "Бусад 기타",
        url: "/category/townshop/other",
      },
      // {
      //   id: 6,
      //   name: "Амтлач",
      //   url: "/category/townshop/seasoning",
      // },
      // {
      //   id: 7,
      //   name: "Амттан",
      //   url: "/category/townshop/snack",
      // },
      // {
      //   id: 8,
      //   name: "Моносын бүтээгдэхүүн",
      //   url: "/category/townshop/personal",
      // },
      // {
      //   id: 9,
      //   name: "Ахуйн бараа",
      //   url: "/category/townshop/household",
      // },
      // {
      //   id: 10,
      //   name: "Цахилгаан бараа",
      //   url: "/category/townshop/electronics",
      // },
    ],
  },
  // travel: {
  //   subcategories: [
  //     {
  //       id: 0,
  //       name: "Аялал жуулчлал",
  //       url: "/category/travel/tourism",
  //     },
  //     {
  //       id: 1,
  //       name: "Чежү аялал",
  //       url: "/category/travel/cheju",
  //     },
  //   ],
  // },
  // provider: {
  //   subcategories: [
  //     {
  //       id: 0,
  //       name: "SIM",
  //       url: "/category/provider/sim",
  //     },
  //     {
  //       id: 1,
  //       name: "312 Картны утас цэнэглэх",
  //       url: "/category/provider/312",
  //     },
  //     {
  //       id: 2,
  //       name: "Гар утас",
  //       url: "/category/provider/mobile",
  //     },
  //     {
  //       id: 3,
  //       name: "ЖиМобайл IP78",
  //       url: "/category/provider/jimobile",
  //     },
  //   ],
  // },
  // health: {
  //   subcategories: [
  //     {
  //       id: 0,
  //       name: "Жемма нийт бүтээгдэхүүн",
  //       url: "/category/health/health",
  //     },
  //     {
  //       id: 1,
  //       name: "Эко бүтээгдэхүүн",
  //       url: "/category/health/eco",
  //     },
  //     {
  //       id: 2,
  //       name: "Хувцас",
  //       url: "/category/health/clothes",
  //     },
  //     {
  //       id: 3,
  //       name: "Эрүүл мэнд нэмэлт",
  //       url: "/category/health/healthadd",
  //     },
  //     {
  //       id: 4,
  //       name: "Гоо сайхны бүтээглэхүүн",
  //       url: "/category/health/healthcare",
  //     },
  //     {
  //       id: 5,
  //       name: "Эрүүлжүүлэх багц",
  //       url: "/category/health/healthpack",
  //     },
  //     {
  //       id: 6,
  //       name: "Төхөөрөмжүүд",
  //       url: "/category/health/device",
  //     },
  //     {
  //       id: 7,
  //       name: "Гал тогооны бараа",
  //       url: "/category/health/kitchen",
  //     },
  //     {
  //       id: 8,
  //       name: "Жемма урамшууллын багц",
  //       url: "/category/health/jemmapack",
  //     },
  //     {
  //       id: 9,
  //       name: "Аmway ба бусад",
  //       url: "/category/health/amway",
  //     },
  //   ],
  // },
};

export const navCategories = [
  {
    id: 0,
    url: "/category/townshop",
    name: "Монгол таун дэлгүүр | 몽골리안 타운 샵",
  },
  // {
  //   id: 1,
  //   url: "/category/travel",
  //   name: "Тийз аялал",
  // },
  // {
  //   id: 2,
  //   url: "/category/provider",
  //   name: "Дугаар сим, гар утас",
  // },
  // {
  //   id: 3,
  //   url: "/category/health",
  //   name: "Эрүүл мэнд",
  // },
];
