<template>
  <div class="bg-white">
    <div class="flex justify-center items-center max-w-[650px] mx-auto px-5">
      <div class="flex flex-col justify-between w-full md:w-4/6 py-10 md:py-5">
        <div class="my-10">
          <div id="logo" class="flex justify-center mx-auto mb-4">
            <img :src="require('../../assets/common/logo.jpeg')" class="w-32" href="/" />
          </div>
          <div>
            <!-- <div class="py-5 px-8 md:px-10 md:py-8 flex justify-center items-center">
              <div class="grid grid-cols-1 gap-4 content-center text-center w-full">
                <p v-if="showSuccessMsg" class="text-green-500 text-base text-center">Таны нууц үг амжилттай солигдлоо</p>

                <p v-if="showRegisterInp" class="text-red-600 text-xs">Та бүртгэлгүй байна бүртгүүлж нэвтэрнэ үү! / please sign In</p>
                <p v-if="showInserPass" class="text-xs text-blue-600">Та шинээр нууц үг хийж нэвтэрнэ үү / create new password</p>

                <input
                  class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                  :class="{ 'border-red-500': emailValid }"
                  type="email"
                  placeholder="Имэйл хаяг / Email"
                  v-model="userEmail"
                  :disabled="lockEmail"
                />

                <input
                  class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                  :class="{ 'border-red-500': emailValid }"
                  type="email"
                  placeholder="Имэйл хаяг / Email"
                  v-model="userEmail"
                  :disabled="lockEmail"
                />
                <p v-if="emailValid" class="text-xs text-red-500">
                  Таны и-мэйл буруу байна засаж бичнэ үү! / incorrect email! please check it and try again
                </p>

                <a class="text-xs text-blue-500 text-right cursor-pointer" v-if="showReturnBtn" @click="backToLogin">Буцах / Prev</a>

                <div class="flex relative" v-if="showRegisterInp || showPassInp || showInserPass">
                  <input
                    class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                    placeholder="Нууц үг / Password"
                    :type="passwordVisible ? 'text' : 'password'"
                    autocomplete="current-password"
                    v-model="userPassword"
                    name="password"
                  />

                  <button @click="togglePasswordVisibility" class="absolute top-3 right-3 cursor-pointer">
                    <img src="../assets/common/eye-off.svg" alt="" v-if="!passwordVisible" />
                    <img src="../assets/common/eye-on.svg" alt="" v-else />
                  </button>
                </div>

                <div class="flex relative" v-if="showRegisterInp || showInserPass">
                  <input
                    class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                    :type="confirmPassVisible ? 'text' : 'password'"
                    placeholder="confirm your password / Нууц үгээ давтан оруулна уу"
                    @input="handleUserConfirmPassword"
                    v-model="userConfirmPassword"
                    autocomplete="new3-password"
                  />
                  <button @click="toggleConfirmPassVisiblity" class="absolute top-3 right-3 cursor-pointer">
                    <img src="../assets/common/eye-off.svg" alt="" v-if="!confirmPassVisible" />
                    <img src="../assets/common/eye-on.svg" alt="" v-else />
                  </button>
                </div>

                <p v-if="passValid" class="text-red-500 text-xs">
                  <span>
                    Таны нууц үгэнд нэг том үсэг, нэг жижиг үсэг,нэг тоо, нэг тэмдэгт орсон байх ёстой ба хамгийн багадаа 8 оронтой байх ёстой.
                  </span>
                </p>

                <p v-if="incorrectPass" class="text-xs text-red-500">Нууц үг буруу байна! / incorrect password</p>

                <a v-if="incorrectPass" @click="forgotPass" class="cursor-pointer">
                  <span class="text-sm font-medium text-gray-500">Нууц үгээ мартсан уу? / forgot password</span>
                </a>

                <input
                  v-if="showVerificationCode"
                  class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                  type="text"
                  placeholder="confirm verification code / баталгаажуулах кодоо оруулна уу"
                  @input="handleVerificationCode"
                  v-model="verificationCode"
                />

                <button
                  v-if="showVerificationCode"
                  class="bg-sky-600 hover:bg-sky-700 text-white font-medium py-2 px-4 rounded-sm"
                  type="button"
                  @click="verifyCode"
                >
                  баталгаажуулах
                </button>

                <button
                  v-if="!showRegisterInp && !showPassInp && !showInserPass && !showVerificationCode"
                  class="bg-sky-600 hover:bg-sky-700 text-white font-medium py-2 px-4 rounded-sm"
                  type="button"
                  @click="checkByEmail"
                >
                  Үргэлжлүүлэх / Next
                </button>

                <button
                  v-if="showPassInp"
                  class="bg-sky-600 hover:bg-sky-700 text-white font-medium py-2 px-4 rounded-sm flex gap-2 justify-center items-center"
                  type="button"
                  @click="logIn"
                >
                  <loading-spinner v-if="loading" />
                  <span>Нэвтрэх / Login</span>
                </button>

                <button
                  v-if="showRegisterInp || showInserPass"
                  @click="registerAccount"
                  class="bg-slate-500 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-sm"
                  type="button"
                >
                  Бүртгүүлэх / Sign In
                </button>

                <h2 class="w-full text-center border-b" style="margin: 10px 0px 20px; line-height: 0.1em">
                  <span class="bg-white text-slate-500" style="padding: 0px 10px">эсвэл / Or</span>
                </h2>

                <div class="flex justify-center items-center">
                  <div id="buttonDiv"></div>
                </div>
              </div>
            </div> -->
            <!-- Login new -->
            <div class="py-5 px-8 md:px-10 md:py-8 flex justify-center items-center">
              <div class="grid grid-cols-1 gap-4 w-full">
                <div>
                  <label for="signUp" class="ml-4 text-xs font-bold text-gray-600">ID</label>
                  <input
                    class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                    :class="{ 'border-red-500': emailValid }"
                    type="email"
                    placeholder="ID"
                    v-model="userName"
                    :disabled="lockEmail"
                  />
                </div>

                <div>
                  <label for="signUp" class="ml-4 text-xs font-bold text-gray-600">Password</label>
                  <div class="flex relative">
                    <input
                      class="bg-textbox border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:bg-slate-100"
                      placeholder="Нууц үг"
                      :type="passwordVisible ? 'text' : 'password'"
                      autocomplete="current-password"
                      v-model="userNewPassword"
                      name="password"
                    />

                    <button @click="togglePasswordVisibility" class="absolute top-3 right-3 cursor-pointer">
                      <img src="../../assets/common/eye-off.svg" alt="" v-if="!passwordVisible" />
                      <img src="../../assets/common/eye-on.svg" alt="" v-else />
                    </button>
                  </div>
                </div>

                <p v-if="incorrectPass" class="text-red-500 text-center text-xs font-bold">Таны нууц үг буруу байна!</p>

                <div class="flex justify-between">
                  <a href="/register" class="cursor-pointer text-xs font-bold text-gray-600 hover:text-blue-400">Нууц үг мартсан</a>

                  <a href="/register" class="text-right cursor-pointer text-xs font-bold text-gray-600 hover:text-blue-400">Бүртгүүлэх</a>
                </div>

                <button class="bg-sky-600 hover:bg-sky-700 text-white font-medium py-2 px-4 rounded-sm" type="button" @click="LogInto">
                  Нэвтрэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";

import { useValidation } from "../../../src/lib/common/useValidation";

export default {
  name: "LoginPage",
  // components: {
  //   LoadingSpinner,
  // },

  setup() {
    const store = useStore();
    const router = useRouter();

    const userEmail = ref("");
    const userPassword = ref("");
    const userConfirmPassword = ref("");
    const verificationCode = ref("");

    const loading = ref(false);
    const lockEmail = ref(false);

    const passwordVisible = ref(false);
    const confirmPassVisible = ref(false);

    // const error = reactive({

    // })

    const emailValid = ref(false);
    const passValid = ref(false);

    const showReturnBtn = ref(false);
    const showRegisterInp = ref(false);
    const showSuccessMsg = ref(false);

    const showPassInp = ref(false);
    const incorrectPass = ref(false);
    const showInserPass = ref(false);
    const showVerificationCode = ref(false);

    //new----->
    const userName = ref("");
    const userNewPassword = ref("");

    const { validateEmail, validatePassword } = useValidation();

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const toggleConfirmPassVisiblity = () => {
      confirmPassVisible.value = !confirmPassVisible.value;
    };

    const checkByEmail = async () => {
      if (!userEmail.value) {
        return alert("Та и-мэйл ээ оруулна уу.");
      } else {
        const res = validateEmail(userEmail.value);
        if (!res) {
          emailValid.value = true;
        } else {
          emailValid.value = false;
          const res = await store.dispatch("login/postToken", { email: userEmail.value });
          if (res.data.noPassword) {
            showRegisterInp.value = true;
            showReturnBtn.value = true;
            lockEmail.value = true;
          } else if (res.data.password == "exist") {
            showPassInp.value = true;
            lockEmail.value = true;
            showReturnBtn.value = true;
          } else if (res.data.registered && !res.data.pass) {
            showInserPass.value = true;
            lockEmail.value = true;
            showReturnBtn.value = true;
          }
        }
      }
    };

    const backToLogin = () => {
      showRegisterInp.value = false;
      showReturnBtn.value = false;
      lockEmail.value = false;
      showPassInp.value = false;
      showInserPass.value = false;
      showVerificationCode.value = false;
    };

    const registerAccount = async () => {
      const userPass = validatePassword(userPassword.value);
      const confirmPass = validatePassword(userConfirmPassword.value);
      if (userConfirmPassword.value === userPassword.value) {
        if (userPass && confirmPass) {
          const res = await store.dispatch("login/postToken", { email: userEmail.value, pass: userPassword.value });
          setTokenAndRedirect(res);
        } else {
          passValid.value = true;
        }
      } else {
        alert("Таны нууц үг баталгаажуулах нууц үгтэй ижил байх ёстой! ");
      }
    };

    const logIn = async () => {
      const res = await store.dispatch("login/postToken", { email: userEmail.value, pass: userPassword.value });
      if (!res.data.error) {
        setTokenAndRedirect(res);
      } else {
        incorrectPass.value = true;
      }
    };

    const forgotPass = async () => {
      loading.value = true;
      const res = await store.dispatch("login/forgotPass", { email: userEmail.value });
      if (res.status == 200) {
        loading.value = false;
        showInserPass.value = false;
        showPassInp.value = false;
        incorrectPass.value = false;
        showVerificationCode.value = true;
      }
    };

    const verifyCode = async () => {
      const res = await store.dispatch("login/verifyCode", { verificationCode: verificationCode.value, email: userEmail.value });
      if (res.data.status == "success") {
        router.push({
          path: "/login/resetPassword",
          query: { email: userEmail.value },
        });
      }
    };

    const setTokenAndRedirect = (res) => {
      // const expirationDate = new Date();
      // expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000);
      // Cookies.set("BStoken", res.data.token, {
      //   expires: expirationDate,
      // });
      // if (!res.data.new_user) {
      //   window.location.href = "/";
      // } else {
      //   window.location.href = "/user/questions";
      // }
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000);
      Cookies.set("BStoken", res.data.token, {
        expires: expirationDate,
      });
      if (res.status) {
        window.location.href = "/";
      }
    };

    //Google Login---->

    // const handleCredentialResponse = async (response) => {
    //   try {
    //     const res = await store.dispatch("login/postToken", { idToken: response.credential });
    //     setTokenAndRedirect(res);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };

    // const initializeGoogleSignIn = () => {
    //   window.google.accounts.id.initialize({
    //     client_id: "677235543627-k0kuc9d2e9hhb7h6gkt61i6tug9knj5u.apps.googleusercontent.com", // Replace with your window.Google Client ID
    //     callback: handleCredentialResponse,
    //   });

    //   window.google.accounts.id.renderButton(
    //     document.getElementById("buttonDiv"),
    //     { theme: "outline", size: "large" } // customization attributes
    //   );

    //   window.google.accounts.id.prompt(); // also display the One Tap dialog
    // };

    onMounted(() => {
      // initializeGoogleSignIn();
      if (router.currentRoute.value.query.status == "successfully") {
        showSuccessMsg.value = true;
      }
    });

    const LogInto = async () => {
      const res = await store.dispatch("login/postToken", { ID: userName.value, pass: userNewPassword.value });
      if (!res.data.error) {
        setTokenAndRedirect(res);
      } else if (res.data.error === "not registered") {
        alert("Бүртгэлгүй хэрэглэгч байна! / unregistered user");
      } else if (res.data.error == "Incorrect password") {
        incorrectPass.value = true;
      }
    };

    return {
      userEmail,
      userPassword,
      userConfirmPassword,
      checkByEmail,
      logIn,
      // handleCredentialResponse,
      // initializeGoogleSignIn,
      showRegisterInp,
      registerAccount,
      backToLogin,
      showPassInp,
      incorrectPass,
      setTokenAndRedirect,
      showInserPass,
      emailValid,
      togglePasswordVisibility,
      passwordVisible,
      passValid,
      confirmPassVisible,
      toggleConfirmPassVisiblity,
      forgotPass,
      lockEmail,
      showReturnBtn,
      showVerificationCode,
      verificationCode,
      verifyCode,
      showSuccessMsg,
      loading,

      userName,
      userNewPassword,
      LogInto,
    };
  },
};
</script>
