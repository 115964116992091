<template>
  <transition name="slide">
    <div v-show="isVisible" class="side-menu fixed inset-y-0 left-0 z-50 w-76 bg-white shadow-lg py-4">
      <div class="flex justify-center border-b-2 pb-4">
        <a href="/">
          <img class="h-8" :src="require('../../assets/common/logo.jpeg')" alt="" />
        </a>
      </div>
      <ul class="grid gap-6 p-4">
        <li class="text-slate-900 text-sm font-semibold uppercase" @click="$emit('toggle-side-menu')">
          <a href="/viewall/popular">Хамгийн эрэлттэй | 인기 있는 </a>
        </li>
        <li
          class="text-slate-900 text-sm font-semibold uppercase"
          @click="$emit('toggle-side-menu')"
          v-for="navCategory in navCategories"
          :key="navCategory.id"
        >
          <a :href="`/category/${navCategory.id}/sortby=views`"> {{ navCategory?.name }} </a>
        </li>

        <!-- <li class="text-slate-900 text-sm font-semibold uppercase" @click="$emit('toggle-side-menu')">
          <a href="/category">Костко</a>
        </li> -->
      </ul>
    </div>
  </transition>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
export default {
  name: "LgScreenMenu",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    toggleSideMenu: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const store = useStore();
    const navCategories = ref([]);
    onBeforeMount(async () => {
      await store.dispatch("categories/fetchNavCategories");
      navCategories.value = store.getters["categories/getNavCategories"];
    });
    return {
      navCategories,
    };
  },
};
</script>
