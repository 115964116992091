<template>
  <admin-page>
    <p class="text-2xl font-bold mb-4">Категори Засах/Устгах</p>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div v-if="tableData.length > 0" class="">
        <hot-table :settings="hotSettings" :data="tableData" ref="hot"></hot-table>
      </div>
      <div v-else class="flex justify-center items-center h-64">
        <div class="flex flex-col items-center gap-4">
          <div class="text-2xl font-bold">Категори байхгүй байна</div>
          <div class="text-xl">Категори нэмэх товчийг дарж категори нэмнэ үү</div>
          <router-link to="/admin/addcategory" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Категори нэмэх
          </router-link>
        </div>
      </div>
      <div id="modal" class="fixed top-[30%] left-[40%] z-[1001]" v-if="isModalOpen">
        <div class="bg-white w-[420px] p-6 rounded shadow">
          <p class="">Та доорх категори устгахдаа итгэлтэй байна уу?</p>
          <p class="mb-2 text-red-500">Категорид байгаа бараанууд болон дэд категори устгагдахыг анхаарна уу!</p>
          <p class="mb-2"><span class="font-bold">Категори:</span> {{ id[1] }}</p>
          <p class="mb-4"><span class="font-bold">ID:</span> {{ id[0] }}</p>

          <div class="flex justify-center gap-4 mt-4">
            <button id="close-modal" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 w-24 rounded" @click="isModalOpen = false">
              Хаах
            </button>
            <button id="" class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 w-24 rounded" @click="deleteCategory">Устгах</button>
          </div>
        </div>
      </div>
    </div>

    <div class="backDrop" v-if="isModalOpen" @click="isModalOpen = false" @keydown.space="isModalOpen = false"></div>
  </admin-page>
</template>
<script>
import { onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import instance from "@/lib/common/axios";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
export default {
  name: "EditCategory",
  components: {
    HotTable,
  },
  setup() {
    registerAllModules();
    const store = useStore();
    const isModalOpen = ref(false);
    const isEdit = ref(false);
    const id = ref(null);
    const hotSettings = {
      columnSorting: {
        initialConfig: {
          column: 2,
          sortOrder: "asc",
        },
      },
      width: "100%",
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",

      colHeaders: ["ID", "Нэр", "Үндсэн ангилал", "Үйлдэл"],

      columns: [
        {
          data: "id",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.style.background = "#EEE";
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "name",
          type: "text",
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "root_category_name",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.style.background = "#EEE";
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "",
          readOnly: true,
        },
      ],

      rowHeights: 30,

      colWidths: [50, 350, 300, 200],

      cells: function (row, col) {
        var cellProperties = {};
        if (col === 3) {
          cellProperties.renderer = function (inst, td, row) {
            td.classList.add("htCenter", "htMiddle");

            td.innerHTML = `<div class="flex justify-center  gap-2">
                <button id="edit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Засах</button>
                <button id="delete" class="bg-red-500 hover:bg-red-600 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Устгах</button>
            </div>`;
            const editBtn = td.querySelector("#edit");
            const deleteBtn = td.querySelector("#delete");

            editBtn.addEventListener("click", async () => {
              if (isEdit.value) {
                id.value = inst.getDataAtRow(row);
                editCategory();
              }
            });

            deleteBtn.addEventListener("click", async () => {
              isModalOpen.value = true;
              id.value = inst.getDataAtRow(row);
            });
          };
        }
        return cellProperties;
      },

      afterChange: function (changes, source) {
        if (source === "edit") {
          const oldValue = changes[0][2]; // Assuming changes[0] represents the first change
          const newValue = changes[0][3];

          if (oldValue !== newValue) {
            isEdit.value = true;
          } else {
            isEdit.value = false;
          }
        }
      },
    };
    const tableData = ref(computed(() => store.getters["admin/getCategories"]));

    onBeforeMount(async () => {
      await store.dispatch("admin/fetchCategories");
    });

    const editCategory = async () => {
      try {
        const res = await instance.put(`/admin/updatecategory`, {
          id: id.value[0],
          name: id.value[1],
        });
        if (res.status === 200) await store.dispatch("admin/fetchCategories");
        isModalOpen.value = false;
        alert("Амжилттай засагдлаа");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    };

    const deleteCategory = async () => {
      try {
        const res = await instance.delete(`/admin/deletecategory/${id.value[0]}`);
        if (res.status === 200) await store.dispatch("admin/fetchCategories");
        isModalOpen.value = false;
        alert("Амжилттай устгагдлаа");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    };

    return {
      hotSettings,
      id,
      tableData,
      isModalOpen,
      isEdit,
      editCategory,
      deleteCategory,
    };
  },
};
</script>

<style>
.backDrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}
</style>
