<template>
  <div>
    <admin-page>
      <p class="text-2xl font-bold mb-4">Категори нэмэх</p>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="w-[70%]">
          <div class="flex gap-8 w-full">
            <div class="flex-1">
              <div class="flex flex-col gap-5 mb-4">
                <!-- <label for="category" class="block text-sm font-medium text-gray-700">Дэд категори оруулах</label> -->
                <div class="flex gap-4">
                  <select
                    name="categories"
                    id=""
                    class="w-[60%] h-10 rounded-lg px-4 py-2 mt-1 border-1"
                    @change="fetchSubCategories($event.target.value)"
                    @click="emptySubCategories"
                  >
                    <option value="" disabled selected>Категори сонгох</option>
                    <option v-for="category in parentCat" :value="category.id" :key="category.id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>

                <select
                  v-if="subCat1?.length > 0"
                  name=""
                  id=""
                  class="w-[60%] h-10 rounded-lg px-4 py-2 mt-1 border-1"
                  @change="fetchSubCategories1($event.target.value)"
                >
                  <option value="" disabled selected>Дэд категори сонгох</option>
                  <option v-for="category in subCat1" :value="category.id" :key="category.id">{{ category.name }}</option>
                </select>

                <select
                  v-if="subCat2?.length > 0"
                  name=""
                  id=""
                  class="w-[60%] h-10 rounded-lg px-4 py-2 mt-1 border-1"
                  @change="fetchSubCategories2($event.target.value)"
                >
                  <option value="" disabled selected>Дэд категори сонгох</option>
                  <option v-for="category in subCat2" :value="category.id" :key="category.id">{{ category.name }}</option>
                </select>

                <select
                  v-if="subCat3?.length > 0"
                  name=""
                  id=""
                  class="w-[60%] h-10 rounded-lg px-4 py-2 mt-1 border-1"
                  @change="fetchSubCategories3($event.target.value)"
                >
                  <option value="" disabled selected>Дэд категори сонгох</option>
                  <option v-for="category in subCat3" :value="category.id" :key="category.id">{{ category.name }}</option>
                </select>

                <select
                  v-if="subCat4?.length > 0"
                  name=""
                  id=""
                  class="w-[60%] h-10 rounded-lg px-4 py-2 mt-1 border-1"
                  @change="currCatId = $event.target.value"
                >
                  <option value="" disabled selected>Дэд категори сонгох</option>
                  <option v-for="category in subCat4" :value="category.id" :key="category.id">{{ category.name }}</option>
                </select>

                <div>
                  <label for="category" class="block text-sm font-medium text-gray-700 mb-4">Категори нэр оруулах</label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    placeholder="Категори нэр"
                    v-model="category"
                    class="mt-1 px-4 py-2 block w-[60%] h-10 rounded-md border-1 focus:border-gray-500 focus:bg-white focus:ring-0"
                  />
                  <!-- <div class="flex gap-2 mt-4 flex-col">
                    <p for="isNavbar" class="block text-sm font-medium text-gray-700">Цэсэн дээр харуулах эсэх</p>
                    <input type="checkbox" class="w-6 h-6" v-model="isNavbar" />
                  </div> -->
                </div>
              </div>
              <div class="mt-4">
                <button type="submit" class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md" @click="addCategory">
                  Категори нэмэх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </admin-page>
  </div>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import instance from "@/lib/common/axios";
export default {
  name: "AddCategoryPage",
  setup() {
    const store = useStore();

    const category = ref("");

    const rootId = ref("");
    const currCatId = ref("");
    const willEnterSubCat = ref(false);
    const isNavbar = ref(false);
    // const subCatId = ref("");

    const parentCat = ref([]);
    const subCat1 = ref([]);
    const subCat2 = ref([]);
    const subCat3 = ref([]);
    const subCat4 = ref([]);

    onBeforeMount(async () => {
      await store.dispatch("admin/fetchCategories");
      parentCat.value = store.getters["admin/getCategories"].filter((cat) => cat.parent_category_id === null);
    });

    const fetchSubCategories = (id) => {
      subCat1.value = store.getters["admin/getCategories"].filter((cat) => cat.parent_category_id == id);
      currCatId.value = id;
      rootId.value = id;
      willEnterSubCat.value = true;
    };

    const fetchSubCategories1 = (id) => {
      subCat2.value = store.getters["admin/getCategories"].filter((cat) => cat.parent_category_id == id);
      currCatId.value = id;
      willEnterSubCat.value = true;
    };

    const fetchSubCategories2 = (id) => {
      subCat3.value = store.getters["admin/getCategories"].filter((cat) => cat.parent_category_id == id);
      currCatId.value = id;
      willEnterSubCat.value = true;
    };

    const fetchSubCategories3 = (id) => {
      subCat4.value = store.getters["admin/getCategories"].filter((cat) => cat.parent_category_id == id);
      currCatId.value = id;
      willEnterSubCat.value = true;
    };

    const emptySubCategories = () => {
      subCat1.value = [];
      subCat2.value = [];
      subCat3.value = [];
      subCat4.value = [];
    };

    const addCategory = async () => {
      if (category.value === "") {
        alert("Категори нэр оруулна уу");
        return;
      }
      if (willEnterSubCat.value) {
        try {
          await instance.post("/admin/insertsubcategory", {
            name: category.value,
            parent: currCatId.value,
            rootId: rootId.value,
          });
          alert("Амжилттай нэмэгдлээ");
          window.location.reload();
        } catch (err) {
          alert("Алдаа гарлаа");
        }
      } else {
        try {
          await instance.post("/admin/insertcategory", {
            name: category.value,
            isNavbar: isNavbar.value,
          });
          alert("Амжилттай нэмэгдлээ");
          window.location.reload();
        } catch (err) {
          alert("Алдаа гарлаа");
        }
      }
    };

    return {
      currCatId,
      parentCat,
      category,
      subCat1,
      subCat2,
      subCat3,
      subCat4,
      isNavbar,
      addCategory,
      fetchSubCategories,
      fetchSubCategories1,
      fetchSubCategories2,
      fetchSubCategories3,
      emptySubCategories,
    };
  },
};
</script>
