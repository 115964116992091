<template>
  <div>
    <div class="bg-white p-5 md:py-7 md:px-9 text-justify rounded-lg border border-slate-200 divide-y-2 md:sticky md:top-2">
      <div class="pb-3">
        <div class="font-extrabold text-lg">Захиалга / My order</div>
        <div class="text-sm">
          <div class="pb-2">Гаалийн татварын чөлөөлөлтөнд хамрагдахын тулд:</div>
          <ul class="list-disc pl-10">
            <li>Ижил төрлийн бараанаас 2-оос илүүгүй тоогоор захиалах</li>
            <li>Хилээр нэвтрүүлэхийг хориглоогүй байх</li>
            <li>Нийт 4,200,000 төгрөгөөс дээшгүй үнийн дүнтэй байх</li>
          </ul>
          Та гаалийн татварын чөлөөлөлтөнд хамрагдахын тулд “
          <span class="font-medium underline">
            <a href="/">Гаалийн тариф, гаалийн татварын тухай</a>
            ” хуулийн 38 дугаар зүйлийг харна уу.
          </span>
        </div>
      </div>
      <div>
        <table class="w-full table-auto text-sm">
          <tbody>
            <tr>
              <td class="py-2"><span>Үнийн дүн / Amount</span></td>
              <td v-if="productsTotal" class="text-right">{{ productsTotal.toLocaleString() }} вон</td>
            </tr>

            <tr v-if="deliveryFee">
              <td class="py-2"><span>Хүргэлтийн дүн / Delivery fee</span></td>
              <td class="text-right">{{ deliveryFee.toLocaleString() }} вон</td>
            </tr>

            <tr v-if="vat">
              <td class="py-2"><span>VAT</span></td>
              <td class="text-right">{{ vat.toLocaleString() }} вон</td>
            </tr>

            <tr class="font-semibold">
              <td class="pr-10 py-2"><span>Нийт дүн / Total amount</span></td>
              <td v-if="totalAmount" class="text-right">{{ totalAmount.toLocaleString() }} вон</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="py-2" v-if="orderProducts">
        <div class="py-3" v-for="item in orderProducts" :key="item.id">
          <div class="flex justify-start">
            <div>
              <img class="w-14" :src="item.products.image_url" alt="" />
            </div>
            <div class="pl-5">
              <div class="mb-1 text-xs">{{ item.products.name }}</div>
            </div>
          </div>
          <div class="flex justify-between items-center text-xs">
            <div class="font-bold"></div>

            <div class="flex justify-end items-center text-xs">
              <div class="p-1 bg-gray-100 rounded-sm mr-3">x{{ item.products.quantity }}</div>
              <div>{{ item.products.price.toLocaleString() }} вон</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderProducts: {
      type: Object,
      default: null,
    },
    productsTotal: {
      type: Number,
      default: 0,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
    deliveryFee: {
      type: Number,
      default: 0,
    },
    vat: {
      type: Number,
      default: 0,
    },
  },
};
</script>
