<template>
  <admin-page>
    <loading-spinner v-if="loading" class="flex justify-center items-center h-screen" />
    <div v-else>
      <h1 class="text-2xl font-semibold mb-4">Бүтээгдэхүүний жагсаалт</h1>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div v-if="products && products?.products?.length > 0">
          <HotTable :settings="hotSettings" :data="products.products" ref="hotTable" class="mt-2" />
          <div class="mt-6">
            <PaginationVue @sent-pageNum="handlePageNum" :totalProductPages="products?.totalPages" />
          </div>
        </div>
        <div v-else class="flex justify-center items-center h-64">
          <div class="flex flex-col items-center gap-4">
            <div class="text-2xl font-bold">Бүтээгдэхүүн байхгүй байна</div>
            <div class="text-xl">Бүтээгдэхүүн нэмэх товчийг дарж категори нэмнэ үү</div>
            <router-link to="/admin/addproduct" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Бүтээгдэхүүн нэмэх
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { ref, watch, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import instance from "@/lib/common/axios";
import PaginationVue from "../../components/pagination/Pagination.vue";
import LoadingSpinner from "../../components/ui/LoadingSpinner.vue";

export default {
  name: "ProductList",
  components: {
    HotTable,
    PaginationVue,
    LoadingSpinner,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const products = ref([]);
    const hotTableRef = ref(null);
    const loading = ref(false);

    registerAllModules();
    const hotSettings = {
      data: [],
      columnSorting: {
        initialConfig: {
          column: 2,
          sortOrder: "asc",
        },
      },
      width: "100%",
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      colHeaders: [
        "ID",
        "Нэр",
        "Үнэ",
        "Membership үнэ",
        "PV оноо",
        "Тайлбар",
        "Ангилал",
        "Зураг",
        "Үлдэгдэл",
        "Төрөл 1",
        "Төрлийн үнэ",
        "Төрөл 2",
        "Төрөл 3",
        "Хямдрал",
        "Үйлдэл",
      ],
      colWidths: [50, 200, 150, 150, 150, 100, 100, 100, 100, 100, 100, 100, 65, 200],
      columns: [
        {
          data: "id",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "name",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "price",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "membership_price",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "pv_point",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "description",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "category",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "image_url",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center"><img src="${value ? value : ""}" alt="" class="w-20 h-20 object-cover"></div>`;
          },
        },
        {
          data: "stock",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "variant1",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value?.values : ""}</div>`;
          },
        },
        {
          data: "variant1",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${
              value && value?.prices.length > 0
                ? value?.values.map((item, index) => {
                    return `<div>${item}:${value?.prices[index]}</div>`;
                  })
                : ""
            }</div>`;
          },
        },

        {
          data: "variant2",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "variant3",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "discount",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center">${value ? value : ""}</div>`;
          },
        },
        {
          data: "action",
          readOnly: true,
          renderer: function (inst, td, row) {
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center  gap-2">
                <button id="edit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Засах</button>
                <button id="delete" class="bg-red-500 hover:bg-red-600 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Устгах</button>
            </div>`;

            td.querySelector("#edit").addEventListener("click", function () {
              const id = inst.getDataAtRow(row)[0];
              router.push({ name: "EditProduct", params: { id: id } });
            });

            td.querySelector("#delete").addEventListener("click", function () {
              const id = inst.getDataAtRow(row)[0];
              deleteProduct(id);
              inst.alter("remove_row", row);
              inst.render();
              inst.validateCells();
            });
          },
        },
      ],
    };

    watch(
      () => store.getters["admin/getProducts"],
      (newVal, oldVal) => {
        if (newVal !== oldVal && hotTableRef.value) {
          hotTableRef.value.hotInstance.loadData(newVal.products);
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      try {
        loading.value = true;
        await store.dispatch("admin/fetchProducts", 1);
        const res = (products.value = store.getters["admin/getProducts"]);
        console.log(res);
        // if (res.status == 200) {
        loading.value = false;
        // }
        proxy.$nextTick(() => {
          hotTableRef.value = proxy.$refs.hotTable;
        });
      } catch (err) {
        console.error(err);
      }
    });

    const deleteProduct = async (id) => {
      const res = await instance.post("/admin/deleteproduct", { id: id });
      if (res.statusText !== "OK") {
        alert("Бүтээгдэхүүн устгах явцад алдаа гарлаа!: ", res.statusText);
      }
    };

    const handlePageNum = async (data) => {
      await store.dispatch("admin/fetchProducts", data);
    };

    return {
      products,
      hotSettings,
      deleteProduct,
      handlePageNum,
      loading,
    };
  },
};
</script>
