<template>
  <div class="bg-slate-50 flex-grow min-h-screen">
    <div class="max-w-[1240px] mx-auto">
      <div class="divide-y">
        <div id="title" class="flex justify-between items-baseline md:items-center">
          <p class="pl-5 py-3 pt-5 font-bold text-lg uppercase">
            Миний сагс / My Cart<span
              >(<span>{{ products?.length }}</span
              >)</span
            >
          </p>
          <button @click="deleteAllBacket" class="flex justify-end items-center text-gray-700 pr-3 md:pr-0">
            <img src="../assets/common/recycle-bin.svg" alt="" />
            <span class="text-xs md:text-sm pl-1 uppercase">Сагс хоослох / delete all</span>
          </button>
        </div>
        <div v-if="products && products?.length > 0" class="md:grid md:grid-cols-10 gap-5">
          <div id="cart-body" class="col-span-7 bg-white my-3 p-5 divide-y md:mb-5 mb-5">
            <div class="hidden md:block">
              <div class="grid grid-cols-7 gap-3 flex justify-center items-center text-center text-xs uppercase font-bold pb-3">
                <div class="col-span-4 text-left">Бүтээгдэхүүн</div>
                <div class="col-span-1">Үнэ</div>
                <div class="col-span-1">Тоо ширхэг</div>
                <div class="col-span-1">Нийт үнэ</div>
              </div>
            </div>

            <div v-if="products">
              <BacketCard :product="products" @minusTotal="minusTotal" @addTotal="addTotal" @removeProductById="removeById"></BacketCard>
            </div>
          </div>
          <div class="col-span-3 w-full">
            <div class="bg-white md:my-3 p-5 divide-y">
              <div class="text-lg font-bold uppercase md:pb-2 px-2">Нийт дүн / Total Amount</div>
              <div class="px-2 pt-5 md:pt-10 font-medium">
                <div class="flex justify-between pb-2 md:pb-4">
                  <span class="text-gray-500">Барааны тоо: </span>
                  <span v-if="totalQuantity > 0">{{ totalQuantity }}</span>
                </div>
                <div class="flex justify-between pb-5 md:pb-8">
                  <span class="text-gray-500">Үнийн дүн: </span>
                  <span v-if="totalAmount > 0" class="text-sky-500 font-bold">{{ totalAmount.toLocaleString() }} вон</span>
                </div>
                <div>
                  <button
                    class="mt-2 w-full bg-sky-500 text-white text-center text-xs font-bold uppercase rounded h-10 flex justify-center items-center hover:shadow-lg"
                    @click="orderProducts"
                  >
                    <span>Захиалах / Start order</span>
                  </button>
                </div>
              </div>
              <div class="Toastify"></div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col justify-center items-center h-[calc(70vh-64px)]">
          <img src="../assets/common/empty-box.png" class="w-40" alt="" />
          <h1 class="text-xl font-semibold">Таны сагс хоосон байна</h1>
          <a href="/" class="mb-4"
            ><button class="bg-black mt-5 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-3 rounded text-sm">
              Дэлгүүр рүү буцах
            </button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted, ref, watch } from "vue";
import BacketCard from "@/components/product/BacketCard.vue";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

//onBeforeMount
export default {
  components: {
    BacketCard,
  },
  async setup() {
    const store = useStore();
    const router = useRouter();

    const order = ref(null);
    const products = ref([]);
    const userInfo = ref({});
    const orderId = ref({});
    const totalQuantity = ref([]);
    const totalAmount = ref([]);
    const pvPoint = ref(0);
    // const vatTotal = ref(null);

    //fetch order data from backet table
    const fetchOrder = async () => {
      if (Cookies.get("BStoken")) {
        const userID = jwt_decode(Cookies.get("BStoken")).user_id;
        // await store.dispatch("products/getBacketData", userID);

        //testing authorization ********************************
        await store.dispatch("products/getBacketData", userID);

        products.value = store.getters["products/getBacketData"];

        orderId.value = products?.value[0]?.order_id;
        // hereglegchiin medeelel avah
        await store.dispatch("login/fetchAccount", jwt_decode(Cookies.get("BStoken")).user_id);
        userInfo.value = store.getters["login/getAccounts"]?.data[0];
      } else {
        products.value = JSON.parse(localStorage.getItem("backetList"));
      }

      totalAmount.value = products?.value?.reduce((sum, item) => {
        return sum + item.products.quantity * item.products.price;
      }, 0);

      totalQuantity.value = products?.value?.reduce((sum, item) => {
        return sum + item.products.quantity;
      }, 0);

      pvPoint.value = products?.value?.reduce((sum, item) => {
        if (item.products.pvPoint == null) return sum + 0;
        else return sum + item.products.quantity * item.products.pvPoint;
      }, 0);
    };

    watch(
      () => store.getters["products/getBacketData"],
      (newVal) => {
        products.value = newVal;
      }
    );

    onMounted(async () => {
      await fetchOrder();
    });

    const deleteAllBacket = async () => {
      if (products.value.length > 0) {
        const token = Cookies.get("BStoken");
        if (token) {
          const idData = products.value.map((item) => item.id);
          const response = await store.dispatch("products/insertUpdateDeleteBacket", { id: idData, userId: jwt_decode(token).user_id });
          if (response.statusText == "OK" || response.status == 200) window.location.reload();
          else console.error(response.statusText);
        } else {
          products.value.splice(0);
          localStorage.removeItem("backetList");
          store.commit("products/setBacketListCount", JSON.parse(localStorage.getItem("backetList"))?.length);
        }
      } else {
        alert("Таны сагс хоосон байна!");
      }
    };

    const addTotal = async (data) => {
      if (data !== null) {
        totalAmount.value += parseInt(data.price);
        totalQuantity.value += 1;
      }
    };

    const minusTotal = (data) => {
      if (data !== null) {
        totalAmount.value -= parseInt(data.price);
        totalQuantity.value -= 1;
      }
    };

    const removeById = (data) => {
      totalAmount.value -= parseInt(data.total);
      totalQuantity.value -= data.quantity;
      products.value.splice(data.index, 1);
      localStorage.setItem("backetList", JSON.stringify(products.value));
      store.commit("products/setBacketListCount", JSON.parse(localStorage.getItem("backetList"))?.length);
    };

    const orderProducts = () => {
      if (Cookies.get("BStoken")) {
        store.commit("order/setCustomer", {
          firstName: userInfo.value.first_name,
          lastName: userInfo.value.last_name,
          phone: userInfo.value.phone_num,
          address: userInfo.value.detailed_address,
          registerNum: userInfo.value.register_num,
        });
        store.commit("order/setShippingAddresses", userInfo.value.account_addresses || []);
        store.commit("order/setUserId", userInfo.value.user_id);
        store.commit("order/setCustomerEmail", userInfo.value.email);
        store.commit("order/setOrderId", orderId.value);
        store.commit("order/setProducts", products.value);
        store.commit("order/setShippingAddress", userInfo.value.detailed_address);
        store.commit("order/setProductsTotal", totalAmount.value);
        store.commit("order/setTotalAmount", totalAmount.value);
        store.commit("order/setPvPoint", pvPoint.value);
        // store.commit("order/setVat", vatTotal.value);

        router.push("/order/address");
        window.scrollTo(0, 0);
      } else {
        router.push("/order/nonuser");
        window.scrollTo(0, 0);
      }
    };

    return {
      order,
      products,
      totalAmount,
      totalQuantity,
      deleteAllBacket,
      addTotal,
      minusTotal,
      orderProducts,
      removeById,
    };
  },
};
</script>
