<template>
  <admin-page>
    <p class="text-2xl font-bold mb-4">Баннер оруулах</p>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div class="mb-4 w-[40%]">
        <div class="flex flex-col">
          <label for="bannerImage" class="block text-gray-700 text-sm font-bold mb-2">Баннер зураг сонгох</label>
          <input id="bannerImage" type="file" class="border border-gray-300 p-2 rounded-md mb-4" @change="handleImageChange" />
        </div>
        <div class="flex flex-col">
          <label for="bannerUrl" class="block text-gray-700 text-sm font-bold mb-2">Баннер линк</label>
          <input id="bannerUrl" type="text" class="border border-gray-300 p-2 rounded-md mb-4" @change="handleBannerUrl" />
        </div>
        <div class="flex flex-col">
          <label for="isBanner" class="block text-gray-700 text-sm font-bold mb-2">Баннер болгох эсэх</label>
          <input id="isBanner" type="checkbox" v-model="isBanner" class="form-checkbox h-5 w-5 text-gray-600" />
        </div>
      </div>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="addBanner">
        Баннер оруулах
      </button>
    </div>
    <p class="text-2xl font-bold mb-4">Баннер Засах/Устгах</p>
    <div>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" v-if="tableData.length > 0">
        <hot-table :settings="hotSettings" :data="tableData" ref="hotTable"></hot-table>
        <div class="flex justify-end"></div>
      </div>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" v-else>
        <p class="text-center">Баннер олдсонгүй</p>
      </div>
    </div>
  </admin-page>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import instance from "@/lib/common/axios";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
export default {
  name: "BannerAddEdit",
  components: {
    HotTable,
  },

  setup() {
    registerAllModules();
    const image = ref(null);
    const isBanner = ref(false);
    const bannerUrl = ref("");
    const store = useStore();
    const tableData = ref([]);

    const hotSettings = {
      colHeaders: ["Баннер зураг", "Баннер линк", "Баннер болгох", "Үйлдэл"],
      columns: [
        {
          data: "image_url",
          width: 300,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center"><img src="${value ? value : ""}" alt="" class="w-20 h-20 object-fit"></div>`;
          },
        },
        {
          data: "link",
          width: 300,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center"><a href="${value ? value : ""}" target="_blank" class="text-blue-500 underline">${
              value ? value : ""
            }</a></div>`;
          },
        },
        {
          data: "is_banner_active",
          type: "checkbox",
          width: 100,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<input type="checkbox" ${value ? "checked" : ""} class="form-checkbox h-5 w-5 text-gray-600" />`;

            td.querySelector("input").addEventListener("click", () => {
              const data = tableData.value[td.parentNode.rowIndex - 1];
              data.is_banner_active = !data.is_banner_active;
            });
          },
        },
        {
          data: "",
          width: 150,
          readOnly: true,
          renderer: function (instance, td) {
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center  gap-2">
                <button id="edit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Засах</button>
                <button id="delete" class="bg-red-500 hover:bg-red-600 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Устгах</button>
            </div>`;

            td.querySelector("#edit").addEventListener("click", () => {
              const data = tableData.value[td.parentNode.rowIndex - 1];

              updateBanner(data);
            });

            td.querySelector("#delete").addEventListener("click", () => {
              const id = tableData.value[td.parentNode.rowIndex - 1].id;
              deleteBanner(id);
            });
          },
        },
      ],
      rowHeaders: true,
      stretchH: "all",
      width: "100%",
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      filters: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    onBeforeMount(async () => {
      await store.dispatch("admin/fetchBanners");
      tableData.value = store.getters["admin/getBanners"];
    });

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      image.value = file;
    };

    const handleBannerUrl = (e) => {
      const bannerUrlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      if (!bannerUrlRegex.test(e.target.value)) return alert("Зөв URL оруулна уу");
      bannerUrl.value = e.target.value;
    };

    const addBanner = async () => {
      if (!image.value) return alert("Зураг сонгоно уу");
      const formData = new FormData();
      formData.append("image", image.value);
      formData.append("bannerUrl", bannerUrl.value);
      formData.append("isBanner", isBanner.value);
      try {
        const res = await instance.post("/admin/addbanner", formData);
        if (res.status === 200) {
          alert("Амжилттай нэмэгдлээ");
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const updateBanner = async (data) => {
      try {
        const res = await instance.post("/admin/updatebanner", data);
        if (res.status === 200) {
          alert("Амжилттай засагдлаа");
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deleteBanner = async (id) => {
      try {
        const res = await instance.post("/admin/deletebanner", { id });
        if (res.status === 200) {
          alert("Амжилттай устгалаа");
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      image,
      isBanner,
      hotSettings,
      tableData,
      addBanner,
      updateBanner,
      deleteBanner,
      handleBannerUrl,
      handleImageChange,
    };
  },
};
</script>
