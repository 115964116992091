import instance from "@/lib/common/axios";

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      orderList: [],
      banners: [],
      products: [],
    };
  },

  getters: {
    getCategories(state) {
      return state.categories;
    },

    getBanners(state) {
      return state.banners;
    },

    getOrderList(state) {
      return state.orderList;
    },
    getProducts(state) {
      return state.products;
    },
  },

  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },

    setBanners(state, payload) {
      state.banners = payload;
    },

    setOrderList(state, payload) {
      state.orderList = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
  },

  actions: {
    async fetchCategories({ commit }) {
      const response = await instance.get("/admin/getcategories");
      commit("setCategories", response.data);
    },

    async fetchBanners({ commit }) {
      const response = await instance.get("/admin/getbanners");
      commit("setBanners", response.data);
    },

    async fetchOrderList({ commit }) {
      const response = await instance.get("/admin/getorderlist");
      commit("setOrderList", response.data);
    },

    async fetchNonUserOrderList({ commit }) {
      const response = await instance.get("/admin/getnonuserorderlist");
      commit("setOrderList", response.data);
    },

    async searchOrder({ commit }, payload) {
      try {
        const response = await instance.post("/admin/searchorder", payload);
        commit("setOrderList", response.data);
      } catch (error) {
        console.error(error);
      }
    },
    //fetch prodcuts and display in admin page
    async fetchProducts({ commit }, payload) {
      const response = await instance.post("/admin/getproducts", { pageNum: payload });
      commit("setProducts", response.data);
      return response;
    },
  },
};
