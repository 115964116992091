<template>
  <div class="max-w-[1240px] mx-auto min-h-screen">
    <div class="px-3">
      <p class="my-4">(нийт бүтээгдэхүүн {{ products.length }})</p>
      <transition name="fade" mode="out-in">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pb-4">
          <ProductCard v-for="product in products" :key="product.id" :product="product" />
        </div>
      </transition>
    </div>
    <back-to-top></back-to-top>
  </div>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { addToWishList } from "@/lib/common/helper";
import ProductCard from "@/components/product/ProductCard.vue";
import BackToTop from "@/components/ui/BackToTop.vue";
import Cookies from "js-cookie";
export default {
  name: "ViewAllPage",
  components: {
    ProductCard,
    BackToTop,
  },

  async setup() {
    const store = useStore();
    const route = useRoute();
    const products = ref([]);
    const isWishList = ref(false);

    onBeforeMount(async () => {
      await store.dispatch("products/fetchViewAllPageProducts", { id: route.params.id, token: Cookies.get("BStoken") });
      products.value = store.getters["products/getProducts"];
    });

    const addToList = (product) => {
      isWishList.value = !isWishList.value;
      addToWishList(product);
    };

    return {
      isWishList,
      addToList,
      products,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
