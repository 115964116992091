<template>
  <admin-page>
    <!-- search orders -->
    <div></div>
    <div>
      <p class="text-2xl font-bold mb-4">Захиалагууд</p>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-8">
          <p class="text-center mb-4 text-lg">Захиалга хайх</p>
          <hot-table :settings="hotSearchSettings"></hot-table>
        </div>
        <div v-if="orderList.length > 0 && !isLoading">
          <hot-table :settings="hotSettings" :data="orderList" ref="hot"></hot-table>
          <div v-if="isLoading" class="flex items-center justify-center h-screen">
            <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-500"></div>
          </div>
        </div>
        <div v-if="orderList.length == 0 && !isLoading">
          <p class="text-center">Захиалга олдсонгүй</p>
        </div>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { ref, onBeforeMount, computed, reactive } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

import instance from "@/lib/common/axios";
export default {
  components: {
    HotTable,
  },
  setup() {
    const store = useStore();
    const orderList = ref([]);
    const hot = ref(null);
    const isLoading = ref(false);
    const searchValue = reactive({
      orderId: "",
      name: "",
      phone: "",
      email: "",
      orderDate: "",
    });
    registerAllModules();

    const hotSettings = {
      rowHeaders: true,
      manualRowResize: true,
      manualColumnResize: true,
      width: "100%",
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      data: orderList.value,
      colHeaders: [
        "Захиалгын дугаар",
        "Захиалагчийн нэр",
        "Захиалагчийн утас",
        "Захиалагчийн email",
        "Захиалагчийн хаяг",
        "Захиалагийн мэдээлэл",
        // "Захиалагчийн </br> захиалсан барааны зураг",
        // "Захиалагчийн </br> захиалсан барааны нэр",
        // "Захиалагчийн </br> захиалсан барааны төрөл",
        "Захиалагийн нийт дүн",
        "Захиалгийн төлбөрийн дугаар",
        "Захиалсан огноо",
        "Хүргэлтийн төлөв",
      ],
      columns: [
        {
          data: "id",
          type: "numeric",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "name",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="capitalize">${value}</div>`;
          },
        },
        {
          data: "phone",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "email",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "deliveryAddress",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "",
          readOnly: true,
          renderer: function (instance, td, row) {
            td.classList.add("htCenter", "htMiddle", "cursor-pointer");
            td.innerHTML = `<div class="flex justify-center items-center">
              <button id="showDetail" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="showOrderDetail">Дэлгэрэнгүй</button>
            </div>`;

            td.querySelector("#showDetail").addEventListener("click", function () {
              const orderId = instance.getDataAtRowProp(row, "id");
              showOrderDetail(orderId);
            });
          },
        },
        {
          data: "total",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}вон</div>`;
          },
        },
        {
          data: "paymentKey",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "orderDate",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        // update delivery status
        {
          data: "deliveryStatus",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle", "cursor-pointer");
            td.innerHTML = `<div class="flex justify-center items-center">
              <button id="updateDeliveryStatus" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="updateDeliveryStatus">${value}</button>
            </div>`;

            td.querySelector("#updateDeliveryStatus").addEventListener("click", function () {
              const orderId = instance.getDataAtRowProp(row, "order_id");
              updateDeliveryStatus(orderId);
            });
          },
        },
      ],
      // colWidths: [300, 150, 150, 150, 200, 160, 200, 200, 200, 200],
    };

    const hotSearchSettings = {
      // rowHeaders: true,
      // manualRowResize: true,
      // manualColumnResize: true,
      // width: "100%",
      // height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      maxRows: 1,
      colHeaders: ["Захиалгын дугаар", "Захиалагчийн нэр", "Захиалагчийн утас", "Захиалагчийн email", "Захиалсан огноо", ""],
      columns: [
        {
          data: "1",
          type: "numeric",
          renderer: function (instance, td, row, col, prop, value) {
            searchValue.orderId = value;
            td.innerHTML = `<div class="flex justify-center items-center">${value || ""}</div>`;
          },
        },
        {
          data: "2",
          renderer: function (instance, td, row, col, prop, value) {
            searchValue.name = value;
            td.innerHTML = `<div class="flex justify-center items-center">${value || ""}</div>`;
          },
        },
        {
          data: "3",
          renderer: function (instance, td, row, col, prop, value) {
            searchValue.phone = value;
            td.innerHTML = `<div class="flex justify-center items-center">${value || ""}</div>`;
          },
        },
        {
          data: "4",
          renderer: function (instance, td, row, col, prop, value) {
            searchValue.email = value;
            td.innerHTML = `<div class="flex justify-center items-center">${value || ""}</div>`;
          },
        },
        {
          data: "5",
          type: "date",
          dateFormat: "YYYY-MM-DD",
          renderer: function (instance, td, row, col, prop, value) {
            searchValue.orderDate = value;
            td.innerHTML = `<div class="flex justify-center items-center">${value || ""}</div>`;
          },
        },
        {
          data: "6",
          readOnly: true,
          renderer: function (instance, td) {
            td.innerHTML = `<div class="flex justify-center items-center">
              <button id="search" class="bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline" >Хайх</button>
            </div>`;

            td.querySelector("#search").addEventListener("click", function () {
              searchOrder();
            });
          },
        },
      ],
      colWidths: [230, 200, 150, 250, 200],
    };

    onBeforeMount(async () => {
      await store.dispatch("admin/fetchOrderList");

      orderList.value = computed(() => store.getters["admin/getOrderList"]).value;
    });

    const showOrderDetail = (id) => {
      window.location.href = `/admin/orderdetail/user=true/order=${id}`;
    };

    const searchOrder = async () => {
      if (!searchValue.orderId && !searchValue.name && !searchValue.phone && !searchValue.email && !searchValue.orderDate)
        return alert("Хайх утгаа оруулна уу!");
      isLoading.value = true;
      const { data } = await instance.post("/admin/searchorder", searchValue);
      if (data) {
        orderList.value = data;
        isLoading.value = false;
        // get hot instance
        hot.value?.hotInstance.loadData(data);
      }
    };

    const updateDeliveryStatus = async (id) => {
      const { data } = await instance.put(`/admin/updateorderdelivery/${id}`);
      if (data) {
        await store.dispatch("admin/fetchOrderList");
        orderList.value = store.getters["admin/getOrderList"];
      }
    };

    return {
      hot,
      isLoading,
      orderList,
      hotSettings,
      hotSearchSettings,
      showOrderDetail,
      searchOrder,
    };
  },
};
</script>
