import instance from "../lib/common/axios";
// import { navCategories } from "./category/category";

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      navCategories: [],
      subCategories: [],
    };
  },

  getters: {
    getCategories(state) {
      return state.categories;
    },
    getNavCategories(state) {
      return state.navCategories;
    },
    getSubCategories(state) {
      return state.subCategories;
    },
  },
  mutations: {
    setNavCategories(state, payload) {
      state.navCategories = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setSubCategories(state, payload) {
      state.subCategories = payload;
    },
  },

  actions: {
    async fetchNavCategories({ commit }) {
      try {
        const response = await instance.get("/categories/getnavcategories");
        commit("setNavCategories", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCategoryByParentId({ commit }, payload) {
      try {
        const response = await instance.get(`/categories/getcategorybyparentid/${payload}`);
        commit("setSubCategories", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCategories({ commit }, payload) {
      try {
        const response = await instance.get(`/categories/${payload}`);
        commit("setCategories", response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
