<template>
  <div class="bg-slate-50 flex-grow md:mt-0">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5">
        <div class="md:grid md:grid-cols-12 gap-5 pt-5 md:mt-5">
          <div class="col-span-8 mb-3">
            <!-- get receiver delivery info-->
            <div class="p-5 rounded-lg border border-slate-200 bg-white mb-5">
              <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">Хүргэлтийн мэдээлэл/ delivery info</h3>

              <div class="grid md:grid-cols-2 gap-5 my-5">
                <div>
                  <label for="lastName" class="block text-xs font-medium mb-1 ml-1">Овог</label>
                  <input
                    name="lastName"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.lastName">{{ errors.lastName }} </span>
                </div>
                <div>
                  <label for="firstName" class="block text-xs font-medium mb-1 ml-1">Нэр</label>
                  <input
                    name="firstName"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.firstName">{{ errors.firstName }}</span>
                </div>
                <div>
                  <label for="regNum" class="block text-xs font-medium mb-1 ml-1">Регистрийн дугаар</label>
                  <input
                    name="regNum"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.regNum">{{ errors.regNum }}</span>
                </div>
                <div>
                  <label for="phone" class="block text-xs font-medium mb-1 ml-1">Утасны дугаар</label>
                  <input
                    name="phone"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.phone">{{ errors.phone }}</span>
                </div>

                <div>
                  <label for="email" class="block text-xs font-medium mb-1 ml-1">И-мэйл хаяг</label>
                  <input
                    name="email"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.email">{{ errors.email }}</span>
                </div>

                <div class="col-span-2">
                  <label for="address" class="block text-xs font-medium mb-1 ml-1">Хаяг</label>
                  <input
                    name="address"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    v-model="orderDetail.address"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.address">{{ errors.address }}</span>
                </div>

                <div class="col-span-2">
                  <button
                    class="px-4 py-2 bg-slate-100 text-[11px] font-semibold uppercase rounded hover:bg-slate-200 my-1"
                    @click="searchKoreanAddress"
                  >
                    Солонгос хаяг хайж оруулах бол энд дарна уу / Click here to search Korean address
                  </button>
                </div>

                <div class="col-span-2">
                  <label for="detailedAddress" class="block text-xs font-medium mb-1 ml-1">Дэлгэрэнгүй хаяг</label>
                  <input
                    name="detailedAddress"
                    type="text"
                    class="appearance-none w-full border border-gray-200 rounded px-4 py-2 text-sm outline-primary"
                    @change="handleInputs"
                  />
                  <span class="text-xs text-red-500" v-if="errors.detailedAddress">{{ errors.detailedAddress }}</span>
                </div>
              </div>
            </div>
            <!-- delivery country -->
            <div class="p-5 rounded-lg border border-slate-200 bg-white mb-5">
              <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">
                Хүргүүлэх улсаа сонгоно уу / Choose delivery country
              </h3>
              <div class="grid md:grid-cols-2 gap-3 my-5">
                <div
                  class="flex justify-center items-center font-medium text-gray-600"
                  :class="
                    orderDetail.deliveryCountry == 'korea'
                      ? 'rounded-xl border-2 border-blue-400 p-5 cursor-pointer'
                      : 'rounded-xl border-2 border-slate-200 p-5 cursor-pointer'
                  "
                  @click="handleDeliveryCountry('korea')"
                >
                  <img class="mr-3" style="height: 36px" src="../../assets/common/korea-flag.png" alt="" />
                  Солонгос улс дотроо хүргүүлэх <br />
                  Delivery in Korea
                </div>

                <div
                  class="flex justify-center items-center font-medium text-gray-600"
                  :class="
                    orderDetail.deliveryCountry == 'mongolia'
                      ? 'rounded-xl border-2 border-blue-400 p-5 cursor-pointer'
                      : 'rounded-xl border-2 border-slate-200 p-5 cursor-pointer'
                  "
                  @click="handleDeliveryCountry('mongolia')"
                >
                  <img class="mr-3" style="height: 30px" src="../../assets/common/mongolia-flag.png" alt="" />
                  Монгол рүү хүргүүлэх <br />
                  Delivery to Mongolia
                </div>

                <span class="text-xs text-red-500" v-if="errors.deliveryCountry">{{ errors.deliveryCountry }}</span>
              </div>
            </div>

            <!-- choose delivery method if coutnry is mongolian -->
            <div class="p-5 rounded-lg border border-slate-200 bg-white my-5" v-if="orderDetail.deliveryCountry === 'mongolia'">
              <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">Хүргэлтийн төрөл сонгоно уу.</h3>
              <div class="grid md:grid-cols-2 gap-3 my-5">
                <div
                  class="rounded-xl border-2 p-5 cursor-pointer"
                  @click="handleDeliveryMethod('land')"
                  :class="orderDetail.deliveryMethod == 'land' ? 'border-blue-400' : 'border-slate-200'"
                >
                  <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">
                    <img class="mr-3" style="height: 30px" src="	https://24mall.mn/media/images/shipping/gazar.png" alt="" />
                    Газрын илгээмж
                  </div>
                  <p class="mt-5 text-sm text-center">1 кг - 3000 вон, 21-35 хоногт ирнэ</p>
                </div>

                <div
                  class="rounded-xl border-2 p-5 cursor-pointer"
                  @click="handleDeliveryMethod('air')"
                  :class="orderDetail.deliveryMethod == 'air' ? 'border-blue-400' : 'border-slate-200'"
                >
                  <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">
                    <img class="mr-3" style="height: 30px" src="https://24mall.mn/media/images/shipping/plane.png" alt="" />
                    Агаарын илгээмж
                  </div>
                  <p class="mt-5 text-sm text-center">1 кг - 7500 вон, 10-21 хоног</p>
                </div>
                <span class="text-xs text-red-500" v-if="errors.deliveryMethod && orderDetail.deliveryCountry === 'mongolia'">{{
                  errors.deliveryMethod
                }}</span>
              </div>
              <div class="mt-5 bg-slate-100 rounded-lg flex justify-start items-center">
                <img class="ml-2" src="../../assets/common/exclamation-circle.svg" alt="" />
                <p class="p-3 font-medium text-sm flex flex-col gap-2 md:w-11/12">
                  <span
                    >5кг-аас доош жинтэй эсвэл овор багатай ачааг Газрын каргогоор тээвэрлэдэггүй болохыг анхаарна уу. Жин хүрэхгүй түүвэр бараа
                    зөвхөн Агаарын каргогоор ирнэ.</span
                  >
                  <span>Ачаа ирэх хугацаа нь каргоны компаниас хамаарч өөрчлөгдөж болохыг анхаарна уу.</span>
                </p>
              </div>
            </div>

            <!-- choose way of recieve method -->
            <div class="p-5 rounded-lg border border-slate-200 bg-white my-5" v-if="orderDetail.deliveryCountry === 'mongolia'">
              <h3 class="font-medium text-lg text-gray-800 border-b border-slate-200 pb-2 px-2">Ачаа хүлээн авах</h3>
              <div class="grid md:grid-cols-2 gap-3 my-5">
                <div
                  class="rounded-xl border-2 p-5 cursor-pointer"
                  @click="handleReceiveMethod('pickup')"
                  :class="orderDetail.receiveMethod == 'pickup' ? 'border-blue-400' : 'border-slate-200'"
                >
                  <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">Өөрийн биеэр ирж авах</div>
                  <p class="mt-5 text-sm text-center">
                    Танд ачаа ирсэнийг мэдэгдэсний дараа өөрийн биеээр ирж авна. Манай хаяг: БЗД, 26-р хороо. Sakura Tower Innovation center.
                  </p>
                </div>

                <div
                  class="rounded-xl border-2 p-5 cursor-pointer"
                  :class="orderDetail.receiveMethod == 'delivery' ? 'border-blue-400' : 'border-slate-200'"
                  @click="handleReceiveMethod('delivery')"
                >
                  <div class="flex justify-center items-center font-medium text-gray-600 border-b-2 border-slate-200 pb-3">Хүргэлтээр авах</div>
                  <p class="mt-5 text-sm text-center">
                    Танд ачаа ирсэнийг мэдэгдэсний дараа хүргэлтээр таны ачааг хүргэх бөгөөд хүргэлтийн төлбөр нэмэлтээр төлөгдөнө.
                  </p>
                </div>
              </div>
              <div class="mt-5 bg-slate-100 rounded-lg flex justify-start items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-3">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  ></path>
                </svg>
                <p class="p-3 font-medium text-sm">Таны захиалга ирсний дараа улс хоорондын Каргоны төлбөр нэмэлтээр тооцогдоно.</p>
              </div>

              <span class="text-xs text-red-500" v-if="errors.receiveMethod && orderDetail.deliveryCountry === 'mongolia'">{{
                errors.receiveMethod
              }}</span>
            </div>

            <div class="p-5 rounded-lg border border-slate-200 bg-white my-5">
              <div class="flex items-center">
                <input type="checkbox" class="border border-gray-300 rounded" v-model="isConfirmed" />
                <label class="font-medium text-sm text-gray-800 border-slate-200 px-2"
                  >Хүргэлтийн мэдээлэл баталгаажуулах / Confirm delivery information
                </label>
              </div>
            </div>

            <!-- Payment -->
            <div class="p-5 rounded-lg border border-slate-200 bg-white my-4">
              <div class="flex items-center gap-2 text-sm">
                <input type="radio" id="card" name="payment" value="card" @click="handlePaymentMethod('카드')" />
                <label for="card"> Карт/Кредит картаар төлөх / Pay with card </label>
              </div>
              <div class="flex items-center gap-2 text-sm">
                <input type="radio" id="cash" name="payment" value="cash" @click="handlePaymentMethod('가상계좌')" />
                <label for="cash">Шилжүүлэг/Transfer</label>
              </div>
            </div>
            <!-- payment button -->
            <div class="flex justify-center">
              <button
                :disabled="!isConfirmed"
                @click="tossPay"
                class="bg-black text-white text-center text-xs font-bold uppercase rounded h-10 px-6 hover:shadow-lg"
              >
                Төлбөр төлөх
              </button>
            </div>
          </div>
          <order-detail
            class="md:block col-span-4"
            :orderProducts="orderDetail.products"
            :productsTotal="orderAmounts.productsTotal"
            :totalAmount="orderAmounts.totalAmount"
            :deliveryFee="orderAmounts.deliveryFee"
            :vat="orderAmounts.vat"
          ></order-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch, computed } from "vue";
// import { useRouter } from "vue-router";
import OrderDetail from "./OrderDetail.vue";
import { validateOrderDetailNonUser } from "../../lib/common/useValidation";
import instance from "@/lib/common/axios";
export default {
  components: {
    OrderDetail,
  },
  setup() {
    // const router = useRouter();

    const orderDetail = reactive({
      lastName: "",
      firstName: "",
      regNum: "",
      phone: "",
      email: "",
      products: [],
      address: "",
      detailedAddress: "",
      deliveryCountry: "",
      deliveryMethod: "",
      receiveMethod: "",
      paymentMethod: "",
    });

    const orderAmounts = reactive({
      productsTotal: 0,
      deliveryFee: 0,
      vat: 0,
      totalAmount: computed(() => orderAmounts.productsTotal + orderAmounts.deliveryFee + orderAmounts.vat),
    });

    const errors = reactive({
      orderId: "",
      lastName: "",
      firstName: "",
      regNum: "",
      phone: "",
      email: "",
      address: "",
      detailedAddress: "",
      deliveryCountry: "",
      deliveryMethod: "",
      receiveMethod: "",
      paymentMethod: "",
    });

    const handleInputs = (e) => {
      errors[e.target.name] = "";
      orderDetail[e.target.name] = e.target.value;
    };

    const handleDeliveryCountry = (country) => {
      errors.deliveryCountry = "";
      orderDetail.deliveryCountry = country;
    };

    const handlePaymentMethod = (method) => {
      errors.paymentMethod = "";
      orderDetail.paymentMethod = method;
    };

    const handleReceiveMethod = (method) => {
      errors.receiveMethod = "";
      orderDetail.receiveMethod = method;
    };

    const handleDeliveryMethod = (method) => {
      errors.deliveryMethod = "";
      orderDetail.deliveryMethod = method;
    };

    onMounted(async () => {
      const orderId = await instance.get("/order/orderid/nonuser");
      orderDetail.orderId = orderId.data.orderId;
      const backetList = JSON.parse(localStorage.getItem("backetList"));
      if (backetList) {
        orderDetail.products = backetList;
        console.log(orderDetail.products.map((item) => item.products.name));

        backetList?.forEach((item) => {
          orderAmounts.productsTotal += item.products.price * item.products.quantity;
        });
      }
    });

    watch(
      () => orderDetail.deliveryCountry,
      () => {
        if (orderDetail.deliveryCountry === "mongolia") {
          orderAmounts.vat = Math.floor(orderAmounts.productsTotal * 0.1);
          orderAmounts.deliveryFee = 0;
        }
        if (orderDetail.deliveryCountry === "korea") {
          orderAmounts.deliveryFee = 3000;
          orderAmounts.vat = 0;
        }
        orderDetail.deliveryMethod = "";
        orderDetail.receiveMethod = "";
      }
    );

    watch(
      () => orderDetail.deliveryMethod,
      () => {
        if (orderDetail.deliveryMethod === "land") {
          orderAmounts.deliveryFee = 3000;
        } else if (orderDetail.deliveryMethod === "air") {
          orderAmounts.deliveryFee = 6500;
        }
      }
    );

    const searchKoreanAddress = (e) => {
      e.preventDefault();
      window.daum.postcode.load(function () {
        new window.daum.Postcode({
          oncomplete: function (data) {
            if (data) {
              orderDetail.address = data.address;
              errors.address = "";
            } else {
              alert("Хаяг олдсонгүй!");
            }
          },
        }).open();
      });
    };

    const tossPayments = window.TossPayments(process.env.VUE_APP_TOSS_CLIENT_KEY);

    const tossPay = () => {
      const valid = validateOrderDetailNonUser(orderDetail);
      if (Object.keys(valid.errors).length > 0) {
        valid.fieldDescriptions.forEach((field) => {
          errors[field.key] = valid.errors[field.key];
        });
        return;
      }
      let orderName = "";
      orderDetail.products.forEach((item) => {
        orderName += item.products.name + " ";
      });

      try {
        localStorage.setItem("orderDetail", JSON.stringify(orderDetail.value));
        tossPayments
          .requestPayment(orderDetail.paymentMethod, {
            amount: orderAmounts?.totalAmount,
            orderId: orderDetail?.orderId,
            orderName: orderName.substring(0, 99),
            successUrl: window.location.origin + "/payment/success",
            failUrl: window.location.origin + "/payment/fail",
            customerName: orderDetail?.lastName + orderDetail?.firstName,
            customerEmail: orderDetail?.email,
            currency: "KRW", // 통화 (선택)
          })
          .catch((e) => {
            if (e.code === "결제가 취소되었습니다") {
              return;
            }
          });
      } catch (e) {
        console.log(e);
      }
    };

    const isConfirmed = ref(false);
    return {
      isConfirmed,
      orderDetail,
      errors,
      orderAmounts,
      handleInputs,
      handleDeliveryCountry,
      handleReceiveMethod,
      handlePaymentMethod,
      handleDeliveryMethod,
      searchKoreanAddress,
      tossPay,
    };
  },
};
</script>
