<template>
  <admin-page>
    <div>
      <p class="text-2xl font-bold mb-4">Бараа нэмэх</p>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="category"> Категори </label>
          <select
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="category"
            id="category"
            v-if="categories.length > 0 && !isEdit"
            @change="handleCategoryChange"
          >
            <option disabled selected>Категори сонгох</option>
            <option v-for="category in categories" :value="category.id" :key="category.id" :selected="isEdit">{{ category.name }}</option>
          </select>

          <select
            v-else
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="category"
            id="category"
            @change="handleCategoryChange"
          >
            <option v-for="category in categories" :value="category.id" :key="category.id" :selected="isEdit && category.id == product.categoryId">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name"> Барааны нэр </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Барааны нэр оруулах"
            name="name"
            v-model="product.name"
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="image"> Зураг </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="image"
            type="file"
            placeholder="Барааны зураг оруулах"
            name="image"
            @change="handleImageChange"
          />
        </div>

        <div class="mb-4 flex" v-if="imageUrl">
          <div class="relative">
            <img :src="imageUrl" alt="" class="w-32 h-32 object-cover" />
            <div
              class="flex justify-center items-center bg-red-500 text-white rounded-full w-6 h-6 cursor-pointer absolute top-0 right-0"
              @click="handleImageRemove"
            >
              X
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="image">Бүтээгдэхүүний зурагууд </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="images"
            type="file"
            placeholder="Барааны зурагууд оруулах"
            name="images"
            @change="handleImagesChange"
            multiple
          />
        </div>

        <div class="flex gap-4" v-if="imagesUrl?.length > 0">
          <div v-for="(image, index) in imagesUrl" :key="index" class="mb-4 relative">
            <img :src="image" alt="" class="w-32 h-32 object-cover" />
            <div
              class="flex justify-center items-center bg-red-500 text-white rounded-full w-6 h-6 cursor-pointer absolute top-0 right-0"
              @click="handleImagesRemove(index)"
            >
              X
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="image">Бичлэг линк оруулах </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="video_url"
            type="text"
            placeholder="Бичлэг линк оруулах"
            name="video_url"
            @change="handleVideoUrlChange"
            v-model="product.videoUrl"
            multiple
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="countInStock"> Үлдэгдэл </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="countInStock"
            type="number"
            placeholder="Барааны үлдэгдэл оруулах"
            name="countInStock"
            v-model="product.countInStock"
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="isVariants">Төрөл байгаа эсэх </label>
          <input
            type="checkbox"
            class="form-checkbox h-5 w-5 text-gray-600"
            id="isVariants"
            name="isVariants"
            value="1"
            v-model="product.isVariants"
            @change="handleIsVariantsChange"
          />
        </div>

        <div class="mb-4" v-if="product.isVariants">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="isPriceChange">Үнэ төрөлийн дагуу өөрчлөгдөх эсэх </label>
          <input
            type="checkbox"
            class="form-checkbox h-5 w-5 text-gray-600"
            id="isPriceChange"
            name="isPriceChange"
            value="1"
            v-model="product.isPriceChange"
            @change="handleIsPriceChange"
          />
        </div>

        <div class="mb-4" v-if="!product.isPriceChange">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="price"> Үнэ </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price"
            type="number"
            placeholder="Барааны үнэ оруулах"
            name="price"
            v-model="product.price"
          />
        </div>

        <div class="mb-4" v-if="product.isPriceChange">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="price"> Бүтээгдэхүүний анхдагч үнэ </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price"
            type="number"
            placeholder="Барааны үнэ оруулах"
            name="price"
            v-model="product.price"
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="membership_price"> Membership үнэ </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="membership_price"
            type="number"
            placeholder="Membership үнэ оруулах"
            name="price"
            v-model="product.membershipPrice"
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="pv_point"> PV оноо </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pv_point"
            type="number"
            placeholder="PV оноо оруулах"
            name="price"
            v-model="product.pvPoint"
          />
        </div>

        <div class="mb-4" v-if="product.isVariants">
          <!-- variant1 -->
          <div class="mb-4">
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="variant1_name"> Төрөл 1 </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="variant1_name"
                type="text"
                placeholder="Барааны төрөл оруулах"
                name="variant1_name"
                v-model="product.variant1.values"
                @change="handleVariant1Values"
              />
            </div>

            <div v-if="product.isPriceChange">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="variant1_isprice"> Төрөлийн үнэ </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="variant1_price"
                type="text"
                placeholder="Барааны үнэ оруулах"
                name="variant1_price"
                v-model="product.variant1.prices"
                @change="handleVariant1Prices"
              />
            </div>
          </div>
          <!-- variant2 -->
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="variant2"> Төрөл 2 </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="variant2"
              type="text"
              placeholder="Барааны хэмжээ оруулах"
              name="variant2"
              v-model="product.variant2"
            />
          </div>
          <!-- variant3 -->
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="variant3"> Төрөл 3 </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="variant3"
              type="text"
              placeholder="Барааны хэмжээ оруулах"
              name="variant3"
              v-model="product.variant3"
            />
          </div>
        </div>

        <!-- <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="isDiscount"> Хямдралтай эсэх </label>
          <input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600" id="isDiscount" name="discount" value="1" v-model="product.isDiscount" />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="discount"> Хямдрал дүн</label>
          <input
            class="shadow appearance-none border rounded w-1/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="discount"
            type="number"
            placeholder="Барааны хямдрал оруулах"
            name="discount"
            v-model="product.discount"
          />
        </div>
         -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="description"> Тайлбар </label>
          <textarea
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="description"
            placeholder="Барааны тайлбар оруулах"
            name="description"
            v-model="product.description"
          ></textarea>
        </div>

        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            @click="handleSubmit"
          >
            Бараа нэмэх
          </button>
        </div>
      </div>
    </div>
    <admin-loading v-if="isLoading"></admin-loading>
  </admin-page>
</template>
<script>
import { reactive, onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import instance from "@/lib/common/axios";

import AdminLoading from "@/components/admin/AdminLoading.vue";
export default {
  name: "AddProductPage",
  components: {
    AdminLoading,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const categories = ref([]);
    const isEdit = ref(false);
    const isLoading = ref(false);
    const imageUrl = ref(null);
    const imagesUrl = ref([]);

    const product = reactive({
      id: "",
      name: "",
      image: null,
      videoUrl: "",
      images: [],
      price: "",
      membershipPrice: "",
      pvPoint: "",
      categoryId: "",
      rootId: "",
      countInStock: "",
      isVariants: false,
      isPriceChange: false,
      variant1: {
        values: [],
        prices: [],
      },
      variant2: "",
      variant3: "",
      isDiscount: false,
      discount: 0,
      description: "",
    });

    watch(
      () => product.pvPoint,
      () => {
        console.log("membership", product.pvPoint);
      }
    );

    onBeforeMount(async () => {
      await store.dispatch("admin/fetchCategories");
      categories.value = store.getters["admin/getCategories"];

      if (route.params.id) {
        try {
          isLoading.value = true;
          const { data } = await instance.get(`/admin/getproduct/${route.params.id}`);

          isEdit.value = true;
          product.id = data.id;
          product.name = data.name;
          product.price = data.price;
          product.membershipPrice = data.membership_price;
          product.pvPoint = data.pv_point;
          product.isPriceChange = data.does_price_varies;
          product.categoryId = data.category;
          product.rootId = data.root_category_id;
          product.countInStock = data.stock;
          product.description = data.description;

          product.image = data.image_url;
          product.images = data.urls || [];
          product.videoUrl = data.video_url;
          imageUrl.value = data.image_url;
          imagesUrl.value = data.urls;

          product.isVariants = data.does_have_variants;
          product.variant1.values = data.variant1.values;
          product.variant1.prices = data.variant1.prices;
          product.variant2 = data.variant2;
          product.variant3 = data.variant3;

          product.isDiscount = data.does_have_discount;
          product.discount = data.discount;

          isLoading.value = false;
        } catch (err) {
          window.location.href = "/admin/addproduct";
          console.log(err);
        }
      }
    });

    const handleCategoryChange = (e) => {
      const category = categories.value.find((category) => category.id == e.target.value);
      product.categoryId = category.id;
      product.rootId = category.root_category_id;
    };

    const handleImageChange = (e) => {
      if (!e.target.files[0]) return;
      product.image = e.target.files[0];
      imageUrl.value = URL.createObjectURL(e.target.files[0]);
    };

    const handleImagesChange = (e) => {
      if (!e.target.files) return;
      const files = e.target.files;
      const imageArray = Array.from(files);
      imageArray.forEach((file) => product.images.push(file));
      imagesUrl.value = product.images.map((image) => {
        if (image instanceof File) {
          return URL.createObjectURL(image);
        } else {
          return image;
        }
      });
    };

    const handleVideoUrlChange = (e) => {
      product.videoUrl = e.target.value;
    };

    const handleIsPriceChange = (e) => {
      product.isPriceChange = e.target.checked;
      if (!e.target.checked) {
        product.variant1.prices = [];
      }
    };

    const handleVariant1Values = (e) => {
      product.variant1.values = e.target.value.split(",");
    };

    const handleVariant1Prices = (e) => {
      product.variant1.prices = e.target.value.split(",");
    };

    const handleIsVariantsChange = (e) => {
      product.isVariants = e.target.checked;
      if (!e.target.checked) {
        product.isPriceChange = false;
        product.variant1.values = [];
        product.variant1.prices = [];
        product.variant2 = "";
        product.variant3 = "";
      }
    };

    const handleImageRemove = () => {
      product.image = null;
      imageUrl.value = null;
    };

    const handleImagesRemove = (index) => {
      product.images = product.images.filter((_, i) => i !== index);
      imagesUrl.value = imagesUrl.value.filter((_, i) => i !== index);
    };

    const handleSubmit = async () => {
      if (product.name === "") return alert("Барааны нэр оруулна уу");
      else if (product.image === "") return alert("Барааны зураг оруулна уу");
      else if (product.price === "") return alert("Барааны үнэ оруулна уу");
      else if (product.countInStock === "") return alert("Барааны үлдэгдэл оруулна уу");
      else if (product.description === "") return alert("Барааны тайлбар оруулна уу");

      isLoading.value = true;

      try {
        const formData = new FormData();
        product.id && formData.append("id", product.id);
        formData.append("category", product.categoryId);
        formData.append("rootId", product.rootId);
        formData.append("name", product.name);
        formData.append("image", product.image);
        for (let i = 0; i < product.images.length; i++) {
          formData.append("images", product.images[i]);
        }
        formData.append("videoUrl", product.videoUrl);
        formData.append("price", product.price);
        formData.append("membershipPrice", product.membershipPrice);
        formData.append("pvPoint", product.pvPoint);
        formData.append("isPriceChange", product.isPriceChange);
        formData.append("countInStock", product.countInStock);
        formData.append("isVariants", product.isVariants);
        formData.append("variant1", JSON.stringify(product.variant1));
        formData.append("variant2", `{${product.variant2}}`);
        formData.append("variant3", `{${product.variant3}}`);
        formData.append("isDiscount", product.isDiscount);
        formData.append("discount", product.discount);
        formData.append("description", product.description);

        const res = isEdit.value ? await instance.post(`/admin/updateproduct`, formData) : await instance.post("/admin/addproduct", formData);

        isLoading.value = false;

        res.status === 200 && isLoading.value === false && alert("Бараа амжилттай нэмэгдлээ");

        isEdit.value ? (window.location.href = "/admin/productlist") : window.location.reload();

        window.scrollTo(0, 0);
      } catch (error) {
        isLoading.value = false;
        alert("Бараа нэмэхэд алдаа гарлаа");
        console.log(error);
      }
    };
    return {
      categories,
      product,
      isEdit,
      isLoading,
      imageUrl,
      imagesUrl,
      handleSubmit,
      handleCategoryChange,
      handleImageChange,
      handleImagesChange,
      handleVideoUrlChange,
      handleImageRemove,
      handleImagesRemove,
      handleIsPriceChange,
      handleVariant1Values,
      handleVariant1Prices,
      handleIsVariantsChange,
    };
  },
};
</script>
