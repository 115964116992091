<template>
  <div class="bg-slate-50 flex-grow min-h-screen">
    <div class="max-w-[1240px] mx-auto">
      <div class="divide-y">
        <div id="title" class="flex justify-between items-baseline md:items-center">
          <p class="pl-5 py-3 pt-5 font-bold text-lg uppercase">
            Хүслийн жагсаалт
            <span
              >(<span>{{ wishList?.length }}</span
              >)</span
            >
          </p>
          <button class="flex justify-end items-center text-gray-700 pr-3 md:pr-0">
            <img src="../assets/common/recycle-bin.svg" alt="" />
            <span class="text-xs md:text-sm pl-1 uppercase" @click="removeAllFromWishList"> Хоослох </span>
          </button>
        </div>
        <div v-if="wishList && wishList.length > 0" class="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-5 mb-10 pt-5 mx-3 md:mx-0">
          <wish-card v-for="product in wishList" :key="product.id" :product="product" @removeFromWishList="removeFromWishList(product.id)" />
        </div>
        <div v-else class="flex flex-col justify-center items-center h-[calc(70vh-64px)]">
          <img src="../assets/common/empty-box.png" class="w-40" alt="" />
          <h1 class="text-xl font-semibold">Хүслийн жагсаалт хоосон байна</h1>
          <a href="/" class="mb-4"
            ><button class="bg-black mt-5 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-3 rounded text-sm">
              Дэлгүүр рүү буцах
            </button></a
          >
        </div>
      </div>
    </div>
    <back-to-top></back-to-top>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import WishCard from "@/components/product/WishCard.vue";
import BackToTop from "@/components/ui/BackToTop.vue";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default {
  components: {
    WishCard,
    BackToTop,
  },

  setup() {
    const store = useStore();
    const wishList = ref([]);

    onMounted(async () => {
      const token = Cookies.get("BStoken");
      if (token) {
        await store.dispatch("products/getWishListData", jwt_decode(token).user_id);
        wishList.value = store.getters["products/getWishList"];
      } else {
        wishList.value = JSON.parse(localStorage.getItem("wishList")) || [];
      }
    });

    const removeAllFromWishList = async () => {
      if (wishList.value.length > 0) {
        if (Cookies.get("BStoken")) {
          const ids = wishList.value.map((item) => item.products.id);
          const userId = jwt_decode(Cookies.get("BStoken")).user_id;
          const response = await store.dispatch("products/addToWishList", { id: ids, userId: userId });
          if (response.status == 200) window.location.reload();
          else console.error(response.statusText);
        } else {
          wishList.value = [];
          store.commit("products/setWishListCount", 0);
          localStorage.removeItem("wishList");
        }
      } else {
        alert("Хүслийн жагсаалт хоосон байна!");
      }
    };

    const removeFromWishList = async (id) => {
      if (Cookies.get("BStoken")) {
        const userId = jwt_decode(Cookies.get("BStoken")).user_id;
        const response = await store.dispatch("products/addToWishList", { id: id, userId: userId });
        if (response.status == 200) window.location.reload();
        else console.error(response.statusText);
      } else {
        const index = wishList.value.findIndex((item) => item.id === id);
        if (index > -1) {
          wishList.value.splice(index, 1);
          store.dispatch("products/decrementWishListCount");
          localStorage.setItem("wishList", JSON.stringify(wishList.value));
        }
      }
    };

    return {
      wishList,
      removeFromWishList,
      removeAllFromWishList,
    };
  },
};
</script>
