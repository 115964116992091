export const useValidation = () => {
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    // const hasLowercase = /[a-z]/.test(password);
    // const hasUppercase = /[A-Z]/.test(password);
    // const hasSpecialChar = /[*&^%$#@!.]/.test(password);
    // const hasNumber = /[0-9]/.test(password);

    const isValidLength = password.length >= 8;

    // return isValidLength && hasLowercase && hasUppercase && hasSpecialChar && hasNumber;
    return isValidLength;
  };

  const validateRegisterNum = (registerNum) => {
    if (registerNum.length !== 10) {
      return false;
    }

    // Check the first two characters are letters and the next eight characters are numbers
    const letters = /^[а-яА-ЯёЁ0-9\s!@#$&*().,-]+$/;
    // const letters = /^[a-zA-Z]{2}$/;
    const numbers = /^[0-9]{8}$/;

    const isValidFormat = letters.test(registerNum.substr(0, 2)) && numbers.test(registerNum.substr(2));

    return isValidFormat;
  };

  return {
    validateEmail,
    validatePassword,
    validateRegisterNum,
    validateOrderDetailNonUser,
  };
};

const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^[0-9]{8}$/;
  return phoneNumberRegex.test(phoneNumber);
};

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

const validateRegisterNumber = (registerNumber) => {
  const registerNumberRegex = /^[А-яЁё]{2}\d{8}$/;
  return registerNumberRegex.test(registerNumber);
};

const validateOptions = (country, key) => {
  if (country === "korea") {
    if (key === "deliveryMethod" || key === "receiveMethod") {
      return true;
    }
  } else if (country === "mongolia") {
    if (key === "deliveryMethod" || key === "receiveMethod") {
      return false;
    }
  }
};

export const validateOrderDetailNonUser = (orderDetail) => {
  const fieldDescriptions = [
    { key: "lastName", name: "Овог" },
    { key: "firstName", name: "Нэр" },
    { key: "regNum", name: "Регистрийн дугаар" },
    { key: "phone", name: "Утасны дугаар" },
    { key: "email", name: "И-мэйл хаяг" },
    { key: "address", name: "Хаяг" },
    { key: "detailedAddress", name: "Дэлгэрэнгүй хаяг" },
    { key: "deliveryCountry", name: "Хүргэлтийн улс" },
    { key: "deliveryMethod", name: "Хүргэлтийн арга" },
    { key: "receiveMethod", name: "Хүргэлтийн арга" },
    { key: "paymentMethod", name: "Төлбөрийн хэлбэр" },
  ];
  const errors = {};

  fieldDescriptions.forEach((field) => {
    if (!orderDetail[field.key]) {
      if (field.key === "deliveryMethod" || field.key === "receiveMethod") {
        if (!validateOptions(orderDetail.deliveryCountry, field.key)) {
          errors[field.key] = `${field.name} сонгоно уу.`;
        }
      } else {
        errors[field.key] = `${field.name} оруулна уу.`;
      }

      if (field.key === "paymentMethod") {
        errors[field.key] = `${field.name} сонгоно уу.`;
      }
    }

    if (orderDetail.regNum && !validateRegisterNumber(orderDetail.regNum)) {
      errors.regNum = "Регистрийн дугаар буруу байна.";
    }

    if (orderDetail.email && !validateEmail(orderDetail.email)) {
      errors.email = "И-мэйл хаяг буруу байна.";
    }

    if (orderDetail.phone && !validatePhoneNumber(orderDetail.phone)) {
      errors.phone = "Утасны дугаар буруу байна.";
    }

    return errors;
  });

  return {
    errors,
    fieldDescriptions,
  };
};
