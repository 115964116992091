<template>
  <div class="bg-slate-50 flex-grow md:mt-0">
    <div class="max-w-[1240px] mx-auto">
      <div class="p-5">
        <div class="p-5 md:p-10 rounded-lg border border-slate-200 bg-white mb-4">
          <div class="font-bold uppercase text-sm pb-5">Захиалагийн мэдээлэл</div>
          <div class="py-2 rounded-lg">
            <ul>
              <li class="flex mb-2">
                <span class="text-gray-600 w-[20%] sm:w-[10%] lg:w-[6%]">Нэр:</span>
                <span>{{ userInfo?.name }}</span>
              </li>
              <li class="flex mb-2">
                <span class="text-gray-600 w-[20%] sm:w-[10%] lg:w-[6%]">Утас:</span>
                <span class="w-[75%]">{{ userInfo?.phone }}</span>
              </li>
              <li class="flex mb-2">
                <span class="text-gray-600 w-[20%] sm:w-[10%] lg:w-[6%]">Имэйл:</span>
                <span class="w-[75%] sm:[90%]">{{ userInfo?.email }}</span>
              </li>
              <li class="flex mb-2">
                <span class="text-gray-600 w-[20%] sm:w-[10%] lg:w-[6%]">Хаяг:</span>
                <span class="w-[75%]">{{ orderInfo?.deliveryAddress }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="p-5 md:p-10 rounded-lg border border-slate-200 bg-white">
          <div class="font-bold uppercase text-sm pb-5">Захиалсан бараа</div>

          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            <div v-for="item in products" :key="item?.productId" class="bg-white rounded-lg shadow-md p-4">
              <p class="font-semibold capitalize">{{ item.productName }}</p>
              <img :src="item.productImage" alt="" class="w-full h-40 object-cover rounded-md my-2" />
              <p class="text-gray-600">Үнэ: {{ item.productPrice }}вон</p>
              <p class="text-gray-600">Тоо ширхэг: {{ item.productQuantity }}</p>
              <p class="text-gray-600" v-if="item.variants1 || item.variants2 || item.variants3">
                Төрөл: {{ item.variants1 }}-{{ item?.variants2 }}-{{ item?.variants3 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import instance from "@/lib/common/axios";
export default {
  name: "CompleteOrder",
  setup() {
    const route = useRoute();
    const orderInfo = ref({});
    const userInfo = ref({});
    const products = ref([]);

    onBeforeMount(async () => {
      try {
        const { data } = await instance.get(`/order/getcomplete/${route.params.id}`);
        await instance.delete(`/backet/deletbyorderid/${data.orderDetail.orderInfo.orderId}`);
        localStorage.removeItem("orderDetail");
        localStorage.removeItem("orderAmounts");
        localStorage.removeItem("nonUser");
        localStorage.removeItem("backetList");
        orderInfo.value = data.orderDetail.orderInfo;
        userInfo.value = data.orderDetail.userInfo;
        products.value = data.orderDetail.productInfo;
      } catch (e) {
        console.log(e);
      }
    });
    return {
      orderInfo,
      userInfo,
      products,
    };
  },
};
</script>
